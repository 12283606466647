/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define(['exports', 'echarts'], factory);
	} else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
		// CommonJS
		factory(exports, require('echarts'));
	} else {
		// Browser globals
		factory({}, root.echarts);
	}
}(this, function(exports, echarts) {
	var log = function(msg) {
		if (typeof console !== 'undefined') {
			console && console.error && console.error(msg);
		}
	}
	if (!echarts) {
		log('ECharts is not Loaded');
		return;
	}
	if (!echarts.registerMap) {
		log('ECharts Map is not loaded')
		return;
	}
	echarts.registerMap('jiaozuo', {
		"type": "FeatureCollection",
		"features": [{
			"type": "Feature",
			"properties": {
				"adcode": 410802,
				"name": "解放区",
				"center": [113.226126, 35.241353],
				"centroid": [113.213062, 35.235611],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 0,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[113.245093, 35.26895],
							[113.243541, 35.270255],
							[113.237689, 35.272612],
							[113.234036, 35.275346],
							[113.230566, 35.280525],
							[113.224348, 35.288744],
							[113.218378, 35.302256],
							[113.213269, 35.31218],
							[113.212019, 35.312146],
							[113.207849, 35.311149],
							[113.19843, 35.306252],
							[113.187783, 35.298328],
							[113.183666, 35.292426],
							[113.185078, 35.28323],
							[113.18205, 35.279192],
							[113.17082, 35.273364],
							[113.170238, 35.269408],
							[113.175066, 35.25455],
							[113.176241, 35.25127],
							[113.18192, 35.250771],
							[113.185843, 35.24965],
							[113.186436, 35.248276],
							[113.186242, 35.24086],
							[113.190014, 35.234475],
							[113.189895, 35.229669],
							[113.187373, 35.227242],
							[113.181726, 35.219809],
							[113.182254, 35.216028],
							[113.184507, 35.21475],
							[113.18996, 35.21451],
							[113.191943, 35.211864],
							[113.197051, 35.207993],
							[113.205068, 35.203541],
							[113.209077, 35.20255],
							[113.210263, 35.199794],
							[113.210166, 35.196217],
							[113.20731, 35.192824],
							[113.206653, 35.187571],
							[113.206836, 35.181435],
							[113.20009, 35.179403],
							[113.191274, 35.177583],
							[113.192223, 35.176981],
							[113.192266, 35.176092],
							[113.193182, 35.176085],
							[113.193139, 35.176968],
							[113.19482, 35.176119],
							[113.194928, 35.174676],
							[113.195941, 35.174464],
							[113.19718, 35.173109],
							[113.197708, 35.170489],
							[113.196544, 35.170735],
							[113.197266, 35.168765],
							[113.196339, 35.168696],
							[113.196501, 35.167807],
							[113.194938, 35.167595],
							[113.19566, 35.164263],
							[113.189313, 35.163852],
							[113.201566, 35.156455],
							[113.225221, 35.155525],
							[113.224229, 35.15961],
							[113.224574, 35.160855],
							[113.226848, 35.161026],
							[113.226395, 35.163373],
							[113.233799, 35.164591],
							[113.238045, 35.164304],
							[113.243379, 35.161882],
							[113.249231, 35.16128],
							[113.252895, 35.159774],
							[113.258014, 35.15885],
							[113.261117, 35.159418],
							[113.261333, 35.161519],
							[113.259501, 35.161368],
							[113.255007, 35.161793],
							[113.252184, 35.162504],
							[113.249296, 35.163544],
							[113.243886, 35.164126],
							[113.238422, 35.165878],
							[113.237894, 35.167807],
							[113.227333, 35.172972],
							[113.226158, 35.178452],
							[113.224089, 35.188939],
							[113.223507, 35.191381],
							[113.227171, 35.192202],
							[113.23701, 35.193214],
							[113.243703, 35.191887],
							[113.251149, 35.192167],
							[113.253186, 35.19437],
							[113.254264, 35.198036],
							[113.25519, 35.198692],
							[113.254436, 35.202091],
							[113.247895, 35.214674],
							[113.246731, 35.227125],
							[113.245168, 35.233976],
							[113.244349, 35.234557],
							[113.243519, 35.238522],
							[113.2395, 35.24114],
							[113.237851, 35.24505],
							[113.237118, 35.245993],
							[113.237161, 35.247292],
							[113.235986, 35.24911],
							[113.233907, 35.249957],
							[113.233928, 35.251092],
							[113.235599, 35.251666],
							[113.235749, 35.252288],
							[113.234241, 35.253539],
							[113.23271, 35.254127],
							[113.232322, 35.255453],
							[113.229876, 35.255234],
							[113.229747, 35.256006],
							[113.231719, 35.256273],
							[113.231439, 35.256874],
							[113.23826, 35.257523],
							[113.2427, 35.257681],
							[113.242517, 35.259471],
							[113.240954, 35.259861],
							[113.238476, 35.259731],
							[113.238336, 35.261617],
							[113.239467, 35.263059],
							[113.239651, 35.26418],
							[113.238928, 35.268061],
							[113.239564, 35.268998],
							[113.244748, 35.268786],
							[113.245093, 35.26895]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410803,
				"name": "中站区",
				"center": [113.175485, 35.236145],
				"centroid": [113.156254, 35.268584],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 1,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[113.191274, 35.177583],
							[113.20009, 35.179403],
							[113.206836, 35.181435],
							[113.206653, 35.187571],
							[113.20731, 35.192824],
							[113.210166, 35.196217],
							[113.210263, 35.199794],
							[113.209077, 35.20255],
							[113.205068, 35.203541],
							[113.197051, 35.207993],
							[113.191943, 35.211864],
							[113.18996, 35.21451],
							[113.184507, 35.21475],
							[113.182254, 35.216028],
							[113.181726, 35.219809],
							[113.187373, 35.227242],
							[113.189895, 35.229669],
							[113.190014, 35.234475],
							[113.186242, 35.24086],
							[113.186436, 35.248276],
							[113.185843, 35.24965],
							[113.18192, 35.250771],
							[113.176241, 35.25127],
							[113.175066, 35.25455],
							[113.170238, 35.269408],
							[113.17082, 35.273364],
							[113.18205, 35.279192],
							[113.185078, 35.28323],
							[113.183666, 35.292426],
							[113.187783, 35.298328],
							[113.19843, 35.306252],
							[113.207849, 35.311149],
							[113.212019, 35.312146],
							[113.213269, 35.31218],
							[113.215198, 35.316237],
							[113.215931, 35.320061],
							[113.214261, 35.325722],
							[113.212946, 35.327504],
							[113.211545, 35.331144],
							[113.210133, 35.333868],
							[113.208463, 35.335794],
							[113.205607, 35.339945],
							[113.205015, 35.342328],
							[113.197385, 35.349564],
							[113.192244, 35.352752],
							[113.190035, 35.353073],
							[113.187675, 35.352827],
							[113.184248, 35.351974],
							[113.175196, 35.350104],
							[113.169689, 35.348779],
							[113.163708, 35.348759],
							[113.162005, 35.348984],
							[113.155302, 35.348977],
							[113.15263, 35.349619],
							[113.151056, 35.350438],
							[113.148675, 35.35224],
							[113.148793, 35.350397],
							[113.147284, 35.34966],
							[113.144698, 35.350063],
							[113.141206, 35.348998],
							[113.137607, 35.348144],
							[113.13668, 35.347332],
							[113.137251, 35.344751],
							[113.138275, 35.344376],
							[113.139267, 35.342444],
							[113.139525, 35.33875],
							[113.137995, 35.336238],
							[113.135322, 35.335323],
							[113.132402, 35.335309],
							[113.129729, 35.333923],
							[113.130387, 35.329546],
							[113.131184, 35.327354],
							[113.132316, 35.325552],
							[113.131809, 35.324828],
							[113.128361, 35.324698],
							[113.126292, 35.322567],
							[113.12322, 35.321106],
							[113.121819, 35.320819],
							[113.120408, 35.31832],
							[113.121345, 35.31735],
							[113.120871, 35.313047],
							[113.120246, 35.312358],
							[113.116819, 35.311845],
							[113.114987, 35.309291],
							[113.114179, 35.30744],
							[113.110709, 35.302789],
							[113.108866, 35.300869],
							[113.108844, 35.299694],
							[113.109793, 35.299455],
							[113.109415, 35.297925],
							[113.111635, 35.297105],
							[113.114157, 35.29718],
							[113.114373, 35.296873],
							[113.111592, 35.295534],
							[113.111452, 35.294332],
							[113.110752, 35.29468],
							[113.106129, 35.293533],
							[113.107702, 35.288306],
							[113.108489, 35.287739],
							[113.108155, 35.286892],
							[113.110493, 35.28681],
							[113.108607, 35.284317],
							[113.109458, 35.283401],
							[113.109609, 35.281522],
							[113.110913, 35.281454],
							[113.110536, 35.279766],
							[113.112099, 35.278817],
							[113.113737, 35.278536],
							[113.114793, 35.277149],
							[113.115763, 35.275059],
							[113.115881, 35.27279],
							[113.114631, 35.272414],
							[113.115612, 35.271642],
							[113.11406, 35.271622],
							[113.11225, 35.269695],
							[113.109178, 35.269114],
							[113.108305, 35.268178],
							[113.107432, 35.268389],
							[113.105827, 35.266387],
							[113.101969, 35.263633],
							[113.102044, 35.26215],
							[113.101107, 35.261494],
							[113.099921, 35.261528],
							[113.099307, 35.260865],
							[113.099576, 35.259123],
							[113.098122, 35.256977],
							[113.09768, 35.255473],
							[113.097992, 35.253963],
							[113.097281, 35.248734],
							[113.097464, 35.248153],
							[113.101839, 35.247299],
							[113.10823, 35.247094],
							[113.109782, 35.245911],
							[113.111215, 35.243396],
							[113.111387, 35.23967],
							[113.112551, 35.237736],
							[113.114157, 35.232109],
							[113.113834, 35.229963],
							[113.113295, 35.228794],
							[113.113036, 35.226168],
							[113.11392, 35.224691],
							[113.11281, 35.221389],
							[113.113133, 35.21957],
							[113.112422, 35.216233],
							[113.113435, 35.214326],
							[113.115763, 35.212575],
							[113.116927, 35.211221],
							[113.118543, 35.210004],
							[113.119308, 35.207925],
							[113.119998, 35.20469],
							[113.12127, 35.202345],
							[113.125257, 35.197824],
							[113.130688, 35.195704],
							[113.135301, 35.195375],
							[113.136411, 35.194855],
							[113.137898, 35.19491],
							[113.13987, 35.193966],
							[113.142855, 35.191784],
							[113.145926, 35.189992],
							[113.150226, 35.188802],
							[113.151509, 35.189226],
							[113.15291, 35.188768],
							[113.154052, 35.187742],
							[113.15569, 35.187024],
							[113.158223, 35.184212],
							[113.160949, 35.183631],
							[113.163029, 35.183686],
							[113.16305, 35.186299],
							[113.160701, 35.186032],
							[113.160292, 35.188932],
							[113.16138, 35.189404],
							[113.1611, 35.191374],
							[113.16179, 35.191415],
							[113.16152, 35.193535],
							[113.161919, 35.194014],
							[113.16388, 35.194254],
							[113.163988, 35.193802],
							[113.170109, 35.194083],
							[113.170486, 35.19199],
							[113.171769, 35.192058],
							[113.172211, 35.189992],
							[113.173202, 35.190074],
							[113.173676, 35.188091],
							[113.175271, 35.188166],
							[113.175584, 35.187024],
							[113.177426, 35.187249],
							[113.177513, 35.186777],
							[113.179302, 35.187017],
							[113.179754, 35.184828],
							[113.18095, 35.180936],
							[113.181532, 35.180806],
							[113.187977, 35.181654],
							[113.188343, 35.179814],
							[113.1901, 35.178719],
							[113.190218, 35.177679],
							[113.191274, 35.177583]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410804,
				"name": "马村区",
				"center": [113.321703, 35.265453],
				"centroid": [113.367643, 35.293384],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 2,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[113.329269, 35.214544],
							[113.332814, 35.214907],
							[113.339409, 35.214633],
							[113.342104, 35.215098],
							[113.345207, 35.216965],
							[113.348408, 35.218333],
							[113.35163, 35.220705],
							[113.35302, 35.222893],
							[113.352697, 35.225204],
							[113.35274, 35.227406],
							[113.353893, 35.229805],
							[113.356135, 35.232178],
							[113.356059, 35.234488],
							[113.355359, 35.235903],
							[113.355305, 35.238905],
							[113.356156, 35.239198],
							[113.360823, 35.239848],
							[113.363743, 35.239807],
							[113.365446, 35.239486],
							[113.369983, 35.239834],
							[113.373776, 35.239882],
							[113.375489, 35.240162],
							[113.378302, 35.240128],
							[113.380016, 35.240709],
							[113.380792, 35.242801],
							[113.380716, 35.245111],
							[113.380167, 35.24803],
							[113.380231, 35.251229],
							[113.380986, 35.252521],
							[113.382236, 35.253607],
							[113.384122, 35.256396],
							[113.388217, 35.259253],
							[113.39034, 35.261631],
							[113.392721, 35.264706],
							[113.393745, 35.266797],
							[113.394273, 35.268895],
							[113.395566, 35.272489],
							[113.398788, 35.274949],
							[113.4027, 35.274895],
							[113.405265, 35.275161],
							[113.407367, 35.276534],
							[113.411429, 35.278174],
							[113.414156, 35.280046],
							[113.416904, 35.283018],
							[113.417152, 35.283709],
							[113.417098, 35.286817],
							[113.416538, 35.288935],
							[113.418057, 35.291715],
							[113.421064, 35.295486],
							[113.423079, 35.298765],
							[113.42545, 35.30104],
							[113.428047, 35.303],
							[113.429523, 35.303779],
							[113.436043, 35.306286],
							[113.437897, 35.307768],
							[113.440483, 35.308622],
							[113.441507, 35.3108],
							[113.440709, 35.313723],
							[113.440418, 35.317644],
							[113.440612, 35.320744],
							[113.441302, 35.324247],
							[113.442067, 35.326344],
							[113.444212, 35.32971],
							[113.448048, 35.332755],
							[113.448318, 35.333459],
							[113.447876, 35.335671],
							[113.447919, 35.338074],
							[113.44447, 35.345714],
							[113.440925, 35.346315],
							[113.432508, 35.345257],
							[113.425407, 35.346444],
							[113.402991, 35.346902],
							[113.397463, 35.350452],
							[113.386051, 35.356199],
							[113.381072, 35.357094],
							[113.379455, 35.357114],
							[113.377602, 35.356138],
							[113.375856, 35.354554],
							[113.37397, 35.35207],
							[113.373065, 35.349981],
							[113.372009, 35.345673],
							[113.367418, 35.335862],
							[113.363312, 35.333725],
							[113.35427, 35.331806],
							[113.348807, 35.32689],
							[113.3465, 35.326603],
							[113.34164, 35.331827],
							[113.338666, 35.339146],
							[113.331683, 35.343154],
							[113.329851, 35.34327],
							[113.328363, 35.342485],
							[113.32374, 35.338033],
							[113.322889, 35.337945],
							[113.320895, 35.336463],
							[113.318632, 35.333186],
							[113.315755, 35.330202],
							[113.314268, 35.329321],
							[113.3125, 35.326637],
							[113.308287, 35.32368],
							[113.306412, 35.322096],
							[113.303836, 35.321222],
							[113.301724, 35.319938],
							[113.300226, 35.318156],
							[113.298997, 35.317623],
							[113.303459, 35.315526],
							[113.311002, 35.313833],
							[113.315184, 35.312651],
							[113.316962, 35.30953],
							[113.319527, 35.303472],
							[113.321305, 35.294919],
							[113.32637, 35.292747],
							[113.327458, 35.290486],
							[113.329473, 35.290124],
							[113.333439, 35.290055],
							[113.336877, 35.289379],
							[113.337265, 35.287644],
							[113.33678, 35.286031],
							[113.335541, 35.285628],
							[113.33081, 35.285512],
							[113.32443, 35.283415],
							[113.323126, 35.282035],
							[113.307403, 35.276712],
							[113.305345, 35.274177],
							[113.305269, 35.271348],
							[113.306627, 35.270289],
							[113.309569, 35.27033],
							[113.310377, 35.269537],
							[113.309989, 35.267139],
							[113.305086, 35.265143],
							[113.304526, 35.263428],
							[113.306347, 35.260401],
							[113.308039, 35.254161],
							[113.312134, 35.250122],
							[113.315378, 35.24561],
							[113.31666, 35.243341],
							[113.318891, 35.240989],
							[113.318729, 35.237872],
							[113.316595, 35.230099],
							[113.315572, 35.223481],
							[113.315658, 35.220035],
							[113.317458, 35.219837],
							[113.32041, 35.218162],
							[113.324064, 35.217519],
							[113.326715, 35.215584],
							[113.329269, 35.214544]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410811,
				"name": "山阳区",
				"center": [113.26766, 35.21476],
				"centroid": [113.234621, 35.191212],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 3,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[113.191274, 35.177583],
							[113.190218, 35.177679],
							[113.1901, 35.178719],
							[113.188343, 35.179814],
							[113.187977, 35.181654],
							[113.181532, 35.180806],
							[113.18095, 35.180936],
							[113.179754, 35.184828],
							[113.179302, 35.187017],
							[113.177513, 35.186777],
							[113.177426, 35.187249],
							[113.175584, 35.187024],
							[113.175271, 35.188166],
							[113.173676, 35.188091],
							[113.173202, 35.190074],
							[113.172211, 35.189992],
							[113.171769, 35.192058],
							[113.170486, 35.19199],
							[113.170109, 35.194083],
							[113.163988, 35.193802],
							[113.16388, 35.194254],
							[113.161919, 35.194014],
							[113.16152, 35.193535],
							[113.16179, 35.191415],
							[113.1611, 35.191374],
							[113.16138, 35.189404],
							[113.160292, 35.188932],
							[113.160701, 35.186032],
							[113.16305, 35.186299],
							[113.163029, 35.183686],
							[113.160949, 35.183631],
							[113.158223, 35.184212],
							[113.15832, 35.183795],
							[113.153912, 35.18359],
							[113.153352, 35.185314],
							[113.150097, 35.184014],
							[113.149095, 35.184116],
							[113.148513, 35.185512],
							[113.146573, 35.187208],
							[113.14376, 35.186921],
							[113.143049, 35.1895],
							[113.138458, 35.189055],
							[113.138038, 35.190074],
							[113.132035, 35.186962],
							[113.130818, 35.180381],
							[113.125138, 35.179143],
							[113.1228, 35.1738],
							[113.115138, 35.171714],
							[113.113931, 35.156688],
							[113.112099, 35.156346],
							[113.111517, 35.153691],
							[113.109674, 35.151973],
							[113.108974, 35.146909],
							[113.105299, 35.146546],
							[113.10518, 35.143754],
							[113.101398, 35.144062],
							[113.099415, 35.143905],
							[113.09394, 35.140113],
							[113.093218, 35.123439],
							[113.098897, 35.122953],
							[113.102098, 35.120502],
							[113.104447, 35.120255],
							[113.111732, 35.121707],
							[113.124686, 35.121768],
							[113.134083, 35.120255],
							[113.138351, 35.117031],
							[113.141971, 35.11649],
							[113.142306, 35.117065],
							[113.152328, 35.116702],
							[113.15278, 35.115004],
							[113.154925, 35.115148],
							[113.155119, 35.111848],
							[113.152198, 35.111834],
							[113.152533, 35.111013],
							[113.1525, 35.105378],
							[113.150517, 35.105611],
							[113.149116, 35.105337],
							[113.149041, 35.102468],
							[113.153168, 35.102194],
							[113.157932, 35.097866],
							[113.174808, 35.099469],
							[113.174398, 35.106549],
							[113.176101, 35.10672],
							[113.176058, 35.107932],
							[113.17678, 35.107945],
							[113.176403, 35.112334],
							[113.181198, 35.112814],
							[113.191339, 35.113868],
							[113.191188, 35.11699],
							[113.192557, 35.118113],
							[113.19232, 35.122384],
							[113.190682, 35.122384],
							[113.190649, 35.125068],
							[113.209433, 35.129066],
							[113.209261, 35.131571],
							[113.215155, 35.132803],
							[113.229531, 35.13628],
							[113.232516, 35.136855],
							[113.237592, 35.138135],
							[113.240868, 35.138799],
							[113.243013, 35.138806],
							[113.247798, 35.137355],
							[113.254533, 35.136485],
							[113.264932, 35.131646],
							[113.265547, 35.128374],
							[113.268456, 35.127471],
							[113.294967, 35.125308],
							[113.295085, 35.132084],
							[113.300829, 35.132427],
							[113.301357, 35.124863],
							[113.309968, 35.124157],
							[113.316865, 35.119393],
							[113.317048, 35.122022],
							[113.329581, 35.121755],
							[113.330799, 35.122843],
							[113.336985, 35.122946],
							[113.33692, 35.124869],
							[113.339474, 35.123932],
							[113.339593, 35.125246],
							[113.337728, 35.125047],
							[113.337718, 35.125677],
							[113.336036, 35.125568],
							[113.33595, 35.126478],
							[113.333008, 35.126286],
							[113.332933, 35.1291],
							[113.331521, 35.129127],
							[113.331489, 35.13101],
							[113.329463, 35.130743],
							[113.329473, 35.132276],
							[113.327092, 35.132338],
							[113.326995, 35.137115],
							[113.326661, 35.137088],
							[113.32679, 35.14001],
							[113.328062, 35.140804],
							[113.330939, 35.141345],
							[113.330616, 35.144267],
							[113.324365, 35.142919],
							[113.324117, 35.142591],
							[113.321413, 35.142673],
							[113.321488, 35.143734],
							[113.316434, 35.143357],
							[113.316401, 35.145075],
							[113.313287, 35.144863],
							[113.312554, 35.14706],
							[113.310819, 35.146889],
							[113.311142, 35.144767],
							[113.309203, 35.144603],
							[113.309515, 35.142508],
							[113.305625, 35.142214],
							[113.305711, 35.140291],
							[113.299827, 35.139271],
							[113.299924, 35.13871],
							[113.298254, 35.138272],
							[113.297661, 35.138888],
							[113.294687, 35.136862],
							[113.294309, 35.138566],
							[113.291518, 35.138258],
							[113.291874, 35.13975],
							[113.289794, 35.14027],
							[113.2896, 35.142652],
							[113.287391, 35.142481],
							[113.287358, 35.145123],
							[113.285095, 35.144849],
							[113.284794, 35.14719],
							[113.280267, 35.146697],
							[113.280332, 35.145205],
							[113.276841, 35.14513],
							[113.276819, 35.146546],
							[113.276183, 35.146553],
							[113.276183, 35.145102],
							[113.274922, 35.145082],
							[113.274901, 35.146581],
							[113.27433, 35.146539],
							[113.273737, 35.149831],
							[113.269243, 35.149941],
							[113.26865, 35.155354],
							[113.271, 35.156168],
							[113.272745, 35.159918],
							[113.274405, 35.160787],
							[113.278673, 35.161581],
							[113.28225, 35.162976],
							[113.284169, 35.165857],
							[113.284697, 35.167342],
							[113.286173, 35.169086],
							[113.289147, 35.170311],
							[113.29071, 35.173191],
							[113.293286, 35.180648],
							[113.294202, 35.182468],
							[113.295592, 35.183863],
							[113.297618, 35.184705],
							[113.318083, 35.189691],
							[113.319268, 35.190252],
							[113.319613, 35.191155],
							[113.319128, 35.205319],
							[113.319893, 35.207761],
							[113.321854, 35.208739],
							[113.326531, 35.210004],
							[113.328072, 35.211686],
							[113.329269, 35.214544],
							[113.326715, 35.215584],
							[113.324064, 35.217519],
							[113.32041, 35.218162],
							[113.317458, 35.219837],
							[113.315658, 35.220035],
							[113.315572, 35.223481],
							[113.316595, 35.230099],
							[113.318729, 35.237872],
							[113.318891, 35.240989],
							[113.31666, 35.243341],
							[113.315378, 35.24561],
							[113.312134, 35.250122],
							[113.308039, 35.254161],
							[113.306347, 35.260401],
							[113.304526, 35.263428],
							[113.305086, 35.265143],
							[113.309989, 35.267139],
							[113.310377, 35.269537],
							[113.309569, 35.27033],
							[113.306627, 35.270289],
							[113.305269, 35.271348],
							[113.305345, 35.274177],
							[113.307403, 35.276712],
							[113.323126, 35.282035],
							[113.32443, 35.283415],
							[113.33081, 35.285512],
							[113.335541, 35.285628],
							[113.33678, 35.286031],
							[113.337265, 35.287644],
							[113.336877, 35.289379],
							[113.333439, 35.290055],
							[113.329473, 35.290124],
							[113.327458, 35.290486],
							[113.32637, 35.292747],
							[113.321305, 35.294919],
							[113.319527, 35.303472],
							[113.316962, 35.30953],
							[113.315184, 35.312651],
							[113.311002, 35.313833],
							[113.303459, 35.315526],
							[113.298997, 35.317623],
							[113.295053, 35.316018],
							[113.289374, 35.313375],
							[113.285171, 35.311121],
							[113.281065, 35.307659],
							[113.279944, 35.306279],
							[113.274965, 35.301819],
							[113.27198, 35.29895],
							[113.269469, 35.295575],
							[113.26795, 35.292679],
							[113.264588, 35.289017],
							[113.261355, 35.286448],
							[113.259598, 35.283866],
							[113.254975, 35.278413],
							[113.253725, 35.276316],
							[113.252033, 35.274116],
							[113.251494, 35.270986],
							[113.249694, 35.266011],
							[113.24714, 35.26554],
							[113.245955, 35.266435],
							[113.245998, 35.268191],
							[113.245093, 35.26895],
							[113.244748, 35.268786],
							[113.239564, 35.268998],
							[113.238928, 35.268061],
							[113.239651, 35.26418],
							[113.239467, 35.263059],
							[113.238336, 35.261617],
							[113.238476, 35.259731],
							[113.240954, 35.259861],
							[113.242517, 35.259471],
							[113.2427, 35.257681],
							[113.23826, 35.257523],
							[113.231439, 35.256874],
							[113.231719, 35.256273],
							[113.229747, 35.256006],
							[113.229876, 35.255234],
							[113.232322, 35.255453],
							[113.23271, 35.254127],
							[113.234241, 35.253539],
							[113.235749, 35.252288],
							[113.235599, 35.251666],
							[113.233928, 35.251092],
							[113.233907, 35.249957],
							[113.235986, 35.24911],
							[113.237161, 35.247292],
							[113.237118, 35.245993],
							[113.237851, 35.24505],
							[113.2395, 35.24114],
							[113.243519, 35.238522],
							[113.244349, 35.234557],
							[113.245168, 35.233976],
							[113.246731, 35.227125],
							[113.247895, 35.214674],
							[113.254436, 35.202091],
							[113.25519, 35.198692],
							[113.254264, 35.198036],
							[113.253186, 35.19437],
							[113.251149, 35.192167],
							[113.243703, 35.191887],
							[113.23701, 35.193214],
							[113.227171, 35.192202],
							[113.223507, 35.191381],
							[113.224089, 35.188939],
							[113.226158, 35.178452],
							[113.227333, 35.172972],
							[113.237894, 35.167807],
							[113.238422, 35.165878],
							[113.243886, 35.164126],
							[113.249296, 35.163544],
							[113.252184, 35.162504],
							[113.255007, 35.161793],
							[113.259501, 35.161368],
							[113.261333, 35.161519],
							[113.261117, 35.159418],
							[113.258014, 35.15885],
							[113.252895, 35.159774],
							[113.249231, 35.16128],
							[113.243379, 35.161882],
							[113.238045, 35.164304],
							[113.233799, 35.164591],
							[113.226395, 35.163373],
							[113.226848, 35.161026],
							[113.224574, 35.160855],
							[113.224229, 35.15961],
							[113.225221, 35.155525],
							[113.201566, 35.156455],
							[113.189313, 35.163852],
							[113.19566, 35.164263],
							[113.194938, 35.167595],
							[113.196501, 35.167807],
							[113.196339, 35.168696],
							[113.197266, 35.168765],
							[113.196544, 35.170735],
							[113.197708, 35.170489],
							[113.19718, 35.173109],
							[113.195941, 35.174464],
							[113.194928, 35.174676],
							[113.19482, 35.176119],
							[113.193139, 35.176968],
							[113.193182, 35.176085],
							[113.192266, 35.176092],
							[113.192223, 35.176981],
							[113.191274, 35.177583]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410821,
				"name": "修武县",
				"center": [113.447465, 35.229923],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 4,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[113.525985, 35.180977],
							[113.524487, 35.183385],
							[113.522137, 35.188563],
							[113.521092, 35.192913],
							[113.520715, 35.200792],
							[113.520715, 35.207542],
							[113.521458, 35.211508],
							[113.528183, 35.220117],
							[113.533711, 35.222572],
							[113.538421, 35.226537],
							[113.540436, 35.228411],
							[113.541546, 35.230804],
							[113.541697, 35.233955],
							[113.53882, 35.243225],
							[113.535102, 35.248761],
							[113.532655, 35.253799],
							[113.530274, 35.256854],
							[113.523237, 35.26364],
							[113.521512, 35.267836],
							[113.520208, 35.272749],
							[113.520445, 35.276582],
							[113.518797, 35.288798],
							[113.514648, 35.288771],
							[113.513451, 35.290725],
							[113.511889, 35.293956],
							[113.511027, 35.294298],
							[113.502039, 35.294359],
							[113.495217, 35.293703],
							[113.49054, 35.292446],
							[113.487297, 35.293013],
							[113.486445, 35.294106],
							[113.485626, 35.305876],
							[113.485626, 35.310452],
							[113.485023, 35.316264],
							[113.484549, 35.317657],
							[113.483536, 35.318668],
							[113.479904, 35.320519],
							[113.478643, 35.323298],
							[113.478007, 35.325661],
							[113.478158, 35.327197],
							[113.480216, 35.33649],
							[113.480022, 35.339733],
							[113.473643, 35.342655],
							[113.466584, 35.349708],
							[113.470011, 35.35321],
							[113.46847, 35.35721],
							[113.46583, 35.358834],
							[113.465981, 35.363837],
							[113.468944, 35.364936],
							[113.476186, 35.363926],
							[113.478772, 35.365755],
							[113.482221, 35.369966],
							[113.480486, 35.375392],
							[113.479807, 35.376532],
							[113.475367, 35.381568],
							[113.472673, 35.38382],
							[113.470431, 35.385178],
							[113.46819, 35.38762],
							[113.464504, 35.392206],
							[113.46237, 35.396859],
							[113.462079, 35.40349],
							[113.458825, 35.413286],
							[113.453113, 35.423831],
							[113.447736, 35.432458],
							[113.444212, 35.43569],
							[113.433899, 35.443805],
							[113.427616, 35.448905],
							[113.418477, 35.452812],
							[113.414296, 35.455662],
							[113.409953, 35.459711],
							[113.407259, 35.460761],
							[113.404037, 35.461511],
							[113.401105, 35.464429],
							[113.398487, 35.466678],
							[113.394963, 35.468178],
							[113.392786, 35.470346],
							[113.392118, 35.47222],
							[113.392042, 35.476875],
							[113.391514, 35.481892],
							[113.390771, 35.48579],
							[113.389424, 35.488789],
							[113.387322, 35.491637],
							[113.383152, 35.495044],
							[113.380533, 35.491276],
							[113.378022, 35.488407],
							[113.376826, 35.487575],
							[113.374832, 35.485449],
							[113.372914, 35.48226],
							[113.370791, 35.480099],
							[113.369067, 35.479458],
							[113.365101, 35.47909],
							[113.363161, 35.479643],
							[113.359055, 35.47967],
							[113.35773, 35.479404],
							[113.355294, 35.477407],
							[113.353742, 35.475178],
							[113.353311, 35.473726],
							[113.350973, 35.470291],
							[113.348182, 35.468341],
							[113.344485, 35.468185],
							[113.339937, 35.468498],
							[113.338019, 35.4692],
							[113.336586, 35.468655],
							[113.332642, 35.468587],
							[113.325917, 35.469269],
							[113.323374, 35.471245],
							[113.318664, 35.478906],
							[113.312349, 35.481265],
							[113.307403, 35.478797],
							[113.295549, 35.471538],
							[113.294266, 35.469589],
							[113.294676, 35.467264],
							[113.30181, 35.467162],
							[113.309451, 35.466153],
							[113.322188, 35.461566],
							[113.323837, 35.457823],
							[113.323945, 35.453337],
							[113.317414, 35.451823],
							[113.315151, 35.451864],
							[113.312425, 35.457714],
							[113.309019, 35.460938],
							[113.306412, 35.460795],
							[113.305959, 35.455082],
							[113.303513, 35.452144],
							[113.299471, 35.450187],
							[113.296907, 35.44418],
							[113.297348, 35.438534],
							[113.299784, 35.436911],
							[113.30223, 35.434252],
							[113.303954, 35.431933],
							[113.305075, 35.429164],
							[113.304364, 35.427023],
							[113.301961, 35.426682],
							[113.298092, 35.42735],
							[113.292467, 35.429894],
							[113.285419, 35.433263],
							[113.282681, 35.434122],
							[113.278446, 35.435131],
							[113.274362, 35.434824],
							[113.270461, 35.434108],
							[113.2667, 35.434108],
							[113.265396, 35.434592],
							[113.263855, 35.436884],
							[113.264081, 35.438602],
							[113.262141, 35.440777],
							[113.260342, 35.440777],
							[113.255007, 35.440096],
							[113.253477, 35.440287],
							[113.251117, 35.441937],
							[113.25033, 35.443028],
							[113.244845, 35.448462],
							[113.243121, 35.449396],
							[113.238594, 35.449737],
							[113.23784, 35.449423],
							[113.235448, 35.45226],
							[113.233788, 35.452969],
							[113.230049, 35.453235],
							[113.222537, 35.452123],
							[113.217946, 35.449546],
							[113.212041, 35.447617],
							[113.208549, 35.447262],
							[113.202363, 35.448619],
							[113.19912, 35.448878],
							[113.190024, 35.449055],
							[113.188494, 35.448087],
							[113.189302, 35.446832],
							[113.191091, 35.446791],
							[113.191371, 35.444855],
							[113.189507, 35.440368],
							[113.190983, 35.434115],
							[113.189658, 35.43033],
							[113.18775, 35.42911],
							[113.187675, 35.4272],
							[113.18996, 35.425515],
							[113.190585, 35.424424],
							[113.189442, 35.423531],
							[113.186123, 35.424717],
							[113.184183, 35.424431],
							[113.184679, 35.423156],
							[113.184097, 35.420987],
							[113.184, 35.419145],
							[113.184722, 35.417931],
							[113.187556, 35.417147],
							[113.188537, 35.416546],
							[113.188289, 35.415441],
							[113.186587, 35.414452],
							[113.186759, 35.413436],
							[113.188332, 35.41257],
							[113.187761, 35.411328],
							[113.185681, 35.409282],
							[113.183612, 35.409957],
							[113.182416, 35.411765],
							[113.177653, 35.413941],
							[113.175713, 35.414548],
							[113.174247, 35.414159],
							[113.1704, 35.411301],
							[113.169506, 35.410885],
							[113.169376, 35.412863],
							[113.168525, 35.4139],
							[113.16624, 35.415155],
							[113.16526, 35.41495],
							[113.165217, 35.412931],
							[113.166467, 35.411451],
							[113.166553, 35.41023],
							[113.164322, 35.409275],
							[113.164193, 35.408565],
							[113.166768, 35.408156],
							[113.16846, 35.406614],
							[113.168126, 35.405325],
							[113.169031, 35.404541],
							[113.1686, 35.402958],
							[113.169484, 35.401709],
							[113.16915, 35.40115],
							[113.167113, 35.401177],
							[113.167027, 35.400236],
							[113.169322, 35.397773],
							[113.171424, 35.396408],
							[113.172254, 35.396634],
							[113.172965, 35.398639],
							[113.173838, 35.399922],
							[113.174797, 35.400413],
							[113.175885, 35.400079],
							[113.174797, 35.398482],
							[113.174959, 35.397841],
							[113.177254, 35.397834],
							[113.177394, 35.396531],
							[113.176618, 35.395255],
							[113.174452, 35.394948],
							[113.173461, 35.393959],
							[113.173267, 35.392547],
							[113.170928, 35.390186],
							[113.168859, 35.389551],
							[113.169279, 35.3882],
							[113.170357, 35.387791],
							[113.169743, 35.38657],
							[113.166542, 35.386597],
							[113.165411, 35.387293],
							[113.163298, 35.385764],
							[113.160443, 35.384591],
							[113.159365, 35.381991],
							[113.161143, 35.380688],
							[113.160098, 35.378204],
							[113.159095, 35.377562],
							[113.157091, 35.379521],
							[113.156919, 35.381022],
							[113.155884, 35.381581],
							[113.154634, 35.380872],
							[113.155431, 35.378811],
							[113.15472, 35.376033],
							[113.155313, 35.373884],
							[113.154386, 35.371816],
							[113.154591, 35.370164],
							[113.153696, 35.369243],
							[113.152716, 35.370308],
							[113.151412, 35.370799],
							[113.14875, 35.370151],
							[113.148427, 35.367537],
							[113.14723, 35.366417],
							[113.147446, 35.364786],
							[113.148944, 35.36394],
							[113.149267, 35.362882],
							[113.147586, 35.358472],
							[113.148039, 35.354718],
							[113.148675, 35.35224],
							[113.151056, 35.350438],
							[113.15263, 35.349619],
							[113.155302, 35.348977],
							[113.162005, 35.348984],
							[113.163708, 35.348759],
							[113.169689, 35.348779],
							[113.175196, 35.350104],
							[113.184248, 35.351974],
							[113.187675, 35.352827],
							[113.190035, 35.353073],
							[113.192244, 35.352752],
							[113.197385, 35.349564],
							[113.205015, 35.342328],
							[113.205607, 35.339945],
							[113.208463, 35.335794],
							[113.210133, 35.333868],
							[113.211545, 35.331144],
							[113.212946, 35.327504],
							[113.214261, 35.325722],
							[113.215931, 35.320061],
							[113.215198, 35.316237],
							[113.213269, 35.31218],
							[113.218378, 35.302256],
							[113.224348, 35.288744],
							[113.230566, 35.280525],
							[113.234036, 35.275346],
							[113.237689, 35.272612],
							[113.243541, 35.270255],
							[113.245093, 35.26895],
							[113.245998, 35.268191],
							[113.245955, 35.266435],
							[113.24714, 35.26554],
							[113.249694, 35.266011],
							[113.251494, 35.270986],
							[113.252033, 35.274116],
							[113.253725, 35.276316],
							[113.254975, 35.278413],
							[113.259598, 35.283866],
							[113.261355, 35.286448],
							[113.264588, 35.289017],
							[113.26795, 35.292679],
							[113.269469, 35.295575],
							[113.27198, 35.29895],
							[113.274965, 35.301819],
							[113.279944, 35.306279],
							[113.281065, 35.307659],
							[113.285171, 35.311121],
							[113.289374, 35.313375],
							[113.295053, 35.316018],
							[113.298997, 35.317623],
							[113.300226, 35.318156],
							[113.301724, 35.319938],
							[113.303836, 35.321222],
							[113.306412, 35.322096],
							[113.308287, 35.32368],
							[113.3125, 35.326637],
							[113.314268, 35.329321],
							[113.315755, 35.330202],
							[113.318632, 35.333186],
							[113.320895, 35.336463],
							[113.322889, 35.337945],
							[113.32374, 35.338033],
							[113.328363, 35.342485],
							[113.329851, 35.34327],
							[113.331683, 35.343154],
							[113.338666, 35.339146],
							[113.34164, 35.331827],
							[113.3465, 35.326603],
							[113.348807, 35.32689],
							[113.35427, 35.331806],
							[113.363312, 35.333725],
							[113.367418, 35.335862],
							[113.372009, 35.345673],
							[113.373065, 35.349981],
							[113.37397, 35.35207],
							[113.375856, 35.354554],
							[113.377602, 35.356138],
							[113.379455, 35.357114],
							[113.381072, 35.357094],
							[113.386051, 35.356199],
							[113.397463, 35.350452],
							[113.402991, 35.346902],
							[113.425407, 35.346444],
							[113.432508, 35.345257],
							[113.440925, 35.346315],
							[113.44447, 35.345714],
							[113.447919, 35.338074],
							[113.447876, 35.335671],
							[113.448318, 35.333459],
							[113.448048, 35.332755],
							[113.444212, 35.32971],
							[113.442067, 35.326344],
							[113.441302, 35.324247],
							[113.440612, 35.320744],
							[113.440418, 35.317644],
							[113.440709, 35.313723],
							[113.441507, 35.3108],
							[113.440483, 35.308622],
							[113.437897, 35.307768],
							[113.436043, 35.306286],
							[113.429523, 35.303779],
							[113.428047, 35.303],
							[113.42545, 35.30104],
							[113.423079, 35.298765],
							[113.421064, 35.295486],
							[113.418057, 35.291715],
							[113.416538, 35.288935],
							[113.417098, 35.286817],
							[113.417152, 35.283709],
							[113.416904, 35.283018],
							[113.414156, 35.280046],
							[113.411429, 35.278174],
							[113.407367, 35.276534],
							[113.405265, 35.275161],
							[113.4027, 35.274895],
							[113.398788, 35.274949],
							[113.395566, 35.272489],
							[113.394273, 35.268895],
							[113.393745, 35.266797],
							[113.392721, 35.264706],
							[113.39034, 35.261631],
							[113.388217, 35.259253],
							[113.384122, 35.256396],
							[113.382236, 35.253607],
							[113.380986, 35.252521],
							[113.380231, 35.251229],
							[113.380167, 35.24803],
							[113.380716, 35.245111],
							[113.380792, 35.242801],
							[113.380016, 35.240709],
							[113.378302, 35.240128],
							[113.375489, 35.240162],
							[113.373776, 35.239882],
							[113.369983, 35.239834],
							[113.365446, 35.239486],
							[113.363743, 35.239807],
							[113.360823, 35.239848],
							[113.356156, 35.239198],
							[113.355305, 35.238905],
							[113.355359, 35.235903],
							[113.356059, 35.234488],
							[113.356135, 35.232178],
							[113.353893, 35.229805],
							[113.35274, 35.227406],
							[113.352697, 35.225204],
							[113.35302, 35.222893],
							[113.35163, 35.220705],
							[113.348408, 35.218333],
							[113.345207, 35.216965],
							[113.342104, 35.215098],
							[113.339409, 35.214633],
							[113.332814, 35.214907],
							[113.329269, 35.214544],
							[113.328072, 35.211686],
							[113.326531, 35.210004],
							[113.321854, 35.208739],
							[113.319893, 35.207761],
							[113.319128, 35.205319],
							[113.319613, 35.191155],
							[113.319268, 35.190252],
							[113.318083, 35.189691],
							[113.297618, 35.184705],
							[113.295592, 35.183863],
							[113.294202, 35.182468],
							[113.293286, 35.180648],
							[113.29071, 35.173191],
							[113.289147, 35.170311],
							[113.286173, 35.169086],
							[113.284697, 35.167342],
							[113.284169, 35.165857],
							[113.28225, 35.162976],
							[113.278673, 35.161581],
							[113.274405, 35.160787],
							[113.272745, 35.159918],
							[113.271, 35.156168],
							[113.26865, 35.155354],
							[113.269243, 35.149941],
							[113.273737, 35.149831],
							[113.27433, 35.146539],
							[113.274901, 35.146581],
							[113.274922, 35.145082],
							[113.276183, 35.145102],
							[113.276183, 35.146553],
							[113.276819, 35.146546],
							[113.276841, 35.14513],
							[113.280332, 35.145205],
							[113.280267, 35.146697],
							[113.284794, 35.14719],
							[113.285095, 35.144849],
							[113.287358, 35.145123],
							[113.287391, 35.142481],
							[113.2896, 35.142652],
							[113.289794, 35.14027],
							[113.291874, 35.13975],
							[113.291518, 35.138258],
							[113.294309, 35.138566],
							[113.294687, 35.136862],
							[113.297661, 35.138888],
							[113.298254, 35.138272],
							[113.299924, 35.13871],
							[113.299827, 35.139271],
							[113.305711, 35.140291],
							[113.305625, 35.142214],
							[113.309515, 35.142508],
							[113.309203, 35.144603],
							[113.311142, 35.144767],
							[113.310819, 35.146889],
							[113.312554, 35.14706],
							[113.313287, 35.144863],
							[113.316401, 35.145075],
							[113.316434, 35.143357],
							[113.321488, 35.143734],
							[113.321413, 35.142673],
							[113.324117, 35.142591],
							[113.324365, 35.142919],
							[113.330616, 35.144267],
							[113.330939, 35.141345],
							[113.328062, 35.140804],
							[113.32679, 35.14001],
							[113.326661, 35.137088],
							[113.326995, 35.137115],
							[113.327092, 35.132338],
							[113.329473, 35.132276],
							[113.329463, 35.130743],
							[113.331489, 35.13101],
							[113.331521, 35.129127],
							[113.332933, 35.1291],
							[113.333008, 35.126286],
							[113.33595, 35.126478],
							[113.336036, 35.125568],
							[113.337718, 35.125677],
							[113.337728, 35.125047],
							[113.339593, 35.125246],
							[113.347901, 35.126444],
							[113.349755, 35.126362],
							[113.349895, 35.12821],
							[113.351307, 35.128251],
							[113.351447, 35.134767],
							[113.3533, 35.134706],
							[113.353257, 35.135965],
							[113.354346, 35.136006],
							[113.35441, 35.136896],
							[113.357061, 35.137101],
							[113.35635, 35.143467],
							[113.355995, 35.149414],
							[113.372634, 35.150714],
							[113.372989, 35.148723],
							[113.375931, 35.148962],
							[113.376308, 35.145178],
							[113.374444, 35.144986],
							[113.374541, 35.142433],
							[113.373701, 35.14142],
							[113.372332, 35.141297],
							[113.372914, 35.131763],
							[113.374908, 35.129743],
							[113.379218, 35.129921],
							[113.386999, 35.128354],
							[113.392635, 35.129675],
							[113.39534, 35.132741],
							[113.398788, 35.137129],
							[113.402205, 35.138881],
							[113.405998, 35.140175],
							[113.409425, 35.142255],
							[113.411634, 35.142146],
							[113.414221, 35.141571],
							[113.416634, 35.141427],
							[113.419027, 35.144219],
							[113.419781, 35.145609],
							[113.424997, 35.148928],
							[113.427594, 35.155319],
							[113.428349, 35.156763],
							[113.429416, 35.156839],
							[113.430967, 35.155668],
							[113.434082, 35.151638],
							[113.433715, 35.144185],
							[113.436647, 35.140277],
							[113.437326, 35.138457],
							[113.438996, 35.136307],
							[113.440914, 35.135767],
							[113.441819, 35.137081],
							[113.440957, 35.142625],
							[113.439211, 35.14619],
							[113.436604, 35.156852],
							[113.436722, 35.160814],
							[113.438004, 35.161375],
							[113.440828, 35.160629],
							[113.44849, 35.151426],
							[113.45016, 35.147265],
							[113.452574, 35.146875],
							[113.454978, 35.147114],
							[113.456357, 35.148127],
							[113.460053, 35.15018],
							[113.464536, 35.15302],
							[113.471013, 35.154888],
							[113.475054, 35.156455],
							[113.482135, 35.157845],
							[113.484441, 35.158782],
							[113.490034, 35.160534],
							[113.492997, 35.161286],
							[113.497599, 35.161498],
							[113.504119, 35.162511],
							[113.505681, 35.163243],
							[113.510779, 35.170229],
							[113.516921, 35.175955],
							[113.518689, 35.17733],
							[113.525985, 35.180977]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410822,
				"name": "博爱县",
				"center": [113.069313, 35.170351],
				"centroid": [113.057844, 35.185704],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 5,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[113.158223, 35.184212],
							[113.15569, 35.187024],
							[113.154052, 35.187742],
							[113.15291, 35.188768],
							[113.151509, 35.189226],
							[113.150226, 35.188802],
							[113.145926, 35.189992],
							[113.142855, 35.191784],
							[113.13987, 35.193966],
							[113.137898, 35.19491],
							[113.136411, 35.194855],
							[113.135301, 35.195375],
							[113.130688, 35.195704],
							[113.125257, 35.197824],
							[113.12127, 35.202345],
							[113.119998, 35.20469],
							[113.119308, 35.207925],
							[113.118543, 35.210004],
							[113.116927, 35.211221],
							[113.115763, 35.212575],
							[113.113435, 35.214326],
							[113.112422, 35.216233],
							[113.113133, 35.21957],
							[113.11281, 35.221389],
							[113.11392, 35.224691],
							[113.113036, 35.226168],
							[113.113295, 35.228794],
							[113.113834, 35.229963],
							[113.114157, 35.232109],
							[113.112551, 35.237736],
							[113.111387, 35.23967],
							[113.111215, 35.243396],
							[113.109782, 35.245911],
							[113.10823, 35.247094],
							[113.101839, 35.247299],
							[113.097464, 35.248153],
							[113.097281, 35.248734],
							[113.097992, 35.253963],
							[113.09768, 35.255473],
							[113.098122, 35.256977],
							[113.099576, 35.259123],
							[113.099307, 35.260865],
							[113.099921, 35.261528],
							[113.101107, 35.261494],
							[113.102044, 35.26215],
							[113.101969, 35.263633],
							[113.105827, 35.266387],
							[113.107432, 35.268389],
							[113.108305, 35.268178],
							[113.109178, 35.269114],
							[113.11225, 35.269695],
							[113.11406, 35.271622],
							[113.115612, 35.271642],
							[113.114631, 35.272414],
							[113.115881, 35.27279],
							[113.115763, 35.275059],
							[113.114793, 35.277149],
							[113.113737, 35.278536],
							[113.112099, 35.278817],
							[113.110536, 35.279766],
							[113.110913, 35.281454],
							[113.109609, 35.281522],
							[113.109458, 35.283401],
							[113.108607, 35.284317],
							[113.110493, 35.28681],
							[113.108155, 35.286892],
							[113.108489, 35.287739],
							[113.107702, 35.288306],
							[113.106129, 35.293533],
							[113.110752, 35.29468],
							[113.111452, 35.294332],
							[113.111592, 35.295534],
							[113.114373, 35.296873],
							[113.114157, 35.29718],
							[113.111635, 35.297105],
							[113.109415, 35.297925],
							[113.109793, 35.299455],
							[113.108844, 35.299694],
							[113.108866, 35.300869],
							[113.110709, 35.302789],
							[113.114179, 35.30744],
							[113.114987, 35.309291],
							[113.116819, 35.311845],
							[113.120246, 35.312358],
							[113.120871, 35.313047],
							[113.121345, 35.31735],
							[113.120408, 35.31832],
							[113.121819, 35.320819],
							[113.12322, 35.321106],
							[113.126292, 35.322567],
							[113.128361, 35.324698],
							[113.131809, 35.324828],
							[113.132316, 35.325552],
							[113.131184, 35.327354],
							[113.130387, 35.329546],
							[113.129729, 35.333923],
							[113.126561, 35.332236],
							[113.122897, 35.332148],
							[113.122089, 35.332353],
							[113.12044, 35.334176],
							[113.116528, 35.336128],
							[113.110967, 35.339351],
							[113.107616, 35.340798],
							[113.105051, 35.340908],
							[113.103413, 35.340478],
							[113.09935, 35.337869],
							[113.096225, 35.337364],
							[113.094554, 35.337986],
							[113.092087, 35.338211],
							[113.08408, 35.340505],
							[113.081698, 35.341686],
							[113.078734, 35.343966],
							[113.073497, 35.34908],
							[113.070404, 35.350499],
							[113.067128, 35.353571],
							[113.064811, 35.353585],
							[113.063184, 35.352384],
							[113.060597, 35.352281],
							[113.054131, 35.355292],
							[113.039658, 35.36022],
							[113.038258, 35.360575],
							[113.033462, 35.359926],
							[113.030746, 35.358998],
							[113.027492, 35.358575],
							[113.02456, 35.357019],
							[113.020207, 35.356029],
							[113.016521, 35.356589],
							[113.011607, 35.358083],
							[113.010206, 35.358295],
							[113.0079, 35.35792],
							[113.001423, 35.361694],
							[112.998169, 35.362452],
							[112.996487, 35.362172],
							[112.995722, 35.361223],
							[112.995539, 35.358991],
							[112.994149, 35.35749],
							[112.991142, 35.35622],
							[112.990884, 35.354814],
							[112.992511, 35.350581],
							[112.994418, 35.347066],
							[112.994073, 35.345427],
							[112.990905, 35.343454],
							[112.986142, 35.339802],
							[112.985323, 35.338136],
							[112.985679, 35.334462],
							[112.985571, 35.329444],
							[112.986196, 35.324869],
							[112.986314, 35.316612],
							[112.985905, 35.314236],
							[112.985021, 35.311886],
							[112.986347, 35.310527],
							[112.987909, 35.310759],
							[112.989202, 35.308219],
							[112.992209, 35.307986],
							[112.996757, 35.306484],
							[113.000874, 35.305876],
							[113.002889, 35.305172],
							[113.004096, 35.303916],
							[113.00512, 35.301634],
							[113.004344, 35.300377],
							[113.001649, 35.299059],
							[113.000561, 35.29787],
							[113.000151, 35.296429],
							[113.001628, 35.295042],
							[113.003169, 35.294673],
							[113.007846, 35.294352],
							[113.009075, 35.294031],
							[113.010691, 35.292597],
							[113.010659, 35.29136],
							[113.009172, 35.287603],
							[113.007458, 35.284057],
							[113.003913, 35.280333],
							[113.001369, 35.278325],
							[113.000453, 35.277272],
							[113.000098, 35.275749],
							[112.999807, 35.272073],
							[112.999947, 35.268738],
							[112.999225, 35.267064],
							[112.997694, 35.266032],
							[112.995895, 35.261713],
							[112.994612, 35.260845],
							[112.993804, 35.260975],
							[112.99042, 35.262881],
							[112.988448, 35.26295],
							[112.986282, 35.262601],
							[112.984181, 35.261603],
							[112.981109, 35.258296],
							[112.978965, 35.255706],
							[112.978879, 35.254332],
							[112.979439, 35.253006],
							[112.981282, 35.250873],
							[112.982629, 35.250204],
							[112.988728, 35.250689],
							[112.990269, 35.250169],
							[112.991789, 35.248618],
							[112.992069, 35.247025],
							[112.990873, 35.245576],
							[112.991013, 35.243983],
							[112.993201, 35.240668],
							[112.994558, 35.237695],
							[112.994364, 35.235651],
							[112.993632, 35.234543],
							[112.988804, 35.230311],
							[112.987758, 35.228315],
							[112.988383, 35.224534],
							[112.987931, 35.219529],
							[112.986368, 35.214702],
							[112.98639, 35.21358],
							[112.987424, 35.212178],
							[112.988965, 35.211071],
							[112.990431, 35.210818],
							[112.992899, 35.211891],
							[112.994699, 35.213943],
							[112.998007, 35.215016],
							[112.99944, 35.21583],
							[112.999914, 35.217874],
							[112.99972, 35.219789],
							[113.001057, 35.223146],
							[113.002824, 35.225491],
							[113.005023, 35.225088],
							[113.009581, 35.218674],
							[113.010411, 35.216486],
							[113.010939, 35.212493],
							[113.010831, 35.210852],
							[113.009958, 35.207651],
							[113.008439, 35.205114],
							[113.007598, 35.204232],
							[113.001811, 35.20056],
							[112.997037, 35.197249],
							[112.995022, 35.194459],
							[112.990485, 35.185751],
							[112.98861, 35.182728],
							[112.981508, 35.177118],
							[112.973232, 35.170325],
							[112.967337, 35.165214],
							[112.966184, 35.163319],
							[112.964093, 35.157988],
							[112.963069, 35.15675],
							[112.959804, 35.155354],
							[112.952896, 35.152959],
							[112.952174, 35.152206],
							[112.95142, 35.150084],
							[112.951775, 35.147723],
							[112.952713, 35.145349],
							[112.956323, 35.137909],
							[112.958198, 35.129004],
							[112.958974, 35.127471],
							[112.959998, 35.126464],
							[112.962207, 35.125828],
							[112.96516, 35.12567],
							[112.966658, 35.125088],
							[112.967692, 35.124082],
							[112.968382, 35.122521],
							[112.968652, 35.120536],
							[112.968511, 35.117538],
							[112.967746, 35.114422],
							[112.966485, 35.112019],
							[112.964567, 35.109164],
							[112.964136, 35.108041],
							[112.964082, 35.105864],
							[112.965041, 35.104084],
							[112.970365, 35.100968],
							[112.972531, 35.099174],
							[112.974719, 35.096572],
							[112.978189, 35.089231],
							[112.978695, 35.08871],
							[112.987273, 35.084573],
							[112.9943, 35.080765],
							[113.003848, 35.07258],
							[113.018407, 35.058715],
							[113.030391, 35.046485],
							[113.035208, 35.043504],
							[113.042083, 35.040893],
							[113.048495, 35.039934],
							[113.052634, 35.039605],
							[113.064908, 35.040119],
							[113.094404, 35.044614],
							[113.096236, 35.044634],
							[113.103585, 35.044107],
							[113.110288, 35.043017],
							[113.126766, 35.039372],
							[113.13279, 35.038316],
							[113.136281, 35.038652],
							[113.141379, 35.040516],
							[113.148287, 35.043586],
							[113.162037, 35.046656],
							[113.159925, 35.048355],
							[113.158945, 35.049684],
							[113.157619, 35.052918],
							[113.157522, 35.055556],
							[113.158007, 35.057954],
							[113.159656, 35.059585],
							[113.162673, 35.061435],
							[113.166014, 35.061599],
							[113.169689, 35.061332],
							[113.172038, 35.062661],
							[113.173191, 35.065168],
							[113.17304, 35.068806],
							[113.173202, 35.07132],
							[113.174916, 35.076375],
							[113.177858, 35.08421],
							[113.177707, 35.087546],
							[113.178256, 35.0906],
							[113.178407, 35.09349],
							[113.179916, 35.101434],
							[113.180271, 35.102598],
							[113.180228, 35.104659],
							[113.179765, 35.106514],
							[113.179862, 35.110794],
							[113.181198, 35.112814],
							[113.176403, 35.112334],
							[113.17678, 35.107945],
							[113.176058, 35.107932],
							[113.176101, 35.10672],
							[113.174398, 35.106549],
							[113.174808, 35.099469],
							[113.157932, 35.097866],
							[113.153168, 35.102194],
							[113.149041, 35.102468],
							[113.149116, 35.105337],
							[113.150517, 35.105611],
							[113.1525, 35.105378],
							[113.152533, 35.111013],
							[113.152198, 35.111834],
							[113.155119, 35.111848],
							[113.154925, 35.115148],
							[113.15278, 35.115004],
							[113.152328, 35.116702],
							[113.142306, 35.117065],
							[113.141971, 35.11649],
							[113.138351, 35.117031],
							[113.134083, 35.120255],
							[113.124686, 35.121768],
							[113.111732, 35.121707],
							[113.104447, 35.120255],
							[113.102098, 35.120502],
							[113.098897, 35.122953],
							[113.093218, 35.123439],
							[113.09394, 35.140113],
							[113.099415, 35.143905],
							[113.101398, 35.144062],
							[113.10518, 35.143754],
							[113.105299, 35.146546],
							[113.108974, 35.146909],
							[113.109674, 35.151973],
							[113.111517, 35.153691],
							[113.112099, 35.156346],
							[113.113931, 35.156688],
							[113.115138, 35.171714],
							[113.1228, 35.1738],
							[113.125138, 35.179143],
							[113.130818, 35.180381],
							[113.132035, 35.186962],
							[113.138038, 35.190074],
							[113.138458, 35.189055],
							[113.143049, 35.1895],
							[113.14376, 35.186921],
							[113.146573, 35.187208],
							[113.148513, 35.185512],
							[113.149095, 35.184116],
							[113.150097, 35.184014],
							[113.153352, 35.185314],
							[113.153912, 35.18359],
							[113.15832, 35.183795],
							[113.158223, 35.184212]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410823,
				"name": "武陟县",
				"center": [113.408334, 35.09885],
				"centroid": [113.398459, 35.047763],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 6,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[113.220328, 34.906602],
							[113.222958, 34.907433],
							[113.227678, 34.908414],
							[113.22896, 34.911366],
							[113.231471, 34.91628],
							[113.236978, 34.925194],
							[113.239327, 34.939501],
							[113.243907, 34.946183],
							[113.252981, 34.951528],
							[113.260557, 34.953606],
							[113.266118, 34.954134],
							[113.274082, 34.954292],
							[113.289891, 34.952577],
							[113.302284, 34.94654],
							[113.315291, 34.942211],
							[113.320809, 34.942053],
							[113.328223, 34.943302],
							[113.33303, 34.944866],
							[113.343451, 34.950519],
							[113.349335, 34.956083],
							[113.352115, 34.958257],
							[113.358797, 34.965267],
							[113.362579, 34.970329],
							[113.371222, 34.977674],
							[113.375576, 34.980013],
							[113.381223, 34.982049],
							[113.38882, 34.98506],
							[113.394532, 34.986795],
							[113.40783, 34.989503],
							[113.4199, 34.987761],
							[113.427185, 34.983565],
							[113.429663, 34.977317],
							[113.428758, 34.969876],
							[113.429437, 34.96476],
							[113.449676, 34.960226],
							[113.452531, 34.960493],
							[113.45931, 34.959924],
							[113.465625, 34.958161],
							[113.477371, 34.957297],
							[113.495842, 34.959924],
							[113.5151, 34.965994],
							[113.53147, 34.96812],
							[113.543292, 34.968018],
							[113.55257, 34.965137],
							[113.564112, 34.956769],
							[113.574824, 34.950588],
							[113.583144, 34.941559],
							[113.588316, 34.936592],
							[113.592067, 34.933738],
							[113.594534, 34.942122],
							[113.594675, 34.946876],
							[113.594287, 34.954148],
							[113.594567, 34.957009],
							[113.595127, 34.959581],
							[113.595267, 34.961872],
							[113.596464, 34.964561],
							[113.600289, 34.971083],
							[113.604417, 34.977263],
							[113.60765, 34.982372],
							[113.610678, 34.984456],
							[113.6139, 34.988228],
							[113.616659, 34.989455],
							[113.617672, 34.992184],
							[113.623815, 34.994817],
							[113.628211, 34.996216],
							[113.63305, 34.997224],
							[113.634483, 34.997703],
							[113.640658, 35.000809],
							[113.644355, 35.004374],
							[113.639236, 35.006678],
							[113.634451, 35.008165],
							[113.633007, 35.009153],
							[113.63028, 35.012656],
							[113.629688, 35.014054],
							[113.629537, 35.016337],
							[113.629957, 35.016755],
							[113.633222, 35.017502],
							[113.640012, 35.017783],
							[113.643902, 35.018448],
							[113.645497, 35.019229],
							[113.646866, 35.021834],
							[113.646629, 35.023691],
							[113.645228, 35.02803],
							[113.645432, 35.031141],
							[113.645497, 35.038083],
							[113.644743, 35.04018],
							[113.642318, 35.043504],
							[113.637598, 35.047834],
							[113.635324, 35.050466],
							[113.633524, 35.053487],
							[113.632533, 35.054474],
							[113.629127, 35.055577],
							[113.627619, 35.05668],
							[113.626498, 35.059674],
							[113.625108, 35.06225],
							[113.620452, 35.068532],
							[113.616799, 35.077868],
							[113.61459, 35.08469],
							[113.613566, 35.086128],
							[113.610829, 35.091093],
							[113.607057, 35.099297],
							[113.603942, 35.111451],
							[113.600235, 35.120728],
							[113.597132, 35.126567],
							[113.593845, 35.131147],
							[113.591517, 35.132201],
							[113.585547, 35.131694],
							[113.583036, 35.131338],
							[113.575126, 35.127786],
							[113.570891, 35.126711],
							[113.568919, 35.126478],
							[113.565847, 35.127019],
							[113.564565, 35.12771],
							[113.563789, 35.130914],
							[113.564112, 35.132584],
							[113.565341, 35.135158],
							[113.565104, 35.137437],
							[113.562539, 35.139264],
							[113.559672, 35.140051],
							[113.554456, 35.140777],
							[113.551213, 35.141023],
							[113.546708, 35.142447],
							[113.542624, 35.144637],
							[113.539046, 35.147792],
							[113.53494, 35.151782],
							[113.533561, 35.155887],
							[113.532946, 35.15963],
							[113.532795, 35.164571],
							[113.531513, 35.167294],
							[113.529606, 35.170475],
							[113.52758, 35.173171],
							[113.525985, 35.180977],
							[113.518689, 35.17733],
							[113.516921, 35.175955],
							[113.510779, 35.170229],
							[113.505681, 35.163243],
							[113.504119, 35.162511],
							[113.497599, 35.161498],
							[113.492997, 35.161286],
							[113.490034, 35.160534],
							[113.484441, 35.158782],
							[113.482135, 35.157845],
							[113.475054, 35.156455],
							[113.471013, 35.154888],
							[113.464536, 35.15302],
							[113.460053, 35.15018],
							[113.456357, 35.148127],
							[113.454978, 35.147114],
							[113.452574, 35.146875],
							[113.45016, 35.147265],
							[113.44849, 35.151426],
							[113.440828, 35.160629],
							[113.438004, 35.161375],
							[113.436722, 35.160814],
							[113.436604, 35.156852],
							[113.439211, 35.14619],
							[113.440957, 35.142625],
							[113.441819, 35.137081],
							[113.440914, 35.135767],
							[113.438996, 35.136307],
							[113.437326, 35.138457],
							[113.436647, 35.140277],
							[113.433715, 35.144185],
							[113.434082, 35.151638],
							[113.430967, 35.155668],
							[113.429416, 35.156839],
							[113.428349, 35.156763],
							[113.427594, 35.155319],
							[113.424997, 35.148928],
							[113.419781, 35.145609],
							[113.419027, 35.144219],
							[113.416634, 35.141427],
							[113.414221, 35.141571],
							[113.411634, 35.142146],
							[113.409425, 35.142255],
							[113.405998, 35.140175],
							[113.402205, 35.138881],
							[113.398788, 35.137129],
							[113.39534, 35.132741],
							[113.392635, 35.129675],
							[113.386999, 35.128354],
							[113.379218, 35.129921],
							[113.374908, 35.129743],
							[113.372914, 35.131763],
							[113.372332, 35.141297],
							[113.373701, 35.14142],
							[113.374541, 35.142433],
							[113.374444, 35.144986],
							[113.376308, 35.145178],
							[113.375931, 35.148962],
							[113.372989, 35.148723],
							[113.372634, 35.150714],
							[113.355995, 35.149414],
							[113.35635, 35.143467],
							[113.357061, 35.137101],
							[113.35441, 35.136896],
							[113.354346, 35.136006],
							[113.353257, 35.135965],
							[113.3533, 35.134706],
							[113.351447, 35.134767],
							[113.351307, 35.128251],
							[113.349895, 35.12821],
							[113.349755, 35.126362],
							[113.347901, 35.126444],
							[113.339593, 35.125246],
							[113.339474, 35.123932],
							[113.33692, 35.124869],
							[113.336985, 35.122946],
							[113.330799, 35.122843],
							[113.329581, 35.121755],
							[113.317048, 35.122022],
							[113.316865, 35.119393],
							[113.309968, 35.124157],
							[113.301357, 35.124863],
							[113.300829, 35.132427],
							[113.295085, 35.132084],
							[113.294967, 35.125308],
							[113.268456, 35.127471],
							[113.265547, 35.128374],
							[113.264932, 35.131646],
							[113.254533, 35.136485],
							[113.247798, 35.137355],
							[113.243013, 35.138806],
							[113.240868, 35.138799],
							[113.237592, 35.138135],
							[113.232516, 35.136855],
							[113.229531, 35.13628],
							[113.215155, 35.132803],
							[113.209261, 35.131571],
							[113.209433, 35.129066],
							[113.190649, 35.125068],
							[113.190682, 35.122384],
							[113.19232, 35.122384],
							[113.192557, 35.118113],
							[113.191188, 35.11699],
							[113.191339, 35.113868],
							[113.181198, 35.112814],
							[113.179862, 35.110794],
							[113.179765, 35.106514],
							[113.180228, 35.104659],
							[113.180271, 35.102598],
							[113.179916, 35.101434],
							[113.178407, 35.09349],
							[113.178256, 35.0906],
							[113.177707, 35.087546],
							[113.177858, 35.08421],
							[113.174916, 35.076375],
							[113.173202, 35.07132],
							[113.17304, 35.068806],
							[113.173191, 35.065168],
							[113.172038, 35.062661],
							[113.169689, 35.061332],
							[113.166014, 35.061599],
							[113.162673, 35.061435],
							[113.159656, 35.059585],
							[113.158007, 35.057954],
							[113.157522, 35.055556],
							[113.157619, 35.052918],
							[113.158945, 35.049684],
							[113.159925, 35.048355],
							[113.162037, 35.046656],
							[113.165314, 35.046368],
							[113.166574, 35.045354],
							[113.168665, 35.042908],
							[113.174355, 35.030134],
							[113.174323, 35.018215],
							[113.173191, 35.008138],
							[113.174819, 34.997134],
							[113.172566, 34.987515],
							[113.173105, 34.981096],
							[113.176974, 34.973744],
							[113.181985, 34.970061],
							[113.189766, 34.965446],
							[113.196436, 34.959691],
							[113.200855, 34.952344],
							[113.205004, 34.942245],
							[113.207536, 34.932996],
							[113.211642, 34.932564],
							[113.213043, 34.93159],
							[113.215058, 34.928674],
							[113.217257, 34.924289],
							[113.217311, 34.922793],
							[113.22022, 34.917282],
							[113.218712, 34.916582],
							[113.222505, 34.909197],
							[113.222958, 34.907659],
							[113.220328, 34.906602]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410825,
				"name": "温县",
				"center": [113.079118, 34.941233],
				"centroid": [113.049295, 34.942639],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 7,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[113.220328, 34.906602],
							[113.222958, 34.907659],
							[113.222505, 34.909197],
							[113.218712, 34.916582],
							[113.22022, 34.917282],
							[113.217311, 34.922793],
							[113.217257, 34.924289],
							[113.215058, 34.928674],
							[113.213043, 34.93159],
							[113.211642, 34.932564],
							[113.207536, 34.932996],
							[113.205004, 34.942245],
							[113.200855, 34.952344],
							[113.196436, 34.959691],
							[113.189766, 34.965446],
							[113.181985, 34.970061],
							[113.176974, 34.973744],
							[113.173105, 34.981096],
							[113.172566, 34.987515],
							[113.174819, 34.997134],
							[113.173191, 35.008138],
							[113.174323, 35.018215],
							[113.174355, 35.030134],
							[113.168665, 35.042908],
							[113.166574, 35.045354],
							[113.165314, 35.046368],
							[113.162037, 35.046656],
							[113.148287, 35.043586],
							[113.141379, 35.040516],
							[113.136281, 35.038652],
							[113.13279, 35.038316],
							[113.126766, 35.039372],
							[113.110288, 35.043017],
							[113.103585, 35.044107],
							[113.096236, 35.044634],
							[113.094404, 35.044614],
							[113.064908, 35.040119],
							[113.052634, 35.039605],
							[113.048495, 35.039934],
							[113.049659, 35.035267],
							[113.049907, 35.029414],
							[113.049595, 35.026268],
							[113.049002, 35.022855],
							[113.048549, 35.02158],
							[113.047569, 35.020826],
							[113.044045, 35.020408],
							[113.035736, 35.022218],
							[113.033365, 35.022944],
							[113.029216, 35.02378],
							[113.023763, 35.024384],
							[113.014301, 35.026118],
							[113.00846, 35.02683],
							[113.001423, 35.028064],
							[112.995949, 35.029791],
							[112.991293, 35.030901],
							[112.986659, 35.031525],
							[112.986066, 35.032032],
							[112.98181, 35.033163],
							[112.980333, 35.033327],
							[112.977715, 35.032759],
							[112.976562, 35.031964],
							[112.97487, 35.030127],
							[112.97391, 35.027331],
							[112.97349, 35.022471],
							[112.972391, 35.019167],
							[112.97168, 35.018228],
							[112.970246, 35.017851],
							[112.968371, 35.018749],
							[112.965785, 35.017351],
							[112.963673, 35.017049],
							[112.962024, 35.016295],
							[112.960752, 35.014643],
							[112.956463, 35.00061],
							[112.95323, 34.997073],
							[112.950374, 34.99516],
							[112.945676, 34.993123],
							[112.942788, 34.991697],
							[112.941279, 34.990381],
							[112.938736, 34.987302],
							[112.937119, 34.986102],
							[112.934307, 34.985348],
							[112.931828, 34.985197],
							[112.92992, 34.985416],
							[112.927776, 34.9865],
							[112.925006, 34.989352],
							[112.923648, 34.990058],
							[112.920685, 34.990662],
							[112.917463, 34.990696],
							[112.910986, 34.993021],
							[112.910124, 34.995345],
							[112.910124, 34.996915],
							[112.91076, 35.001941],
							[112.910081, 35.003593],
							[112.907236, 35.007795],
							[112.905544, 35.010003],
							[112.903938, 35.014582],
							[112.903076, 35.015857],
							[112.901503, 35.016755],
							[112.897343, 35.017803],
							[112.893452, 35.017735],
							[112.889713, 35.016501],
							[112.888247, 35.015452],
							[112.887385, 35.013389],
							[112.887256, 35.00992],
							[112.886426, 35.008494],
							[112.884357, 35.008083],
							[112.882741, 35.008371],
							[112.878559, 35.010304],
							[112.876921, 35.01014],
							[112.87579, 35.00931],
							[112.874906, 35.007343],
							[112.874809, 35.004984],
							[112.876328, 35.000988],
							[112.876199, 35.000295],
							[112.874658, 34.999637],
							[112.872524, 34.996065],
							[112.870218, 34.992691],
							[112.869087, 34.991642],
							[112.867147, 34.990703],
							[112.864334, 34.989921],
							[112.860142, 34.989544],
							[112.863418, 34.985828],
							[112.865627, 34.984017],
							[112.867438, 34.982927],
							[112.872955, 34.980726],
							[112.87385, 34.980047],
							[112.874787, 34.977852],
							[112.87552, 34.967092],
							[112.87663, 34.965363],
							[112.883204, 34.964883],
							[112.884422, 34.963889],
							[112.884626, 34.963086],
							[112.883495, 34.95836],
							[112.884034, 34.956789],
							[112.891631, 34.952207],
							[112.89425, 34.951699],
							[112.899681, 34.952241],
							[112.902063, 34.95126],
							[112.90271, 34.950252],
							[112.903388, 34.94759],
							[112.903001, 34.938794],
							[112.903054, 34.936228],
							[112.903873, 34.932832],
							[112.905824, 34.929854],
							[112.908583, 34.926999],
							[112.911686, 34.925126],
							[112.920997, 34.920048],
							[112.92312, 34.918531],
							[112.925351, 34.916397],
							[112.926828, 34.913994],
							[112.928035, 34.910323],
							[112.929209, 34.898716],
							[112.929425, 34.897782],
							[112.936473, 34.88894],
							[112.936828, 34.888157],
							[112.933822, 34.887292],
							[112.93158, 34.88769],
							[112.92978, 34.887388],
							[112.92867, 34.885789],
							[112.928961, 34.872674],
							[112.928918, 34.867167],
							[112.932184, 34.835586],
							[112.938175, 34.831595],
							[112.942992, 34.8311],
							[112.950902, 34.835483],
							[112.954577, 34.837771],
							[112.965429, 34.843122],
							[112.969007, 34.844557],
							[112.97613, 34.847786],
							[112.981594, 34.850719],
							[112.985582, 34.853226],
							[112.989062, 34.856323],
							[112.990841, 34.859963],
							[112.992877, 34.863521],
							[112.996143, 34.864894],
							[112.999914, 34.864812],
							[113.004376, 34.864077],
							[113.016047, 34.860451],
							[113.025035, 34.854922],
							[113.039594, 34.841961],
							[113.048183, 34.838286],
							[113.060253, 34.837558],
							[113.070415, 34.839131],
							[113.072204, 34.839941],
							[113.078012, 34.841233],
							[113.088972, 34.842668],
							[113.107659, 34.844688],
							[113.115655, 34.8476],
							[113.119405, 34.853981],
							[113.126011, 34.854572],
							[113.134945, 34.855087],
							[113.147909, 34.856117],
							[113.147963, 34.856282],
							[113.142025, 34.877323],
							[113.139795, 34.884635],
							[113.140053, 34.8848],
							[113.153158, 34.887244],
							[113.156929, 34.887484],
							[113.16276, 34.88962],
							[113.16873, 34.891109],
							[113.170497, 34.891418],
							[113.179539, 34.893684],
							[113.184248, 34.895798],
							[113.189593, 34.897494],
							[113.197654, 34.900294],
							[113.207763, 34.90304],
							[113.214326, 34.905037],
							[113.220328, 34.906602]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410882,
				"name": "沁阳市",
				"center": [112.934538, 35.08901],
				"centroid": [112.881775, 35.128147],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 8,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[112.735284, 35.043339],
							[112.738938, 35.041674],
							[112.742181, 35.040605],
							[112.745404, 35.039906],
							[112.748831, 35.039461],
							[112.753443, 35.039173],
							[112.75771, 35.03772],
							[112.761493, 35.036117],
							[112.766633, 35.033122],
							[112.771903, 35.02925],
							[112.778477, 35.023863],
							[112.781796, 35.019524],
							[112.786021, 35.016981],
							[112.78685, 35.015692],
							[112.789523, 35.014383],
							[112.792239, 35.012512],
							[112.793112, 35.01147],
							[112.793855, 35.009242],
							[112.794017, 35.005053],
							[112.794405, 35.004779],
							[112.800213, 35.003223],
							[112.803888, 35.00181],
							[112.806496, 35.000576],
							[112.809945, 34.997491],
							[112.811734, 34.994083],
							[112.814374, 34.99311],
							[112.817294, 34.992787],
							[112.82625, 34.990957],
							[112.830151, 34.99108],
							[112.832414, 34.991553],
							[112.836433, 34.993569],
							[112.84027, 34.994104],
							[112.84749, 34.995784],
							[112.849559, 34.996538],
							[112.8529, 34.996531],
							[112.854818, 34.99553],
							[112.860142, 34.989544],
							[112.864334, 34.989921],
							[112.867147, 34.990703],
							[112.869087, 34.991642],
							[112.870218, 34.992691],
							[112.872524, 34.996065],
							[112.874658, 34.999637],
							[112.876199, 35.000295],
							[112.876328, 35.000988],
							[112.874809, 35.004984],
							[112.874906, 35.007343],
							[112.87579, 35.00931],
							[112.876921, 35.01014],
							[112.878559, 35.010304],
							[112.882741, 35.008371],
							[112.884357, 35.008083],
							[112.886426, 35.008494],
							[112.887256, 35.00992],
							[112.887385, 35.013389],
							[112.888247, 35.015452],
							[112.889713, 35.016501],
							[112.893452, 35.017735],
							[112.897343, 35.017803],
							[112.901503, 35.016755],
							[112.903076, 35.015857],
							[112.903938, 35.014582],
							[112.905544, 35.010003],
							[112.907236, 35.007795],
							[112.910081, 35.003593],
							[112.91076, 35.001941],
							[112.910124, 34.996915],
							[112.910124, 34.995345],
							[112.910986, 34.993021],
							[112.917463, 34.990696],
							[112.920685, 34.990662],
							[112.923648, 34.990058],
							[112.925006, 34.989352],
							[112.927776, 34.9865],
							[112.92992, 34.985416],
							[112.931828, 34.985197],
							[112.934307, 34.985348],
							[112.937119, 34.986102],
							[112.938736, 34.987302],
							[112.941279, 34.990381],
							[112.942788, 34.991697],
							[112.945676, 34.993123],
							[112.950374, 34.99516],
							[112.95323, 34.997073],
							[112.956463, 35.00061],
							[112.960752, 35.014643],
							[112.962024, 35.016295],
							[112.963673, 35.017049],
							[112.965785, 35.017351],
							[112.968371, 35.018749],
							[112.970246, 35.017851],
							[112.97168, 35.018228],
							[112.972391, 35.019167],
							[112.97349, 35.022471],
							[112.97391, 35.027331],
							[112.97487, 35.030127],
							[112.976562, 35.031964],
							[112.977715, 35.032759],
							[112.980333, 35.033327],
							[112.98181, 35.033163],
							[112.986066, 35.032032],
							[112.986659, 35.031525],
							[112.991293, 35.030901],
							[112.995949, 35.029791],
							[113.001423, 35.028064],
							[113.00846, 35.02683],
							[113.014301, 35.026118],
							[113.023763, 35.024384],
							[113.029216, 35.02378],
							[113.033365, 35.022944],
							[113.035736, 35.022218],
							[113.044045, 35.020408],
							[113.047569, 35.020826],
							[113.048549, 35.02158],
							[113.049002, 35.022855],
							[113.049595, 35.026268],
							[113.049907, 35.029414],
							[113.049659, 35.035267],
							[113.048495, 35.039934],
							[113.042083, 35.040893],
							[113.035208, 35.043504],
							[113.030391, 35.046485],
							[113.018407, 35.058715],
							[113.003848, 35.07258],
							[112.9943, 35.080765],
							[112.987273, 35.084573],
							[112.978695, 35.08871],
							[112.978189, 35.089231],
							[112.974719, 35.096572],
							[112.972531, 35.099174],
							[112.970365, 35.100968],
							[112.965041, 35.104084],
							[112.964082, 35.105864],
							[112.964136, 35.108041],
							[112.964567, 35.109164],
							[112.966485, 35.112019],
							[112.967746, 35.114422],
							[112.968511, 35.117538],
							[112.968652, 35.120536],
							[112.968382, 35.122521],
							[112.967692, 35.124082],
							[112.966658, 35.125088],
							[112.96516, 35.12567],
							[112.962207, 35.125828],
							[112.959998, 35.126464],
							[112.958974, 35.127471],
							[112.958198, 35.129004],
							[112.956323, 35.137909],
							[112.952713, 35.145349],
							[112.951775, 35.147723],
							[112.95142, 35.150084],
							[112.952174, 35.152206],
							[112.952896, 35.152959],
							[112.959804, 35.155354],
							[112.963069, 35.15675],
							[112.964093, 35.157988],
							[112.966184, 35.163319],
							[112.967337, 35.165214],
							[112.973232, 35.170325],
							[112.981508, 35.177118],
							[112.98861, 35.182728],
							[112.990485, 35.185751],
							[112.995022, 35.194459],
							[112.997037, 35.197249],
							[113.001811, 35.20056],
							[113.007598, 35.204232],
							[113.008439, 35.205114],
							[113.009958, 35.207651],
							[113.010831, 35.210852],
							[113.010939, 35.212493],
							[113.010411, 35.216486],
							[113.009581, 35.218674],
							[113.005023, 35.225088],
							[113.002824, 35.225491],
							[113.001057, 35.223146],
							[112.99972, 35.219789],
							[112.999914, 35.217874],
							[112.99944, 35.21583],
							[112.998007, 35.215016],
							[112.994699, 35.213943],
							[112.992899, 35.211891],
							[112.990431, 35.210818],
							[112.988965, 35.211071],
							[112.987424, 35.212178],
							[112.98639, 35.21358],
							[112.986368, 35.214702],
							[112.987931, 35.219529],
							[112.988383, 35.224534],
							[112.987758, 35.228315],
							[112.988804, 35.230311],
							[112.993632, 35.234543],
							[112.994364, 35.235651],
							[112.994558, 35.237695],
							[112.993201, 35.240668],
							[112.991013, 35.243983],
							[112.990873, 35.245576],
							[112.992069, 35.247025],
							[112.991789, 35.248618],
							[112.990269, 35.250169],
							[112.988728, 35.250689],
							[112.982629, 35.250204],
							[112.981282, 35.250873],
							[112.979439, 35.253006],
							[112.978879, 35.254332],
							[112.978965, 35.255706],
							[112.981109, 35.258296],
							[112.984181, 35.261603],
							[112.986282, 35.262601],
							[112.988448, 35.26295],
							[112.99042, 35.262881],
							[112.993804, 35.260975],
							[112.994612, 35.260845],
							[112.995895, 35.261713],
							[112.997694, 35.266032],
							[112.999225, 35.267064],
							[112.999947, 35.268738],
							[112.999807, 35.272073],
							[113.000098, 35.275749],
							[113.000453, 35.277272],
							[113.001369, 35.278325],
							[113.003913, 35.280333],
							[113.007458, 35.284057],
							[113.009172, 35.287603],
							[113.010659, 35.29136],
							[113.010691, 35.292597],
							[113.009075, 35.294031],
							[113.007846, 35.294352],
							[113.003169, 35.294673],
							[113.001628, 35.295042],
							[113.000151, 35.296429],
							[113.000561, 35.29787],
							[113.001649, 35.299059],
							[113.004344, 35.300377],
							[113.00512, 35.301634],
							[113.004096, 35.303916],
							[113.002889, 35.305172],
							[113.000874, 35.305876],
							[112.996757, 35.306484],
							[112.992209, 35.307986],
							[112.989202, 35.308219],
							[112.989935, 35.306047],
							[112.991552, 35.302324],
							[112.991897, 35.296101],
							[112.990582, 35.293888],
							[112.988955, 35.28998],
							[112.988276, 35.289304],
							[112.985689, 35.288566],
							[112.984633, 35.287534],
							[112.98279, 35.287261],
							[112.980689, 35.288648],
							[112.97474, 35.289584],
							[112.971884, 35.29108],
							[112.969988, 35.292515],
							[112.968544, 35.293171],
							[112.964697, 35.293123],
							[112.960203, 35.291633],
							[112.955396, 35.289522],
							[112.951862, 35.287118],
							[112.948176, 35.285984],
							[112.945859, 35.285758],
							[112.941495, 35.284884],
							[112.937636, 35.284768],
							[112.936052, 35.284392],
							[112.934285, 35.282178],
							[112.934188, 35.278318],
							[112.936279, 35.274737],
							[112.936656, 35.271888],
							[112.935955, 35.269469],
							[112.935136, 35.268232],
							[112.934199, 35.262752],
							[112.933143, 35.260975],
							[112.929662, 35.256881],
							[112.926925, 35.254892],
							[112.925513, 35.254195],
							[112.924263, 35.252418],
							[112.922172, 35.251776],
							[112.914079, 35.247722],
							[112.908788, 35.246],
							[112.907085, 35.245809],
							[112.903604, 35.246205],
							[112.901653, 35.246731],
							[112.897278, 35.248529],
							[112.893625, 35.248625],
							[112.8862, 35.246253],
							[112.884734, 35.24423],
							[112.882741, 35.243854],
							[112.877557, 35.24371],
							[112.868117, 35.243908],
							[112.864808, 35.244872],
							[112.862912, 35.245139],
							[112.857103, 35.24736],
							[112.855788, 35.248727],
							[112.855163, 35.253307],
							[112.853256, 35.254113],
							[112.850594, 35.252473],
							[112.848719, 35.252603],
							[112.84291, 35.255316],
							[112.83874, 35.255733],
							[112.835862, 35.255254],
							[112.834203, 35.255405],
							[112.832317, 35.256102],
							[112.822208, 35.258091],
							[112.818555, 35.258248],
							[112.816012, 35.257633],
							[112.814762, 35.256491],
							[112.814277, 35.253997],
							[112.813016, 35.25153],
							[112.812628, 35.250122],
							[112.811001, 35.248549],
							[112.806388, 35.24546],
							[112.805375, 35.245255],
							[112.800472, 35.24103],
							[112.798252, 35.238672],
							[112.79586, 35.238337],
							[112.790989, 35.238378],
							[112.787357, 35.237168],
							[112.784803, 35.235227],
							[112.784415, 35.23455],
							[112.784566, 35.230906],
							[112.78615, 35.228493],
							[112.786301, 35.226319],
							[112.785977, 35.224821],
							[112.784943, 35.222921],
							[112.781193, 35.220986],
							[112.779899, 35.219577],
							[112.778369, 35.215515],
							[112.776085, 35.211522],
							[112.772561, 35.207521],
							[112.767248, 35.204088],
							[112.765782, 35.203589],
							[112.763541, 35.203589],
							[112.758012, 35.20441],
							[112.757344, 35.20268],
							[112.757258, 35.199794],
							[112.757613, 35.198118],
							[112.75867, 35.196709],
							[112.762581, 35.194267],
							[112.764338, 35.192297],
							[112.765599, 35.190163],
							[112.766483, 35.187516],
							[112.766892, 35.18296],
							[112.766483, 35.180724],
							[112.764511, 35.173492],
							[112.762334, 35.16117],
							[112.761978, 35.158057],
							[112.762334, 35.157112],
							[112.764101, 35.155764],
							[112.764564, 35.15406],
							[112.764392, 35.150413],
							[112.763271, 35.144651],
							[112.763034, 35.140592],
							[112.76339, 35.13947],
							[112.765502, 35.138238],
							[112.765858, 35.137122],
							[112.764446, 35.127738],
							[112.764683, 35.122624],
							[112.765965, 35.117209],
							[112.767259, 35.112451],
							[112.767323, 35.109623],
							[112.766321, 35.107213],
							[112.763153, 35.104008],
							[112.759855, 35.101831],
							[112.754273, 35.098893],
							[112.753809, 35.098072],
							[112.753605, 35.0911],
							[112.753788, 35.088957],
							[112.753217, 35.085101],
							[112.749219, 35.080868],
							[112.748637, 35.078868],
							[112.747871, 35.073279],
							[112.745813, 35.067867],
							[112.743345, 35.064572],
							[112.740166, 35.06225],
							[112.737461, 35.06125],
							[112.73605, 35.06001],
							[112.735521, 35.05766],
							[112.735575, 35.05372],
							[112.735284, 35.043339]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 410883,
				"name": "孟州市",
				"center": [112.78708, 34.90963],
				"centroid": [112.763989, 34.921726],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 410800
				},
				"subFeatureIndex": 9,
				"acroutes": [100000, 410000, 410800]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[112.735284, 35.043339],
							[112.727105, 35.035041],
							[112.721986, 35.032094],
							[112.716512, 35.030381],
							[112.7115, 35.029051],
							[112.705627, 35.025926],
							[112.698967, 35.023205],
							[112.688309, 35.016247],
							[112.684602, 35.012505],
							[112.681531, 35.004505],
							[112.678168, 34.99673],
							[112.675938, 34.993521],
							[112.670172, 34.989626],
							[112.666454, 34.987508],
							[112.664213, 34.987501],
							[112.65974, 34.988433],
							[112.655979, 34.989599],
							[112.654622, 34.989599],
							[112.653081, 34.98866],
							[112.65196, 34.986774],
							[112.651378, 34.983537],
							[112.650376, 34.981658],
							[112.644664, 34.978881],
							[112.642369, 34.978703],
							[112.638424, 34.978812],
							[112.624781, 34.977887],
							[112.615546, 34.979587],
							[112.614199, 34.979999],
							[112.612787, 34.979526],
							[112.610373, 34.978058],
							[112.606084, 34.973525],
							[112.599855, 34.971701],
							[112.595975, 34.971001],
							[112.591923, 34.971296],
							[112.584003, 34.972565],
							[112.581599, 34.972448],
							[112.579423, 34.971749],
							[112.578075, 34.970692],
							[112.574907, 34.965638],
							[112.569217, 34.958607],
							[112.571383, 34.956947],
							[112.571502, 34.949977],
							[112.572084, 34.948248],
							[112.575133, 34.947226],
							[112.577041, 34.946135],
							[112.578162, 34.944105],
							[112.578453, 34.940105],
							[112.579326, 34.933401],
							[112.580683, 34.930046],
							[112.582677, 34.928104],
							[112.585371, 34.926512],
							[112.59201, 34.92551],
							[112.604403, 34.92271],
							[112.618218, 34.920308],
							[112.62351, 34.919897],
							[112.637271, 34.920267],
							[112.638446, 34.919354],
							[112.638629, 34.914132],
							[112.638166, 34.906781],
							[112.638177, 34.896979],
							[112.641291, 34.896642],
							[112.642142, 34.895709],
							[112.642347, 34.889853],
							[112.642024, 34.887979],
							[112.640828, 34.885857],
							[112.639955, 34.885054],
							[112.636355, 34.882665],
							[112.635838, 34.880399],
							[112.635676, 34.873031],
							[112.636786, 34.860883],
							[112.638187, 34.854187],
							[112.642757, 34.856591],
							[112.656637, 34.859627],
							[112.676089, 34.853178],
							[112.693482, 34.843658],
							[112.696737, 34.840621],
							[112.711306, 34.835057],
							[112.722137, 34.828819],
							[112.736879, 34.829657],
							[112.745274, 34.828476],
							[112.753271, 34.828579],
							[112.762959, 34.831251],
							[112.773304, 34.835868],
							[112.780923, 34.837475],
							[112.78782, 34.837599],
							[112.799858, 34.836252],
							[112.813382, 34.8329],
							[112.822381, 34.8257],
							[112.827295, 34.819193],
							[112.838643, 34.812136],
							[112.848299, 34.810879],
							[112.853525, 34.810741],
							[112.857146, 34.813737],
							[112.860282, 34.818472],
							[112.866479, 34.82954],
							[112.873225, 34.832824],
							[112.87732, 34.84466],
							[112.879141, 34.849695],
							[112.881501, 34.851886],
							[112.88454, 34.853068],
							[112.902332, 34.852285],
							[112.914154, 34.847477],
							[112.924532, 34.840491],
							[112.932184, 34.835586],
							[112.928918, 34.867167],
							[112.928961, 34.872674],
							[112.92867, 34.885789],
							[112.92978, 34.887388],
							[112.93158, 34.88769],
							[112.933822, 34.887292],
							[112.936828, 34.888157],
							[112.936473, 34.88894],
							[112.929425, 34.897782],
							[112.929209, 34.898716],
							[112.928035, 34.910323],
							[112.926828, 34.913994],
							[112.925351, 34.916397],
							[112.92312, 34.918531],
							[112.920997, 34.920048],
							[112.911686, 34.925126],
							[112.908583, 34.926999],
							[112.905824, 34.929854],
							[112.903873, 34.932832],
							[112.903054, 34.936228],
							[112.903001, 34.938794],
							[112.903388, 34.94759],
							[112.90271, 34.950252],
							[112.902063, 34.95126],
							[112.899681, 34.952241],
							[112.89425, 34.951699],
							[112.891631, 34.952207],
							[112.884034, 34.956789],
							[112.883495, 34.95836],
							[112.884626, 34.963086],
							[112.884422, 34.963889],
							[112.883204, 34.964883],
							[112.87663, 34.965363],
							[112.87552, 34.967092],
							[112.874787, 34.977852],
							[112.87385, 34.980047],
							[112.872955, 34.980726],
							[112.867438, 34.982927],
							[112.865627, 34.984017],
							[112.863418, 34.985828],
							[112.860142, 34.989544],
							[112.854818, 34.99553],
							[112.8529, 34.996531],
							[112.849559, 34.996538],
							[112.84749, 34.995784],
							[112.84027, 34.994104],
							[112.836433, 34.993569],
							[112.832414, 34.991553],
							[112.830151, 34.99108],
							[112.82625, 34.990957],
							[112.817294, 34.992787],
							[112.814374, 34.99311],
							[112.811734, 34.994083],
							[112.809945, 34.997491],
							[112.806496, 35.000576],
							[112.803888, 35.00181],
							[112.800213, 35.003223],
							[112.794405, 35.004779],
							[112.794017, 35.005053],
							[112.793855, 35.009242],
							[112.793112, 35.01147],
							[112.792239, 35.012512],
							[112.789523, 35.014383],
							[112.78685, 35.015692],
							[112.786021, 35.016981],
							[112.781796, 35.019524],
							[112.778477, 35.023863],
							[112.771903, 35.02925],
							[112.766633, 35.033122],
							[112.761493, 35.036117],
							[112.75771, 35.03772],
							[112.753443, 35.039173],
							[112.748831, 35.039461],
							[112.745404, 35.039906],
							[112.742181, 35.040605],
							[112.738938, 35.041674],
							[112.735284, 35.043339]
						]
					]
				]
			}
		}]
	})
}));
