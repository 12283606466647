/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define(['exports', 'echarts'], factory);
	} else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
		// CommonJS
		factory(exports, require('echarts'));
	} else {
		// Browser globals
		factory({}, root.echarts);
	}
}(this, function(exports, echarts) {
	var log = function(msg) {
		if (typeof console !== 'undefined') {
			console && console.error && console.error(msg);
		}
	}
	if (!echarts) {
		log('ECharts is not Loaded');
		return;
	}
	if (!echarts.registerMap) {
		log('ECharts Map is not loaded')
		return;
	}
	echarts.registerMap('chif', {
		"type": "FeatureCollection",
		"features": [{
			"type": "Feature",
			"properties": {
				"adcode": 150402,
				"name": "红山区",
				"center": [118.961087, 42.269732],
				"centroid": [118.984114, 42.189403],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 0,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[118.887381, 42.234182],
							[118.906322, 42.220407],
							[118.913547, 42.218549],
							[118.905952, 42.210064],
							[118.893633, 42.20566],
							[118.875571, 42.185089],
							[118.845978, 42.14785],
							[118.847831, 42.121433],
							[118.868995, 42.127583],
							[118.872746, 42.125318],
							[118.868717, 42.115607],
							[118.861863, 42.112855],
							[118.849266, 42.119248],
							[118.864179, 42.108039],
							[118.856167, 42.098001],
							[118.865383, 42.094925],
							[118.859594, 42.084967],
							[118.847645, 42.07861],
							[118.84709, 42.065085],
							[118.868486, 42.05824],
							[118.868995, 42.064194],
							[118.876498, 42.058889],
							[118.893772, 42.055729],
							[118.893448, 42.04779],
							[118.907063, 42.044266],
							[118.916881, 42.034502],
							[118.947956, 42.036001],
							[118.961803, 42.042078],
							[118.974493, 42.038715],
							[118.983894, 42.051112],
							[118.993527, 42.057349],
							[118.99663, 42.068811],
							[119.006726, 42.079258],
							[119.011635, 42.079015],
							[119.021406, 42.088732],
							[119.03794, 42.087881],
							[119.052481, 42.105934],
							[119.069478, 42.101482],
							[119.091615, 42.113583],
							[119.093236, 42.122566],
							[119.077629, 42.13794],
							[119.076239, 42.148173],
							[119.085224, 42.149751],
							[119.083232, 42.155736],
							[119.092309, 42.155372],
							[119.091476, 42.161761],
							[119.101016, 42.155938],
							[119.109491, 42.166128],
							[119.101803, 42.182583],
							[119.081241, 42.199194],
							[119.092726, 42.210832],
							[119.088604, 42.213822],
							[119.095134, 42.228325],
							[119.075961, 42.232041],
							[119.06054, 42.237413],
							[119.049008, 42.236201],
							[119.029742, 42.242502],
							[119.031178, 42.249205],
							[119.040116, 42.253849],
							[119.049842, 42.252355],
							[119.059706, 42.26806],
							[119.054982, 42.273308],
							[119.072442, 42.274357],
							[119.090642, 42.279483],
							[119.101618, 42.276375],
							[119.117225, 42.286142],
							[119.134638, 42.284851],
							[119.12306, 42.288685],
							[119.131396, 42.29619],
							[119.147883, 42.29619],
							[119.155432, 42.28925],
							[119.173493, 42.294294],
							[119.168399, 42.302606],
							[119.174327, 42.302969],
							[119.183589, 42.312571],
							[119.183126, 42.318057],
							[119.17178, 42.320154],
							[119.165343, 42.329027],
							[119.139084, 42.322251],
							[119.118661, 42.321727],
							[119.116901, 42.318863],
							[119.081982, 42.313539],
							[119.057437, 42.316927],
							[119.033494, 42.322211],
							[119.022796, 42.327696],
							[119.00705, 42.331487],
							[118.995426, 42.330318],
							[118.97829, 42.332899],
							[118.953653, 42.333907],
							[118.944437, 42.322413],
							[118.940778, 42.328301],
							[118.910166, 42.346044],
							[118.907989, 42.342052],
							[118.893262, 42.345036],
							[118.897384, 42.33556],
							[118.889419, 42.340842],
							[118.884093, 42.327414],
							[118.907387, 42.315475],
							[118.931747, 42.306035],
							[118.945363, 42.289734],
							[118.95657, 42.284165],
							[118.944159, 42.276739],
							[118.941519, 42.269675],
							[118.931377, 42.262893],
							[118.915677, 42.256958],
							[118.908453, 42.250578],
							[118.896689, 42.233818],
							[118.887381, 42.234182]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150403,
				"name": "元宝山区",
				"center": [119.289877, 42.041168],
				"centroid": [119.243478, 42.181693],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 1,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[119.502352, 42.388042],
							[119.508048, 42.398718],
							[119.500684, 42.406452],
							[119.491746, 42.405123],
							[119.476232, 42.409231],
							[119.471971, 42.405002],
							[119.459467, 42.406291],
							[119.458541, 42.396341],
							[119.427975, 42.403753],
							[119.402041, 42.398718],
							[119.394353, 42.41636],
							[119.393797, 42.42373],
							[119.379811, 42.423045],
							[119.349107, 42.418092],
							[119.349477, 42.422038],
							[119.337205, 42.415232],
							[119.339474, 42.402061],
							[119.335723, 42.382078],
							[119.343364, 42.378734],
							[119.336417, 42.368537],
							[119.319375, 42.365837],
							[119.30951, 42.374059],
							[119.296219, 42.380144],
							[119.294552, 42.385906],
							[119.274221, 42.389774],
							[119.272137, 42.380869],
							[119.262736, 42.379378],
							[119.251621, 42.387759],
							[119.236477, 42.388525],
							[119.230086, 42.384576],
							[119.218601, 42.387518],
							[119.222491, 42.377928],
							[119.231522, 42.378129],
							[119.212488, 42.363419],
							[119.213877, 42.353059],
							[119.205819, 42.347536],
							[119.197668, 42.331044],
							[119.183126, 42.318057],
							[119.183589, 42.312571],
							[119.174327, 42.302969],
							[119.168399, 42.302606],
							[119.173493, 42.294294],
							[119.155432, 42.28925],
							[119.147883, 42.29619],
							[119.131396, 42.29619],
							[119.12306, 42.288685],
							[119.134638, 42.284851],
							[119.117225, 42.286142],
							[119.101618, 42.276375],
							[119.090642, 42.279483],
							[119.072442, 42.274357],
							[119.054982, 42.273308],
							[119.059706, 42.26806],
							[119.049842, 42.252355],
							[119.040116, 42.253849],
							[119.031178, 42.249205],
							[119.029742, 42.242502],
							[119.049008, 42.236201],
							[119.06054, 42.237413],
							[119.075961, 42.232041],
							[119.095134, 42.228325],
							[119.088604, 42.213822],
							[119.092726, 42.210832],
							[119.081241, 42.199194],
							[119.101803, 42.182583],
							[119.109491, 42.166128],
							[119.101016, 42.155938],
							[119.091476, 42.161761],
							[119.092309, 42.155372],
							[119.083232, 42.155736],
							[119.085224, 42.149751],
							[119.076239, 42.148173],
							[119.077629, 42.13794],
							[119.093236, 42.122566],
							[119.091615, 42.113583],
							[119.069478, 42.101482],
							[119.052481, 42.105934],
							[119.03794, 42.087881],
							[119.045257, 42.074804],
							[119.062114, 42.071929],
							[119.060586, 42.05824],
							[119.077212, 42.036041],
							[119.083881, 42.030328],
							[119.099117, 42.032719],
							[119.123848, 42.050058],
							[119.123153, 42.055365],
							[119.141909, 42.052975],
							[119.151495, 42.041592],
							[119.154969, 42.048276],
							[119.169835, 42.035636],
							[119.183821, 42.027613],
							[119.174096, 42.023034],
							[119.181644, 42.005809],
							[119.197529, 42.001917],
							[119.197205, 41.994214],
							[119.188082, 41.982983],
							[119.181181, 41.981645],
							[119.165852, 41.986105],
							[119.16349, 41.978319],
							[119.139501, 41.97325],
							[119.111251, 41.974588],
							[119.111992, 41.960798],
							[119.108611, 41.953212],
							[119.121069, 41.940675],
							[119.136352, 41.940715],
							[119.143159, 41.936049],
							[119.159461, 41.939782],
							[119.181135, 41.935602],
							[119.207301, 41.937266],
							[119.219249, 41.952725],
							[119.229994, 41.961609],
							[119.231059, 41.98128],
							[119.233837, 41.9872],
							[119.246203, 41.990728],
							[119.261439, 41.991052],
							[119.273897, 41.987241],
							[119.287929, 41.989389],
							[119.282881, 41.971344],
							[119.290291, 41.961204],
							[119.329332, 41.960555],
							[119.324608, 41.968951],
							[119.33049, 41.977954],
							[119.349385, 41.989795],
							[119.365408, 42.011564],
							[119.374439, 42.020967],
							[119.37657, 42.037257],
							[119.381015, 42.040133],
							[119.38347, 42.057876],
							[119.378144, 42.062493],
							[119.384165, 42.077314],
							[119.384998, 42.089541],
							[119.377959, 42.095978],
							[119.376523, 42.103992],
							[119.362491, 42.109415],
							[119.352395, 42.118196],
							[119.315994, 42.119248],
							[119.304416, 42.128999],
							[119.308492, 42.147324],
							[119.287096, 42.154685],
							[119.282603, 42.170899],
							[119.276351, 42.174821],
							[119.276305, 42.186059],
							[119.265885, 42.188929],
							[119.252362, 42.187716],
							[119.237913, 42.200892],
							[119.24787, 42.205741],
							[119.252084, 42.218185],
							[119.271026, 42.235313],
							[119.276166, 42.242784],
							[119.278667, 42.257321],
							[119.284456, 42.265153],
							[119.305713, 42.268787],
							[119.330258, 42.28469],
							[119.336649, 42.293568],
							[119.348227, 42.300548],
							[119.362537, 42.30309],
							[119.374208, 42.299701],
							[119.393334, 42.307931],
							[119.415657, 42.309666],
							[119.422696, 42.315556],
							[119.431912, 42.316483],
							[119.438488, 42.32814],
							[119.452706, 42.332697],
							[119.482577, 42.346971],
							[119.48957, 42.35318],
							[119.498925, 42.372084],
							[119.502352, 42.388042]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150404,
				"name": "松山区",
				"center": [118.938958, 42.281046],
				"centroid": [118.614776, 42.403627],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 2,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[119.183126, 42.318057],
							[119.197668, 42.331044],
							[119.205819, 42.347536],
							[119.213877, 42.353059],
							[119.212488, 42.363419],
							[119.231522, 42.378129],
							[119.222491, 42.377928],
							[119.218601, 42.387518],
							[119.230086, 42.384576],
							[119.236477, 42.388525],
							[119.251621, 42.387759],
							[119.262736, 42.379378],
							[119.272137, 42.380869],
							[119.274221, 42.389774],
							[119.294552, 42.385906],
							[119.296219, 42.380144],
							[119.30951, 42.374059],
							[119.319375, 42.365837],
							[119.336417, 42.368537],
							[119.343364, 42.378734],
							[119.335723, 42.382078],
							[119.339474, 42.402061],
							[119.337205, 42.415232],
							[119.349477, 42.422038],
							[119.349107, 42.418092],
							[119.379811, 42.423045],
							[119.393797, 42.42373],
							[119.394353, 42.41636],
							[119.402041, 42.398718],
							[119.427975, 42.403753],
							[119.458541, 42.396341],
							[119.459467, 42.406291],
							[119.471971, 42.405002],
							[119.476232, 42.409231],
							[119.491746, 42.405123],
							[119.500684, 42.406452],
							[119.508048, 42.398718],
							[119.517171, 42.404881],
							[119.539771, 42.42836],
							[119.548015, 42.4313],
							[119.56867, 42.432024],
							[119.570291, 42.444424],
							[119.575524, 42.449094],
							[119.577701, 42.465112],
							[119.5708, 42.49239],
							[119.585759, 42.505301],
							[119.595808, 42.504215],
							[119.616695, 42.512057],
							[119.629431, 42.530431],
							[119.638508, 42.534089],
							[119.643092, 42.549322],
							[119.655828, 42.573109],
							[119.655411, 42.583834],
							[119.648742, 42.592188],
							[119.632811, 42.613509],
							[119.621789, 42.619691],
							[119.60859, 42.62226],
							[119.60169, 42.629606],
							[119.615769, 42.647825],
							[119.620307, 42.66295],
							[119.614426, 42.670972],
							[119.593771, 42.67751],
							[119.582332, 42.67386],
							[119.57293, 42.655729],
							[119.567049, 42.651396],
							[119.53088, 42.643732],
							[119.48869, 42.64618],
							[119.465951, 42.65284],
							[119.453447, 42.659781],
							[119.448491, 42.668886],
							[119.448028, 42.697038],
							[119.440665, 42.706699],
							[119.402041, 42.713714],
							[119.385554, 42.722571],
							[119.373559, 42.71155],
							[119.362259, 42.705777],
							[119.341002, 42.704254],
							[119.325766, 42.700686],
							[119.313632, 42.692186],
							[119.310761, 42.67763],
							[119.316967, 42.655247],
							[119.328591, 42.643411],
							[119.342947, 42.622822],
							[119.364806, 42.620574],
							[119.359249, 42.60311],
							[119.34832, 42.597609],
							[119.329054, 42.599617],
							[119.313169, 42.589738],
							[119.312428, 42.571502],
							[119.30738, 42.54671],
							[119.292144, 42.543454],
							[119.286679, 42.536903],
							[119.274545, 42.532079],
							[119.261115, 42.530752],
							[119.249676, 42.540118],
							[119.251297, 42.554667],
							[119.247314, 42.55985],
							[119.232911, 42.561779],
							[119.189239, 42.556796],
							[119.185118, 42.541847],
							[119.169094, 42.524159],
							[119.148207, 42.516962],
							[119.124496, 42.500676],
							[119.109722, 42.492511],
							[119.086984, 42.485994],
							[119.067347, 42.484626],
							[119.047619, 42.477023],
							[119.035022, 42.474689],
							[118.979078, 42.475091],
							[118.954949, 42.466923],
							[118.944853, 42.468854],
							[118.945641, 42.49605],
							[118.960877, 42.505864],
							[118.982181, 42.508598],
							[118.980421, 42.520099],
							[118.961479, 42.52046],
							[118.931284, 42.534009],
							[118.919521, 42.534652],
							[118.901182, 42.528622],
							[118.889928, 42.532441],
							[118.884834, 42.540158],
							[118.872052, 42.543133],
							[118.83917, 42.531677],
							[118.827176, 42.532561],
							[118.802677, 42.527014],
							[118.795916, 42.527456],
							[118.78105, 42.542369],
							[118.751642, 42.558685],
							[118.736035, 42.577447],
							[118.716954, 42.585601],
							[118.686481, 42.585159],
							[118.660917, 42.574033],
							[118.622479, 42.580661],
							[118.611271, 42.584878],
							[118.602148, 42.593633],
							[118.595711, 42.61098],
							[118.577325, 42.614553],
							[118.560514, 42.602267],
							[118.552085, 42.600741],
							[118.531291, 42.610578],
							[118.515453, 42.614754],
							[118.491834, 42.606603],
							[118.487249, 42.607246],
							[118.475579, 42.62499],
							[118.463213, 42.63089],
							[118.444179, 42.62784],
							[118.43538, 42.619852],
							[118.421487, 42.615958],
							[118.374527, 42.625552],
							[118.35188, 42.631653],
							[118.279773, 42.643852],
							[118.255738, 42.646822],
							[118.225774, 42.655007],
							[118.208083, 42.66648],
							[118.192198, 42.671052],
							[118.165569, 42.67001],
							[118.143988, 42.672697],
							[118.126899, 42.671574],
							[118.088368, 42.664394],
							[118.06586, 42.655568],
							[118.016076, 42.630248],
							[118.005053, 42.627759],
							[117.977128, 42.630007],
							[117.947766, 42.626997],
							[117.934521, 42.629606],
							[117.900343, 42.628843],
							[117.87677, 42.634502],
							[117.846344, 42.638957],
							[117.832636, 42.643652],
							[117.823327, 42.629485],
							[117.801514, 42.612706],
							[117.792298, 42.598372],
							[117.797578, 42.58528],
							[117.808831, 42.57576],
							[117.815917, 42.574314],
							[117.829625, 42.564993],
							[117.849586, 42.546629],
							[117.864035, 42.528863],
							[117.874084, 42.510207],
							[117.887746, 42.498584],
							[117.91419, 42.479517],
							[117.940125, 42.462738],
							[117.954574, 42.444988],
							[117.997644, 42.416682],
							[118.011305, 42.404881],
							[118.024412, 42.384858],
							[118.021309, 42.3716],
							[118.008156, 42.355276],
							[118.008851, 42.346608],
							[118.016307, 42.333262],
							[118.038351, 42.311764],
							[118.055209, 42.304502],
							[118.059979, 42.298289],
							[118.047428, 42.280573],
							[118.023254, 42.267132],
							[117.999866, 42.260753],
							[117.971107, 42.247994],
							[117.970783, 42.236363],
							[117.977452, 42.2299],
							[117.99593, 42.225821],
							[118.020336, 42.213458],
							[118.033581, 42.199154],
							[118.061785, 42.189454],
							[118.089063, 42.183876],
							[118.106337, 42.17195],
							[118.109208, 42.165077],
							[118.10416, 42.148901],
							[118.089618, 42.123052],
							[118.091749, 42.109779],
							[118.097769, 42.105125],
							[118.127686, 42.097597],
							[118.150147, 42.086748],
							[118.155195, 42.081161],
							[118.154871, 42.069499],
							[118.149221, 42.064113],
							[118.138801, 42.065855],
							[118.140052, 42.054879],
							[118.115229, 42.045886],
							[118.116525, 42.037176],
							[118.125324, 42.032921],
							[118.141858, 42.031301],
							[118.189049, 42.030531],
							[118.204193, 42.034866],
							[118.212714, 42.043172],
							[118.220217, 42.058605],
							[118.212575, 42.08112],
							[118.226654, 42.09027],
							[118.239297, 42.09282],
							[118.261388, 42.088246],
							[118.263194, 42.09784],
							[118.279634, 42.10642],
							[118.306078, 42.104963],
							[118.327104, 42.108525],
							[118.348778, 42.118237],
							[118.365079, 42.114069],
							[118.387633, 42.095451],
							[118.394024, 42.092213],
							[118.422922, 42.090189],
							[118.449829, 42.083549],
							[118.466131, 42.086141],
							[118.50193, 42.103628],
							[118.525827, 42.10472],
							[118.538655, 42.101401],
							[118.544351, 42.093792],
							[118.555698, 42.09278],
							[118.55278, 42.086222],
							[118.539952, 42.07861],
							[118.537497, 42.071159],
							[118.544907, 42.070917],
							[118.571212, 42.087112],
							[118.577927, 42.084562],
							[118.57626, 42.057795],
							[118.580845, 42.054555],
							[118.593812, 42.061319],
							[118.600481, 42.072901],
							[118.609512, 42.080391],
							[118.626601, 42.089177],
							[118.64406, 42.101401],
							[118.662075, 42.109941],
							[118.686806, 42.113907],
							[118.717001, 42.110467],
							[118.747613, 42.100349],
							[118.759005, 42.102332],
							[118.762247, 42.110953],
							[118.760117, 42.12815],
							[118.767388, 42.142389],
							[118.774612, 42.148537],
							[118.79485, 42.159254],
							[118.796934, 42.173851],
							[118.818052, 42.182583],
							[118.81268, 42.190505],
							[118.826944, 42.199881],
							[118.809022, 42.202791],
							[118.810504, 42.20962],
							[118.828102, 42.208529],
							[118.82537, 42.221619],
							[118.831483, 42.229658],
							[118.869597, 42.238544],
							[118.887381, 42.234182],
							[118.896689, 42.233818],
							[118.908453, 42.250578],
							[118.915677, 42.256958],
							[118.931377, 42.262893],
							[118.941519, 42.269675],
							[118.944159, 42.276739],
							[118.95657, 42.284165],
							[118.945363, 42.289734],
							[118.931747, 42.306035],
							[118.907387, 42.315475],
							[118.884093, 42.327414],
							[118.889419, 42.340842],
							[118.897384, 42.33556],
							[118.893262, 42.345036],
							[118.907989, 42.342052],
							[118.910166, 42.346044],
							[118.940778, 42.328301],
							[118.944437, 42.322413],
							[118.953653, 42.333907],
							[118.97829, 42.332899],
							[118.995426, 42.330318],
							[119.00705, 42.331487],
							[119.022796, 42.327696],
							[119.033494, 42.322211],
							[119.057437, 42.316927],
							[119.081982, 42.313539],
							[119.116901, 42.318863],
							[119.118661, 42.321727],
							[119.139084, 42.322251],
							[119.165343, 42.329027],
							[119.17178, 42.320154],
							[119.183126, 42.318057]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150421,
				"name": "阿鲁科尔沁旗",
				"center": [120.094969, 43.87877],
				"centroid": [120.033011, 44.201823],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 3,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[120.422191, 43.379785],
							[120.431685, 43.387199],
							[120.454702, 43.395246],
							[120.480035, 43.412288],
							[120.492446, 43.414468],
							[120.515324, 43.414745],
							[120.542463, 43.428613],
							[120.571778, 43.429999],
							[120.605863, 43.425998],
							[120.641569, 43.44921],
							[120.656435, 43.452141],
							[120.668754, 43.460773],
							[120.681953, 43.474195],
							[120.68061, 43.482626],
							[120.684917, 43.50463],
							[120.700431, 43.517527],
							[120.711083, 43.517923],
							[120.738314, 43.540941],
							[120.753643, 43.545884],
							[120.760451, 43.55391],
							[120.774993, 43.555966],
							[120.787728, 43.561382],
							[120.802178, 43.558496],
							[120.820471, 43.569603],
							[120.863726, 43.592879],
							[120.88225, 43.598529],
							[120.899478, 43.601333],
							[120.9105, 43.606903],
							[120.931248, 43.610734],
							[120.943659, 43.617765],
							[120.943845, 43.6259],
							[120.952598, 43.633599],
							[120.962462, 43.633718],
							[120.973391, 43.645206],
							[120.987609, 43.654995],
							[120.984552, 43.660165],
							[120.968066, 43.65831],
							[120.958248, 43.670346],
							[120.940001, 43.698079],
							[120.921754, 43.704389],
							[120.904387, 43.721305],
							[120.87623, 43.739556],
							[120.87007, 43.754964],
							[120.836263, 43.788762],
							[120.786941, 43.814354],
							[120.786571, 43.834662],
							[120.798473, 43.841351],
							[120.795879, 43.885638],
							[120.780921, 43.896253],
							[120.775085, 43.903564],
							[120.774761, 43.935471],
							[120.765823, 43.948865],
							[120.728774, 43.992562],
							[120.692697, 44.037011],
							[120.694318, 44.061986],
							[120.708489, 44.081309],
							[120.735026, 44.08844],
							[120.736184, 44.097961],
							[120.7454, 44.113669],
							[120.705896, 44.129215],
							[120.695013, 44.14327],
							[120.688761, 44.182638],
							[120.653286, 44.185729],
							[120.657269, 44.220885],
							[120.645645, 44.235114],
							[120.61795, 44.234098],
							[120.61193, 44.231792],
							[120.590812, 44.23527],
							[120.574603, 44.23527],
							[120.560987, 44.26153],
							[120.541583, 44.256294],
							[120.497077, 44.249457],
							[120.481933, 44.262155],
							[120.45461, 44.262545],
							[120.429462, 44.286801],
							[120.409178, 44.311749],
							[120.415569, 44.320375],
							[120.400055, 44.332746],
							[120.388199, 44.338325],
							[120.380465, 44.351863],
							[120.377779, 44.367464],
							[120.373796, 44.37421],
							[120.378334, 44.386491],
							[120.33934, 44.390974],
							[120.312109, 44.418372],
							[120.325122, 44.440616],
							[120.316925, 44.446925],
							[120.320121, 44.450819],
							[120.306505, 44.457088],
							[120.318222, 44.464914],
							[120.312526, 44.476048],
							[120.294001, 44.488502],
							[120.301735, 44.493561],
							[120.299605, 44.504495],
							[120.289092, 44.506596],
							[120.28636, 44.517683],
							[120.255331, 44.52445],
							[120.24806, 44.530206],
							[120.245467, 44.540394],
							[120.231944, 44.549414],
							[120.214716, 44.551202],
							[120.210594, 44.555517],
							[120.207121, 44.570714],
							[120.189291, 44.580079],
							[120.181418, 44.588393],
							[120.166089, 44.585596],
							[120.156965, 44.598726],
							[120.159651, 44.609135],
							[120.178222, 44.629559],
							[120.179241, 44.635033],
							[120.156224, 44.636702],
							[120.127974, 44.646872],
							[120.113896, 44.653741],
							[120.102688, 44.662471],
							[120.08333, 44.687609],
							[120.08032, 44.716572],
							[120.073419, 44.721457],
							[120.076661, 44.726456],
							[120.065639, 44.731223],
							[120.059526, 44.740291],
							[120.043502, 44.749667],
							[120.043502, 44.756447],
							[120.024838, 44.773837],
							[119.989873, 44.788667],
							[119.980657, 44.800591],
							[119.968477, 44.805506],
							[119.943376, 44.827486],
							[119.927862, 44.846441],
							[119.918831, 44.849535],
							[119.89248, 44.865738],
							[119.863999, 44.873818],
							[119.858256, 44.886612],
							[119.842742, 44.897742],
							[119.828755, 44.90037],
							[119.819447, 44.906127],
							[119.805924, 44.907557],
							[119.783046, 44.917872],
							[119.756741, 44.922816],
							[119.751091, 44.926949],
							[119.748127, 44.937608],
							[119.737753, 44.944017],
							[119.734928, 44.952473],
							[119.7177, 44.969379],
							[119.716867, 44.984622],
							[119.708762, 44.988017],
							[119.712745, 45.004798],
							[119.703668, 45.012049],
							[119.701445, 45.021381],
							[119.691905, 45.035492],
							[119.679123, 45.048713],
							[119.677085, 45.063626],
							[119.656893, 45.102452],
							[119.638415, 45.115273],
							[119.635358, 45.121162],
							[119.62077, 45.121663],
							[119.594975, 45.136057],
							[119.591131, 45.144907],
							[119.575709, 45.155448],
							[119.551303, 45.168949],
							[119.507585, 45.195058],
							[119.492163, 45.223997],
							[119.337622, 45.252308],
							[119.336325, 45.243474],
							[119.319329, 45.234985],
							[119.322663, 45.223959],
							[119.32081, 45.217119],
							[119.310576, 45.209971],
							[119.307612, 45.191406],
							[119.311594, 45.185869],
							[119.325997, 45.17864],
							[119.333129, 45.179256],
							[119.348412, 45.173449],
							[119.362074, 45.165411],
							[119.364436, 45.15641],
							[119.355822, 45.152794],
							[119.368141, 45.139212],
							[119.367863, 45.130361],
							[119.3743, 45.11431],
							[119.369715, 45.10045],
							[119.35698, 45.089128],
							[119.345819, 45.084083],
							[119.342253, 45.076225],
							[119.323867, 45.081194],
							[119.32169, 45.08466],
							[119.306917, 45.084275],
							[119.293487, 45.087318],
							[119.289782, 45.094481],
							[119.289087, 45.120508],
							[119.278574, 45.128783],
							[119.225733, 45.143907],
							[119.215637, 45.153063],
							[119.205495, 45.15014],
							[119.19878, 45.140675],
							[119.186739, 45.133132],
							[119.159183, 45.100103],
							[119.15483, 45.081502],
							[119.15696, 45.07453],
							[119.17868, 45.058309],
							[119.184377, 45.047865],
							[119.211608, 45.049831],
							[119.222908, 45.044512],
							[119.20443, 45.038499],
							[119.197298, 45.032254],
							[119.212488, 45.026625],
							[119.219944, 45.029092],
							[119.229392, 45.02327],
							[119.223093, 45.011316],
							[119.225733, 45.002908],
							[119.209153, 44.997431],
							[119.171826, 45.01606],
							[119.160758, 45.007382],
							[119.159693, 44.996698],
							[119.153394, 44.99365],
							[119.162286, 44.980609],
							[119.17266, 44.980725],
							[119.174234, 44.966099],
							[119.185257, 44.95394],
							[119.197298, 44.955136],
							[119.210265, 44.945292],
							[119.217397, 44.94618],
							[119.230179, 44.935561],
							[119.226242, 44.923241],
							[119.229345, 44.916674],
							[119.224482, 44.909875],
							[119.214109, 44.910416],
							[119.200817, 44.915747],
							[119.170483, 44.922121],
							[119.145892, 44.925095],
							[119.128201, 44.920189],
							[119.112084, 44.920112],
							[119.094301, 44.927296],
							[119.08138, 44.937453],
							[119.072071, 44.924593],
							[119.079944, 44.912],
							[119.074016, 44.899056],
							[119.075869, 44.893917],
							[119.06744, 44.871614],
							[119.079157, 44.862528],
							[119.077999, 44.857115],
							[119.098654, 44.845513],
							[119.108009, 44.842612],
							[119.120652, 44.833173],
							[119.126997, 44.834837],
							[119.14918, 44.826055],
							[119.147652, 44.809144],
							[119.157886, 44.798345],
							[119.16965, 44.780885],
							[119.182339, 44.776935],
							[119.17956, 44.77163],
							[119.213321, 44.775348],
							[119.221241, 44.77225],
							[119.25176, 44.745793],
							[119.276768, 44.752689],
							[119.288439, 44.764155],
							[119.301174, 44.770391],
							[119.342114, 44.776393],
							[119.362583, 44.784215],
							[119.369113, 44.782898],
							[119.371707, 44.760475],
							[119.377681, 44.753348],
							[119.377681, 44.746142],
							[119.385554, 44.73754],
							[119.384257, 44.732657],
							[119.400837, 44.720798],
							[119.414777, 44.707734],
							[119.417463, 44.689625],
							[119.437238, 44.682954],
							[119.465349, 44.690168],
							[119.47864, 44.690827],
							[119.476232, 44.684506],
							[119.480215, 44.671705],
							[119.492348, 44.663209],
							[119.497304, 44.651606],
							[119.507492, 44.642913],
							[119.51041, 44.635072],
							[119.499295, 44.619737],
							[119.499851, 44.593599],
							[119.515041, 44.574211],
							[119.530741, 44.55921],
							[119.544912, 44.55474],
							[119.553248, 44.539655],
							[119.555656, 44.528845],
							[119.563437, 44.515154],
							[119.573486, 44.503717],
							[119.565891, 44.489436],
							[119.566817, 44.472467],
							[119.602709, 44.43711],
							[119.595855, 44.433332],
							[119.566354, 44.428501],
							[119.566956, 44.420476],
							[119.558481, 44.406682],
							[119.564872, 44.399666],
							[119.562047, 44.392611],
							[119.54732, 44.381735],
							[119.535696, 44.376978],
							[119.532686, 44.357089],
							[119.531574, 44.328961],
							[119.537641, 44.313935],
							[119.549451, 44.299023],
							[119.554035, 44.285864],
							[119.561399, 44.279732],
							[119.565521, 44.247112],
							[119.578627, 44.225537],
							[119.589186, 44.211776],
							[119.591085, 44.196407],
							[119.598587, 44.185025],
							[119.614657, 44.1808],
							[119.632858, 44.16691],
							[119.638693, 44.154426],
							[119.639712, 44.141117],
							[119.630033, 44.128941],
							[119.619335, 44.077429],
							[119.634247, 44.069081],
							[119.636609, 44.048892],
							[119.658607, 44.043873],
							[119.65819, 44.032658],
							[119.67213, 44.002019],
							[119.695054, 43.989422],
							[119.69385, 43.969834],
							[119.6995, 43.960489],
							[119.725203, 43.948315],
							[119.73516, 43.941402],
							[119.74257, 43.940852],
							[119.744191, 43.933546],
							[119.738031, 43.924077],
							[119.735345, 43.909538],
							[119.742431, 43.901756],
							[119.744376, 43.881077],
							[119.763502, 43.859054],
							[119.772116, 43.852485],
							[119.782166, 43.853665],
							[119.786149, 43.849299],
							[119.798931, 43.852249],
							[119.805414, 43.844223],
							[119.812871, 43.845246],
							[119.81375, 43.831671],
							[119.793466, 43.822817],
							[119.808147, 43.811795],
							[119.792586, 43.803804],
							[119.790734, 43.797819],
							[119.796337, 43.785021],
							[119.793281, 43.780413],
							[119.766883, 43.773835],
							[119.748451, 43.767178],
							[119.734002, 43.757762],
							[119.720896, 43.741684],
							[119.71469, 43.739162],
							[119.71469, 43.730687],
							[119.707836, 43.724183],
							[119.694961, 43.72438],
							[119.692646, 43.716298],
							[119.69774, 43.695989],
							[119.716496, 43.68522],
							[119.726083, 43.669991],
							[119.72812, 43.655508],
							[119.718025, 43.639245],
							[119.689728, 43.632336],
							[119.680605, 43.631665],
							[119.673704, 43.626097],
							[119.688617, 43.618397],
							[119.707975, 43.603743],
							[119.730668, 43.595091],
							[119.758269, 43.588967],
							[119.790641, 43.588296],
							[119.824217, 43.583435],
							[119.860062, 43.583752],
							[119.892341, 43.589679],
							[119.924065, 43.600464],
							[119.939949, 43.598608],
							[119.938004, 43.573753],
							[119.940135, 43.559287],
							[119.955695, 43.538252],
							[119.985937, 43.552289],
							[120.022106, 43.53849],
							[120.046559, 43.519861],
							[120.04327, 43.516776],
							[120.057442, 43.510406],
							[120.053135, 43.498971],
							[120.045864, 43.499763],
							[120.03637, 43.480212],
							[120.038176, 43.46105],
							[120.009741, 43.434357],
							[120.057766, 43.402975],
							[120.06587, 43.400597],
							[120.074994, 43.384424],
							[120.079023, 43.370189],
							[120.089397, 43.370863],
							[120.133207, 43.38145],
							[120.157428, 43.382164],
							[120.186697, 43.381212],
							[120.198553, 43.384186],
							[120.219486, 43.383353],
							[120.237362, 43.387278],
							[120.285711, 43.391798],
							[120.297104, 43.390767],
							[120.327299, 43.375424],
							[120.344527, 43.370507],
							[120.373564, 43.373084],
							[120.390607, 43.371419],
							[120.422191, 43.379785]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150422,
				"name": "巴林左旗",
				"center": [119.391737, 43.980715],
				"centroid": [119.270087, 44.201302],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 4,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[119.17956, 44.77163],
							[119.173401, 44.76063],
							[119.150986, 44.759739],
							[119.125932, 44.762218],
							[119.129173, 44.751178],
							[119.118012, 44.745406],
							[119.138714, 44.745096],
							[119.150245, 44.741337],
							[119.148254, 44.731843],
							[119.122227, 44.729906],
							[119.111297, 44.726263],
							[119.09393, 44.72572],
							[119.083464, 44.719635],
							[119.074711, 44.719286],
							[119.074896, 44.713316],
							[119.042061, 44.710486],
							[119.020619, 44.714828],
							[119.001122, 44.71413],
							[118.983801, 44.720177],
							[118.97051, 44.731999],
							[118.953745, 44.730913],
							[118.941982, 44.721767],
							[118.935128, 44.723278],
							[118.928691, 44.717387],
							[118.925958, 44.705718],
							[118.942167, 44.704128],
							[118.953884, 44.694822],
							[118.968241, 44.691138],
							[118.974956, 44.682683],
							[118.974678, 44.669261],
							[118.986071, 44.66864],
							[118.992276, 44.657544],
							[119.001492, 44.649006],
							[118.997695, 44.643728],
							[118.999872, 44.634179],
							[118.988896, 44.618727],
							[118.983662, 44.59018],
							[118.97741, 44.574445],
							[118.975743, 44.550891],
							[118.967176, 44.542299],
							[118.940871, 44.531878],
							[118.92707, 44.531139],
							[118.908684, 44.515815],
							[118.884278, 44.511381],
							[118.871218, 44.501733],
							[118.85876, 44.500098],
							[118.84093, 44.502199],
							[118.834632, 44.492472],
							[118.822776, 44.489903],
							[118.81495, 44.484066],
							[118.803047, 44.468457],
							[118.782485, 44.453545],
							[118.783411, 44.430254],
							[118.79962, 44.401771],
							[118.793044, 44.381696],
							[118.777484, 44.371207],
							[118.769703, 44.362316],
							[118.767897, 44.353345],
							[118.74488, 44.341408],
							[118.738952, 44.328453],
							[118.749419, 44.321117],
							[118.747983, 44.31214],
							[118.760533, 44.297968],
							[118.770768, 44.281919],
							[118.765906, 44.2736],
							[118.756458, 44.271413],
							[118.7666, 44.265554],
							[118.771417, 44.254028],
							[118.778225, 44.247815],
							[118.792118, 44.241602],
							[118.794017, 44.236443],
							[118.808327, 44.222449],
							[118.828982, 44.212088],
							[118.832826, 44.198597],
							[118.829306, 44.192144],
							[118.847645, 44.175088],
							[118.850007, 44.167262],
							[118.848572, 44.150356],
							[118.855194, 44.126435],
							[118.867652, 44.118212],
							[118.859825, 44.098431],
							[118.85751, 44.083347],
							[118.864318, 44.07594],
							[118.880249, 44.080251],
							[118.909425, 44.078918],
							[118.91878, 44.073941],
							[118.925819, 44.053715],
							[118.92003, 44.050147],
							[118.913269, 44.035795],
							[118.922114, 44.023323],
							[118.932627, 44.013555],
							[118.936517, 44.001941],
							[118.93133, 43.995623],
							[118.925542, 43.974545],
							[118.921976, 43.944466],
							[118.958423, 43.941167],
							[118.978707, 43.931739],
							[119.003206, 43.903918],
							[119.01219, 43.909499],
							[119.025297, 43.912407],
							[119.080037, 43.886778],
							[119.074016, 43.876477],
							[119.08226, 43.841902],
							[119.079342, 43.827382],
							[119.089392, 43.822187],
							[119.096709, 43.822777],
							[119.113427, 43.809748],
							[119.109306, 43.803607],
							[119.110695, 43.793921],
							[119.100877, 43.783052],
							[119.10134, 43.773953],
							[119.143298, 43.743852],
							[119.128664, 43.735378],
							[119.149921, 43.722764],
							[119.179884, 43.71338],
							[119.183636, 43.705691],
							[119.197668, 43.692044],
							[119.206838, 43.692754],
							[119.213553, 43.706834],
							[119.225131, 43.696975],
							[119.23745, 43.699262],
							[119.244952, 43.690269],
							[119.258799, 43.686759],
							[119.271767, 43.689836],
							[119.292468, 43.682932],
							[119.303768, 43.681433],
							[119.330165, 43.68806],
							[119.340539, 43.695673],
							[119.354108, 43.699775],
							[119.404866, 43.704271],
							[119.408108, 43.691413],
							[119.42126, 43.696699],
							[119.421445, 43.707031],
							[119.429828, 43.711645],
							[119.431263, 43.726351],
							[119.42302, 43.736403],
							[119.423761, 43.752285],
							[119.431588, 43.758432],
							[119.427744, 43.769384],
							[119.439229, 43.782658],
							[119.439044, 43.78829],
							[119.449649, 43.791479],
							[119.464237, 43.778641],
							[119.469146, 43.766075],
							[119.497396, 43.759417],
							[119.504019, 43.753664],
							[119.509715, 43.726824],
							[119.517635, 43.711566],
							[119.528332, 43.697842],
							[119.53741, 43.671925],
							[119.547042, 43.663598],
							[119.566678, 43.656534],
							[119.577747, 43.647061],
							[119.590992, 43.643153],
							[119.60257, 43.633797],
							[119.608035, 43.634073],
							[119.624522, 43.624044],
							[119.63082, 43.615553],
							[119.646381, 43.610419],
							[119.659626, 43.609668],
							[119.673704, 43.626097],
							[119.680605, 43.631665],
							[119.689728, 43.632336],
							[119.718025, 43.639245],
							[119.72812, 43.655508],
							[119.726083, 43.669991],
							[119.716496, 43.68522],
							[119.69774, 43.695989],
							[119.692646, 43.716298],
							[119.694961, 43.72438],
							[119.707836, 43.724183],
							[119.71469, 43.730687],
							[119.71469, 43.739162],
							[119.720896, 43.741684],
							[119.734002, 43.757762],
							[119.748451, 43.767178],
							[119.766883, 43.773835],
							[119.793281, 43.780413],
							[119.796337, 43.785021],
							[119.790734, 43.797819],
							[119.792586, 43.803804],
							[119.808147, 43.811795],
							[119.793466, 43.822817],
							[119.81375, 43.831671],
							[119.812871, 43.845246],
							[119.805414, 43.844223],
							[119.798931, 43.852249],
							[119.786149, 43.849299],
							[119.782166, 43.853665],
							[119.772116, 43.852485],
							[119.763502, 43.859054],
							[119.744376, 43.881077],
							[119.742431, 43.901756],
							[119.735345, 43.909538],
							[119.738031, 43.924077],
							[119.744191, 43.933546],
							[119.74257, 43.940852],
							[119.73516, 43.941402],
							[119.725203, 43.948315],
							[119.6995, 43.960489],
							[119.69385, 43.969834],
							[119.695054, 43.989422],
							[119.67213, 44.002019],
							[119.65819, 44.032658],
							[119.658607, 44.043873],
							[119.636609, 44.048892],
							[119.634247, 44.069081],
							[119.619335, 44.077429],
							[119.630033, 44.128941],
							[119.639712, 44.141117],
							[119.638693, 44.154426],
							[119.632858, 44.16691],
							[119.614657, 44.1808],
							[119.598587, 44.185025],
							[119.591085, 44.196407],
							[119.589186, 44.211776],
							[119.578627, 44.225537],
							[119.565521, 44.247112],
							[119.561399, 44.279732],
							[119.554035, 44.285864],
							[119.549451, 44.299023],
							[119.537641, 44.313935],
							[119.531574, 44.328961],
							[119.532686, 44.357089],
							[119.535696, 44.376978],
							[119.54732, 44.381735],
							[119.562047, 44.392611],
							[119.564872, 44.399666],
							[119.558481, 44.406682],
							[119.566956, 44.420476],
							[119.566354, 44.428501],
							[119.595855, 44.433332],
							[119.602709, 44.43711],
							[119.566817, 44.472467],
							[119.565891, 44.489436],
							[119.573486, 44.503717],
							[119.563437, 44.515154],
							[119.555656, 44.528845],
							[119.553248, 44.539655],
							[119.544912, 44.55474],
							[119.530741, 44.55921],
							[119.515041, 44.574211],
							[119.499851, 44.593599],
							[119.499295, 44.619737],
							[119.51041, 44.635072],
							[119.507492, 44.642913],
							[119.497304, 44.651606],
							[119.492348, 44.663209],
							[119.480215, 44.671705],
							[119.476232, 44.684506],
							[119.47864, 44.690827],
							[119.465349, 44.690168],
							[119.437238, 44.682954],
							[119.417463, 44.689625],
							[119.414777, 44.707734],
							[119.400837, 44.720798],
							[119.384257, 44.732657],
							[119.385554, 44.73754],
							[119.377681, 44.746142],
							[119.377681, 44.753348],
							[119.371707, 44.760475],
							[119.369113, 44.782898],
							[119.362583, 44.784215],
							[119.342114, 44.776393],
							[119.301174, 44.770391],
							[119.288439, 44.764155],
							[119.276768, 44.752689],
							[119.25176, 44.745793],
							[119.221241, 44.77225],
							[119.213321, 44.775348],
							[119.17956, 44.77163]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150423,
				"name": "巴林右旗",
				"center": [118.678347, 43.528963],
				"centroid": [118.93345, 43.685471],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 5,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[120.079023, 43.370189],
							[120.074994, 43.384424],
							[120.06587, 43.400597],
							[120.057766, 43.402975],
							[120.009741, 43.434357],
							[120.038176, 43.46105],
							[120.03637, 43.480212],
							[120.045864, 43.499763],
							[120.053135, 43.498971],
							[120.057442, 43.510406],
							[120.04327, 43.516776],
							[120.046559, 43.519861],
							[120.022106, 43.53849],
							[119.985937, 43.552289],
							[119.955695, 43.538252],
							[119.940135, 43.559287],
							[119.938004, 43.573753],
							[119.939949, 43.598608],
							[119.924065, 43.600464],
							[119.892341, 43.589679],
							[119.860062, 43.583752],
							[119.824217, 43.583435],
							[119.790641, 43.588296],
							[119.758269, 43.588967],
							[119.730668, 43.595091],
							[119.707975, 43.603743],
							[119.688617, 43.618397],
							[119.673704, 43.626097],
							[119.659626, 43.609668],
							[119.646381, 43.610419],
							[119.63082, 43.615553],
							[119.624522, 43.624044],
							[119.608035, 43.634073],
							[119.60257, 43.633797],
							[119.590992, 43.643153],
							[119.577747, 43.647061],
							[119.566678, 43.656534],
							[119.547042, 43.663598],
							[119.53741, 43.671925],
							[119.528332, 43.697842],
							[119.517635, 43.711566],
							[119.509715, 43.726824],
							[119.504019, 43.753664],
							[119.497396, 43.759417],
							[119.469146, 43.766075],
							[119.464237, 43.778641],
							[119.449649, 43.791479],
							[119.439044, 43.78829],
							[119.439229, 43.782658],
							[119.427744, 43.769384],
							[119.431588, 43.758432],
							[119.423761, 43.752285],
							[119.42302, 43.736403],
							[119.431263, 43.726351],
							[119.429828, 43.711645],
							[119.421445, 43.707031],
							[119.42126, 43.696699],
							[119.408108, 43.691413],
							[119.404866, 43.704271],
							[119.354108, 43.699775],
							[119.340539, 43.695673],
							[119.330165, 43.68806],
							[119.303768, 43.681433],
							[119.292468, 43.682932],
							[119.271767, 43.689836],
							[119.258799, 43.686759],
							[119.244952, 43.690269],
							[119.23745, 43.699262],
							[119.225131, 43.696975],
							[119.213553, 43.706834],
							[119.206838, 43.692754],
							[119.197668, 43.692044],
							[119.183636, 43.705691],
							[119.179884, 43.71338],
							[119.149921, 43.722764],
							[119.128664, 43.735378],
							[119.143298, 43.743852],
							[119.10134, 43.773953],
							[119.100877, 43.783052],
							[119.110695, 43.793921],
							[119.109306, 43.803607],
							[119.113427, 43.809748],
							[119.096709, 43.822777],
							[119.089392, 43.822187],
							[119.079342, 43.827382],
							[119.08226, 43.841902],
							[119.074016, 43.876477],
							[119.080037, 43.886778],
							[119.025297, 43.912407],
							[119.01219, 43.909499],
							[119.003206, 43.903918],
							[118.978707, 43.931739],
							[118.958423, 43.941167],
							[118.921976, 43.944466],
							[118.925542, 43.974545],
							[118.93133, 43.995623],
							[118.936517, 44.001941],
							[118.932627, 44.013555],
							[118.922114, 44.023323],
							[118.913269, 44.035795],
							[118.92003, 44.050147],
							[118.925819, 44.053715],
							[118.91878, 44.073941],
							[118.909425, 44.078918],
							[118.880249, 44.080251],
							[118.864318, 44.07594],
							[118.85751, 44.083347],
							[118.859825, 44.098431],
							[118.867652, 44.118212],
							[118.855194, 44.126435],
							[118.848572, 44.150356],
							[118.850007, 44.167262],
							[118.847645, 44.175088],
							[118.829306, 44.192144],
							[118.832826, 44.198597],
							[118.828982, 44.212088],
							[118.808327, 44.222449],
							[118.794017, 44.236443],
							[118.792118, 44.241602],
							[118.778225, 44.247815],
							[118.771417, 44.254028],
							[118.7666, 44.265554],
							[118.756458, 44.271413],
							[118.765906, 44.2736],
							[118.770768, 44.281919],
							[118.760533, 44.297968],
							[118.747983, 44.31214],
							[118.749419, 44.321117],
							[118.738952, 44.328453],
							[118.74488, 44.341408],
							[118.767897, 44.353345],
							[118.769703, 44.362316],
							[118.777484, 44.371207],
							[118.793044, 44.381696],
							[118.79962, 44.401771],
							[118.783411, 44.430254],
							[118.782485, 44.453545],
							[118.769564, 44.461839],
							[118.75104, 44.468223],
							[118.741175, 44.467523],
							[118.729968, 44.462033],
							[118.715704, 44.45923],
							[118.70357, 44.453467],
							[118.65499, 44.442563],
							[118.646978, 44.443108],
							[118.6423, 44.461683],
							[118.622896, 44.466472],
							[118.610021, 44.463902],
							[118.591635, 44.440304],
							[118.566025, 44.435357],
							[118.556994, 44.431345],
							[118.558847, 44.415294],
							[118.552224, 44.402784],
							[118.50883, 44.396081],
							[118.505588, 44.391286],
							[118.482942, 44.388869],
							[118.479608, 44.379279],
							[118.481692, 44.358416],
							[118.478589, 44.346791],
							[118.465159, 44.342617],
							[118.457286, 44.345231],
							[118.446078, 44.342617],
							[118.428109, 44.317877],
							[118.403379, 44.307611],
							[118.394209, 44.309993],
							[118.385734, 44.317838],
							[118.371424, 44.319556],
							[118.355632, 44.312842],
							[118.335347, 44.310929],
							[118.32326, 44.318892],
							[118.305708, 44.322561],
							[118.306264, 44.317331],
							[118.282506, 44.319751],
							[118.255784, 44.327439],
							[118.246244, 44.326112],
							[118.242493, 44.320141],
							[118.226978, 44.308392],
							[118.250875, 44.281138],
							[118.245318, 44.262819],
							[118.248282, 44.248206],
							[118.261434, 44.24211],
							[118.285238, 44.225616],
							[118.295566, 44.227648],
							[118.31071, 44.211306],
							[118.307746, 44.204893],
							[118.317193, 44.182169],
							[118.317286, 44.168906],
							[118.349472, 44.136615],
							[118.351788, 44.121932],
							[118.359522, 44.112729],
							[118.362115, 44.096198],
							[118.356558, 44.090321],
							[118.369016, 44.075313],
							[118.383094, 44.062849],
							[118.393607, 44.047637],
							[118.429638, 44.031716],
							[118.441817, 43.975762],
							[118.435334, 43.978706],
							[118.40412, 43.969834],
							[118.368645, 43.963081],
							[118.357623, 43.9727],
							[118.332569, 43.983063],
							[118.327335, 43.97376],
							[118.315341, 43.964965],
							[118.305198, 43.961314],
							[118.284405, 43.941088],
							[118.262546, 43.93551],
							[118.260647, 43.940577],
							[118.266991, 43.952714],
							[118.269678, 43.967243],
							[118.258007, 43.974427],
							[118.239668, 43.979845],
							[118.232443, 43.979413],
							[118.237815, 43.96524],
							[118.221004, 43.956248],
							[118.229711, 43.937631],
							[118.246151, 43.924785],
							[118.245364, 43.905529],
							[118.249393, 43.899515],
							[118.266806, 43.888823],
							[118.262453, 43.87687],
							[118.272595, 43.865544],
							[118.27241, 43.855789],
							[118.278523, 43.849731],
							[118.277319, 43.843004],
							[118.288434, 43.826398],
							[118.28408, 43.815928],
							[118.286905, 43.802426],
							[118.27843, 43.793488],
							[118.28973, 43.788132],
							[118.287461, 43.778404],
							[118.298807, 43.775332],
							[118.302837, 43.768517],
							[118.301586, 43.75654],
							[118.311775, 43.748975],
							[118.325853, 43.7271],
							[118.338172, 43.720989],
							[118.348129, 43.693859],
							[118.366422, 43.663875],
							[118.375777, 43.655705],
							[118.36633, 43.645877],
							[118.396432, 43.620095],
							[118.388003, 43.614961],
							[118.416624, 43.594341],
							[118.387263, 43.593037],
							[118.353224, 43.597857],
							[118.335486, 43.588967],
							[118.326178, 43.589046],
							[118.297418, 43.599793],
							[118.280607, 43.584937],
							[118.256757, 43.580669],
							[118.248282, 43.571856],
							[118.218272, 43.56154],
							[118.206786, 43.552645],
							[118.205443, 43.536908],
							[118.200164, 43.527218],
							[118.198728, 43.506212],
							[118.203684, 43.496399],
							[118.216697, 43.488365],
							[118.219476, 43.472493],
							[118.211649, 43.438001],
							[118.217623, 43.425721],
							[118.223875, 43.425523],
							[118.241335, 43.435308],
							[118.258053, 43.424572],
							[118.278338, 43.422432],
							[118.295566, 43.426513],
							[118.305754, 43.434912],
							[118.319509, 43.438239],
							[118.340395, 43.428771],
							[118.355354, 43.414428],
							[118.376287, 43.411575],
							[118.398609, 43.411179],
							[118.415281, 43.405908],
							[118.425979, 43.390053],
							[118.441308, 43.383869],
							[118.467845, 43.382124],
							[118.500541, 43.346788],
							[118.523372, 43.325243],
							[118.547964, 43.307343],
							[118.54185, 43.301507],
							[118.568711, 43.293527],
							[118.568989, 43.287412],
							[118.580382, 43.278398],
							[118.580428, 43.271924],
							[118.594738, 43.272321],
							[118.609975, 43.265608],
							[118.618542, 43.257186],
							[118.642346, 43.249597],
							[118.685694, 43.249279],
							[118.729042, 43.254921],
							[118.762942, 43.254723],
							[118.829028, 43.239226],
							[118.85876, 43.229449],
							[118.898681, 43.227978],
							[118.964304, 43.23855],
							[119.007559, 43.229369],
							[119.028075, 43.226189],
							[119.039792, 43.231357],
							[119.056927, 43.225315],
							[119.076424, 43.223208],
							[119.107685, 43.216371],
							[119.151727, 43.220545],
							[119.186044, 43.227501],
							[119.224019, 43.230999],
							[119.233698, 43.234695],
							[119.260189, 43.250471],
							[119.269127, 43.261635],
							[119.306963, 43.271248],
							[119.329378, 43.271368],
							[119.341604, 43.275101],
							[119.368697, 43.269262],
							[119.393473, 43.274545],
							[119.421538, 43.274664],
							[119.451455, 43.270811],
							[119.475259, 43.270335],
							[119.495405, 43.264575],
							[119.51078, 43.264615],
							[119.544866, 43.271368],
							[119.556536, 43.270732],
							[119.574459, 43.260523],
							[119.586824, 43.257861],
							[119.608359, 43.26243],
							[119.642768, 43.266283],
							[119.663284, 43.276729],
							[119.718627, 43.287293],
							[119.753684, 43.301388],
							[119.781657, 43.30647],
							[119.806711, 43.313377],
							[119.832646, 43.314131],
							[119.858812, 43.323854],
							[119.878077, 43.327505],
							[119.902206, 43.326831],
							[119.914663, 43.328934],
							[119.930965, 43.339211],
							[119.949675, 43.346193],
							[119.991772, 43.355753],
							[120.019281, 43.36079],
							[120.046929, 43.369713],
							[120.079023, 43.370189]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150424,
				"name": "林西县",
				"center": [118.05775, 43.605326],
				"centroid": [118.106167, 43.75835],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 6,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[117.64387, 44.038775],
							[117.639933, 44.031795],
							[117.639887, 44.016026],
							[117.655216, 44.006885],
							[117.669017, 44.001941],
							[117.683975, 43.980512],
							[117.695553, 43.948904],
							[117.701991, 43.915944],
							[117.710373, 43.904586],
							[117.725934, 43.898297],
							[117.734316, 43.887643],
							[117.751081, 43.858936],
							[117.756499, 43.853351],
							[117.775302, 43.849495],
							[117.780859, 43.839541],
							[117.776135, 43.833482],
							[117.782156, 43.812819],
							[117.794336, 43.796992],
							[117.811888, 43.787423],
							[117.818418, 43.779034],
							[117.824577, 43.742118],
							[117.846992, 43.716652],
							[117.856532, 43.692202],
							[117.865285, 43.683761],
							[117.891636, 43.668965],
							[117.895712, 43.66202],
							[117.897657, 43.643232],
							[117.901871, 43.632415],
							[117.926416, 43.593314],
							[117.941607, 43.578219],
							[117.942301, 43.570394],
							[117.933039, 43.565809],
							[117.940078, 43.552526],
							[117.953787, 43.546359],
							[117.94633, 43.537896],
							[117.945265, 43.525676],
							[117.926787, 43.523105],
							[117.918682, 43.518595],
							[117.918404, 43.511672],
							[117.904418, 43.482547],
							[117.904835, 43.470671],
							[117.913495, 43.461763],
							[117.927852, 43.453408],
							[117.931325, 43.433684],
							[117.946747, 43.426949],
							[117.950406, 43.418193],
							[117.9511, 43.400359],
							[117.966198, 43.393145],
							[117.985788, 43.394295],
							[118.007369, 43.404323],
							[118.016446, 43.402975],
							[118.029043, 43.389895],
							[118.045159, 43.359838],
							[118.06725, 43.354286],
							[118.06674, 43.344924],
							[118.059284, 43.333934],
							[118.059099, 43.323854],
							[118.065721, 43.31159],
							[118.084107, 43.296545],
							[118.091054, 43.285149],
							[118.092258, 43.271646],
							[118.084478, 43.254921],
							[118.086562, 43.2521],
							[118.116201, 43.245862],
							[118.152139, 43.249279],
							[118.185113, 43.26243],
							[118.214937, 43.26811],
							[118.237815, 43.286658],
							[118.249208, 43.291065],
							[118.287183, 43.289199],
							[118.314044, 43.294996],
							[118.328447, 43.293368],
							[118.347759, 43.281932],
							[118.379667, 43.282409],
							[118.410279, 43.272321],
							[118.4502, 43.267356],
							[118.458629, 43.268508],
							[118.527123, 43.277881],
							[118.580428, 43.271924],
							[118.580382, 43.278398],
							[118.568989, 43.287412],
							[118.568711, 43.293527],
							[118.54185, 43.301507],
							[118.547964, 43.307343],
							[118.523372, 43.325243],
							[118.500541, 43.346788],
							[118.467845, 43.382124],
							[118.441308, 43.383869],
							[118.425979, 43.390053],
							[118.415281, 43.405908],
							[118.398609, 43.411179],
							[118.376287, 43.411575],
							[118.355354, 43.414428],
							[118.340395, 43.428771],
							[118.319509, 43.438239],
							[118.305754, 43.434912],
							[118.295566, 43.426513],
							[118.278338, 43.422432],
							[118.258053, 43.424572],
							[118.241335, 43.435308],
							[118.223875, 43.425523],
							[118.217623, 43.425721],
							[118.211649, 43.438001],
							[118.219476, 43.472493],
							[118.216697, 43.488365],
							[118.203684, 43.496399],
							[118.198728, 43.506212],
							[118.200164, 43.527218],
							[118.205443, 43.536908],
							[118.206786, 43.552645],
							[118.218272, 43.56154],
							[118.248282, 43.571856],
							[118.256757, 43.580669],
							[118.280607, 43.584937],
							[118.297418, 43.599793],
							[118.326178, 43.589046],
							[118.335486, 43.588967],
							[118.353224, 43.597857],
							[118.387263, 43.593037],
							[118.416624, 43.594341],
							[118.388003, 43.614961],
							[118.396432, 43.620095],
							[118.36633, 43.645877],
							[118.375777, 43.655705],
							[118.366422, 43.663875],
							[118.348129, 43.693859],
							[118.338172, 43.720989],
							[118.325853, 43.7271],
							[118.311775, 43.748975],
							[118.301586, 43.75654],
							[118.302837, 43.768517],
							[118.298807, 43.775332],
							[118.287461, 43.778404],
							[118.28973, 43.788132],
							[118.27843, 43.793488],
							[118.286905, 43.802426],
							[118.28408, 43.815928],
							[118.288434, 43.826398],
							[118.277319, 43.843004],
							[118.278523, 43.849731],
							[118.27241, 43.855789],
							[118.272595, 43.865544],
							[118.262453, 43.87687],
							[118.266806, 43.888823],
							[118.249393, 43.899515],
							[118.245364, 43.905529],
							[118.246151, 43.924785],
							[118.229711, 43.937631],
							[118.221004, 43.956248],
							[118.237815, 43.96524],
							[118.232443, 43.979413],
							[118.239668, 43.979845],
							[118.258007, 43.974427],
							[118.269678, 43.967243],
							[118.266991, 43.952714],
							[118.260647, 43.940577],
							[118.262546, 43.93551],
							[118.284405, 43.941088],
							[118.305198, 43.961314],
							[118.315341, 43.964965],
							[118.327335, 43.97376],
							[118.332569, 43.983063],
							[118.357623, 43.9727],
							[118.368645, 43.963081],
							[118.40412, 43.969834],
							[118.435334, 43.978706],
							[118.441817, 43.975762],
							[118.429638, 44.031716],
							[118.393607, 44.047637],
							[118.383094, 44.062849],
							[118.369016, 44.075313],
							[118.356558, 44.090321],
							[118.362115, 44.096198],
							[118.359522, 44.112729],
							[118.351788, 44.121932],
							[118.349472, 44.136615],
							[118.317286, 44.168906],
							[118.317193, 44.182169],
							[118.307746, 44.204893],
							[118.31071, 44.211306],
							[118.295566, 44.227648],
							[118.285238, 44.225616],
							[118.261434, 44.24211],
							[118.245225, 44.235427],
							[118.238371, 44.244181],
							[118.2242, 44.248245],
							[118.227117, 44.256177],
							[118.212668, 44.257623],
							[118.200766, 44.244806],
							[118.193356, 44.243634],
							[118.194653, 44.237928],
							[118.187197, 44.230971],
							[118.180713, 44.211306],
							[118.173025, 44.204307],
							[118.164782, 44.20415],
							[118.154732, 44.212479],
							[118.142923, 44.207553],
							[118.129631, 44.186394],
							[118.135652, 44.166871],
							[118.131438, 44.157753],
							[118.121156, 44.151217],
							[118.113885, 44.130899],
							[118.080356, 44.117859],
							[118.074197, 44.108969],
							[118.063962, 44.102819],
							[118.056042, 44.106305],
							[118.033164, 44.11077],
							[118.003479, 44.106736],
							[117.989261, 44.115274],
							[117.954898, 44.118838],
							[117.924471, 44.126983],
							[117.90377, 44.119974],
							[117.887422, 44.106579],
							[117.878206, 44.102505],
							[117.874316, 44.094749],
							[117.873529, 44.079428],
							[117.86811, 44.074216],
							[117.832913, 44.06622],
							[117.828421, 44.057988],
							[117.809341, 44.041717],
							[117.799338, 44.039599],
							[117.794058, 44.03101],
							[117.796327, 44.021989],
							[117.789797, 44.019753],
							[117.762427, 44.023597],
							[117.752239, 44.022028],
							[117.744736, 44.015398],
							[117.72473, 44.013829],
							[117.717783, 44.017282],
							[117.701528, 44.016536],
							[117.681799, 44.025715],
							[117.664154, 44.025676],
							[117.653873, 44.029363],
							[117.64387, 44.038775]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150425,
				"name": "克什克腾旗",
				"center": [117.542465, 43.256233],
				"centroid": [117.352996, 43.247976],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 7,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[117.801514, 42.612706],
							[117.823327, 42.629485],
							[117.832636, 42.643652],
							[117.835136, 42.657534],
							[117.842176, 42.66307],
							[117.862877, 42.661586],
							[117.878716, 42.669408],
							[117.895897, 42.685209],
							[117.921739, 42.699884],
							[117.940634, 42.718363],
							[117.948739, 42.731227],
							[117.96643, 42.743167],
							[117.989215, 42.762755],
							[118.019132, 42.780574],
							[118.046271, 42.787501],
							[118.078874, 42.791504],
							[118.096241, 42.796067],
							[118.110412, 42.805754],
							[118.133475, 42.812677],
							[118.148943, 42.824281],
							[118.16418, 42.823401],
							[118.181639, 42.832963],
							[118.203035, 42.836483],
							[118.216466, 42.846843],
							[118.222718, 42.855801],
							[118.263935, 42.878671],
							[118.301308, 42.909125],
							[118.315619, 42.909005],
							[118.337755, 42.889304],
							[118.351001, 42.887665],
							[118.366608, 42.900334],
							[118.373971, 42.910004],
							[118.374249, 42.925025],
							[118.370451, 42.937087],
							[118.371702, 42.949826],
							[118.368831, 42.95813],
							[118.354983, 42.981521],
							[118.326594, 42.99828],
							[118.318629, 43.008852],
							[118.323955, 43.019343],
							[118.322056, 43.026123],
							[118.333726, 43.047056],
							[118.342109, 43.053713],
							[118.342248, 43.061246],
							[118.357855, 43.072365],
							[118.35716, 43.087387],
							[118.370312, 43.099098],
							[118.375592, 43.138718],
							[118.348315, 43.166895],
							[118.34373, 43.196967],
							[118.335301, 43.209294],
							[118.335857, 43.214105],
							[118.356095, 43.234099],
							[118.360402, 43.243836],
							[118.367858, 43.248207],
							[118.389949, 43.250551],
							[118.404954, 43.249677],
							[118.422228, 43.244591],
							[118.447097, 43.253213],
							[118.458629, 43.268508],
							[118.4502, 43.267356],
							[118.410279, 43.272321],
							[118.379667, 43.282409],
							[118.347759, 43.281932],
							[118.328447, 43.293368],
							[118.314044, 43.294996],
							[118.287183, 43.289199],
							[118.249208, 43.291065],
							[118.237815, 43.286658],
							[118.214937, 43.26811],
							[118.185113, 43.26243],
							[118.152139, 43.249279],
							[118.116201, 43.245862],
							[118.086562, 43.2521],
							[118.084478, 43.254921],
							[118.092258, 43.271646],
							[118.091054, 43.285149],
							[118.084107, 43.296545],
							[118.065721, 43.31159],
							[118.059099, 43.323854],
							[118.059284, 43.333934],
							[118.06674, 43.344924],
							[118.06725, 43.354286],
							[118.045159, 43.359838],
							[118.029043, 43.389895],
							[118.016446, 43.402975],
							[118.007369, 43.404323],
							[117.985788, 43.394295],
							[117.966198, 43.393145],
							[117.9511, 43.400359],
							[117.950406, 43.418193],
							[117.946747, 43.426949],
							[117.931325, 43.433684],
							[117.927852, 43.453408],
							[117.913495, 43.461763],
							[117.904835, 43.470671],
							[117.904418, 43.482547],
							[117.918404, 43.511672],
							[117.918682, 43.518595],
							[117.926787, 43.523105],
							[117.945265, 43.525676],
							[117.94633, 43.537896],
							[117.953787, 43.546359],
							[117.940078, 43.552526],
							[117.933039, 43.565809],
							[117.942301, 43.570394],
							[117.941607, 43.578219],
							[117.926416, 43.593314],
							[117.901871, 43.632415],
							[117.897657, 43.643232],
							[117.895712, 43.66202],
							[117.891636, 43.668965],
							[117.865285, 43.683761],
							[117.856532, 43.692202],
							[117.846992, 43.716652],
							[117.824577, 43.742118],
							[117.818418, 43.779034],
							[117.811888, 43.787423],
							[117.794336, 43.796992],
							[117.782156, 43.812819],
							[117.776135, 43.833482],
							[117.780859, 43.839541],
							[117.775302, 43.849495],
							[117.756499, 43.853351],
							[117.751081, 43.858936],
							[117.734316, 43.887643],
							[117.725934, 43.898297],
							[117.710373, 43.904586],
							[117.701991, 43.915944],
							[117.695553, 43.948904],
							[117.683975, 43.980512],
							[117.669017, 44.001941],
							[117.655216, 44.006885],
							[117.639887, 44.016026],
							[117.639933, 44.031795],
							[117.64387, 44.038775],
							[117.653456, 44.047598],
							[117.653364, 44.052538],
							[117.662209, 44.059909],
							[117.671379, 44.083973],
							[117.684855, 44.091536],
							[117.686615, 44.095767],
							[117.677631, 44.103876],
							[117.670869, 44.104386],
							[117.664478, 44.111984],
							[117.650307, 44.12064],
							[117.633496, 44.114569],
							[117.624882, 44.12898],
							[117.634839, 44.148594],
							[117.624095, 44.156657],
							[117.608627, 44.163349],
							[117.608488, 44.168593],
							[117.552868, 44.186668],
							[117.555137, 44.195742],
							[117.544439, 44.209781],
							[117.522858, 44.226906],
							[117.505815, 44.225694],
							[117.492894, 44.229642],
							[117.468951, 44.229915],
							[117.452464, 44.235192],
							[117.392722, 44.231909],
							[117.206504, 44.220299],
							[117.166027, 44.19277],
							[117.120781, 44.17947],
							[117.084936, 44.139826],
							[117.025564, 44.072805],
							[117.011254, 44.057713],
							[116.981383, 44.035991],
							[116.961654, 44.024852],
							[116.971148, 43.988362],
							[117.021906, 43.969991],
							[117.032835, 43.954834],
							[117.031863, 43.942856],
							[117.016348, 43.934567],
							[116.999815, 43.912446],
							[117.010559, 43.900026],
							[116.997639, 43.876359],
							[117.013477, 43.850636],
							[116.986292, 43.840407],
							[117.001112, 43.782658],
							[117.054324, 43.753388],
							[116.97226, 43.673661],
							[116.932849, 43.67299],
							[116.920483, 43.664585],
							[116.909924, 43.667939],
							[116.858658, 43.657442],
							[116.862501, 43.648561],
							[116.848006, 43.64268],
							[116.842032, 43.632533],
							[116.844533, 43.625307],
							[116.838003, 43.614684],
							[116.825591, 43.611129],
							[116.813689, 43.612828],
							[116.805538, 43.606627],
							[116.804334, 43.593274],
							[116.811096, 43.574109],
							[116.804658, 43.565256],
							[116.830917, 43.507004],
							[116.790255, 43.484051],
							[116.734821, 43.508982],
							[116.681192, 43.516973],
							[116.622052, 43.505263],
							[116.622608, 43.498615],
							[116.612002, 43.483339],
							[116.602601, 43.444339],
							[116.605843, 43.419857],
							[116.595515, 43.40999],
							[116.536051, 43.375225],
							[116.491685, 43.353135],
							[116.436621, 43.328656],
							[116.419439, 43.292693],
							[116.414206, 43.256908],
							[116.399201, 43.245783],
							[116.370163, 43.243557],
							[116.358863, 43.187501],
							[116.356502, 43.156987],
							[116.370951, 43.151455],
							[116.393643, 43.125063],
							[116.398506, 43.107064],
							[116.4193, 43.104316],
							[116.436204, 43.078143],
							[116.451996, 43.074358],
							[116.503911, 43.049248],
							[116.499558, 43.039082],
							[116.505162, 43.025524],
							[116.500855, 43.015314],
							[116.564255, 42.990061],
							[116.578519, 42.982878],
							[116.600008, 42.975974],
							[116.639928, 42.952421],
							[116.661046, 42.927062],
							[116.668086, 42.916037],
							[116.674245, 42.889623],
							[116.673597, 42.862719],
							[116.671235, 42.835843],
							[116.666094, 42.816799],
							[116.67406, 42.761473],
							[116.661741, 42.750819],
							[116.644513, 42.710307],
							[116.619458, 42.671855],
							[116.617236, 42.656732],
							[116.608297, 42.636107],
							[116.580927, 42.61379],
							[116.588106, 42.599898],
							[116.635621, 42.614713],
							[116.634927, 42.602749],
							[116.638261, 42.577407],
							[116.669568, 42.555912],
							[116.676144, 42.569895],
							[116.673875, 42.575559],
							[116.699207, 42.592228],
							[116.698373, 42.59536],
							[116.718426, 42.592469],
							[116.743481, 42.585962],
							[116.782336, 42.581343],
							[116.801555, 42.583071],
							[116.829342, 42.558444],
							[116.820867, 42.546991],
							[116.841893, 42.546549],
							[116.849627, 42.534089],
							[116.858611, 42.541404],
							[116.885796, 42.534732],
							[116.877506, 42.51845],
							[116.878757, 42.501762],
							[116.883712, 42.488368],
							[116.876951, 42.480804],
							[116.891122, 42.460082],
							[116.906729, 42.447202],
							[116.909554, 42.438064],
							[116.9027, 42.412252],
							[116.895568, 42.409715],
							[116.891863, 42.388444],
							[116.912425, 42.391789],
							[116.91437, 42.402706],
							[116.950077, 42.41491],
							[116.965081, 42.421555],
							[116.967212, 42.427515],
							[116.982124, 42.428401],
							[116.989858, 42.425501],
							[117.006623, 42.433031],
							[117.007595, 42.448933],
							[117.016765, 42.4565],
							[117.036216, 42.458592],
							[117.046312, 42.454125],
							[117.066457, 42.46145],
							[117.079517, 42.460605],
							[117.080212, 42.46652],
							[117.094939, 42.483782],
							[117.110222, 42.475976],
							[117.126894, 42.474327],
							[117.13574, 42.469176],
							[117.150189, 42.470826],
							[117.153616, 42.467526],
							[117.175892, 42.465635],
							[117.208217, 42.470947],
							[117.215581, 42.475574],
							[117.258743, 42.474488],
							[117.275554, 42.481971],
							[117.320986, 42.468975],
							[117.330063, 42.461893],
							[117.362712, 42.460283],
							[117.390731, 42.462054],
							[117.411988, 42.471389],
							[117.413053, 42.487523],
							[117.417175, 42.494723],
							[117.416295, 42.512338],
							[117.408422, 42.519978],
							[117.387257, 42.517606],
							[117.39263, 42.520661],
							[117.396149, 42.535415],
							[117.405365, 42.542691],
							[117.433152, 42.555752],
							[117.44297, 42.570096],
							[117.44436, 42.577447],
							[117.43519, 42.5854],
							[117.455937, 42.589416],
							[117.461773, 42.598533],
							[117.475064, 42.602508],
							[117.49044, 42.597368],
							[117.510029, 42.59797],
							[117.512947, 42.594477],
							[117.52823, 42.591465],
							[117.539947, 42.605439],
							[117.551061, 42.609735],
							[117.558101, 42.605479],
							[117.600291, 42.60299],
							[117.61085, 42.592388],
							[117.644194, 42.589818],
							[117.66735, 42.582468],
							[117.677862, 42.585561],
							[117.687495, 42.58291],
							[117.70727, 42.588011],
							[117.72422, 42.597609],
							[117.759787, 42.609775],
							[117.779933, 42.618607],
							[117.7929, 42.618969],
							[117.801514, 42.612706]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150426,
				"name": "翁牛特旗",
				"center": [119.022619, 42.937128],
				"centroid": [119.248434, 42.969563],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 8,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[118.580428, 43.271924],
							[118.527123, 43.277881],
							[118.458629, 43.268508],
							[118.447097, 43.253213],
							[118.422228, 43.244591],
							[118.404954, 43.249677],
							[118.389949, 43.250551],
							[118.367858, 43.248207],
							[118.360402, 43.243836],
							[118.356095, 43.234099],
							[118.335857, 43.214105],
							[118.335301, 43.209294],
							[118.34373, 43.196967],
							[118.348315, 43.166895],
							[118.375592, 43.138718],
							[118.370312, 43.099098],
							[118.35716, 43.087387],
							[118.357855, 43.072365],
							[118.342248, 43.061246],
							[118.342109, 43.053713],
							[118.333726, 43.047056],
							[118.322056, 43.026123],
							[118.323955, 43.019343],
							[118.318629, 43.008852],
							[118.326594, 42.99828],
							[118.354983, 42.981521],
							[118.368831, 42.95813],
							[118.371702, 42.949826],
							[118.370451, 42.937087],
							[118.374249, 42.925025],
							[118.373971, 42.910004],
							[118.366608, 42.900334],
							[118.351001, 42.887665],
							[118.337755, 42.889304],
							[118.315619, 42.909005],
							[118.301308, 42.909125],
							[118.263935, 42.878671],
							[118.222718, 42.855801],
							[118.216466, 42.846843],
							[118.203035, 42.836483],
							[118.181639, 42.832963],
							[118.16418, 42.823401],
							[118.148943, 42.824281],
							[118.133475, 42.812677],
							[118.110412, 42.805754],
							[118.096241, 42.796067],
							[118.078874, 42.791504],
							[118.046271, 42.787501],
							[118.019132, 42.780574],
							[117.989215, 42.762755],
							[117.96643, 42.743167],
							[117.948739, 42.731227],
							[117.940634, 42.718363],
							[117.921739, 42.699884],
							[117.895897, 42.685209],
							[117.878716, 42.669408],
							[117.862877, 42.661586],
							[117.842176, 42.66307],
							[117.835136, 42.657534],
							[117.832636, 42.643652],
							[117.846344, 42.638957],
							[117.87677, 42.634502],
							[117.900343, 42.628843],
							[117.934521, 42.629606],
							[117.947766, 42.626997],
							[117.977128, 42.630007],
							[118.005053, 42.627759],
							[118.016076, 42.630248],
							[118.06586, 42.655568],
							[118.088368, 42.664394],
							[118.126899, 42.671574],
							[118.143988, 42.672697],
							[118.165569, 42.67001],
							[118.192198, 42.671052],
							[118.208083, 42.66648],
							[118.225774, 42.655007],
							[118.255738, 42.646822],
							[118.279773, 42.643852],
							[118.35188, 42.631653],
							[118.374527, 42.625552],
							[118.421487, 42.615958],
							[118.43538, 42.619852],
							[118.444179, 42.62784],
							[118.463213, 42.63089],
							[118.475579, 42.62499],
							[118.487249, 42.607246],
							[118.491834, 42.606603],
							[118.515453, 42.614754],
							[118.531291, 42.610578],
							[118.552085, 42.600741],
							[118.560514, 42.602267],
							[118.577325, 42.614553],
							[118.595711, 42.61098],
							[118.602148, 42.593633],
							[118.611271, 42.584878],
							[118.622479, 42.580661],
							[118.660917, 42.574033],
							[118.686481, 42.585159],
							[118.716954, 42.585601],
							[118.736035, 42.577447],
							[118.751642, 42.558685],
							[118.78105, 42.542369],
							[118.795916, 42.527456],
							[118.802677, 42.527014],
							[118.827176, 42.532561],
							[118.83917, 42.531677],
							[118.872052, 42.543133],
							[118.884834, 42.540158],
							[118.889928, 42.532441],
							[118.901182, 42.528622],
							[118.919521, 42.534652],
							[118.931284, 42.534009],
							[118.961479, 42.52046],
							[118.980421, 42.520099],
							[118.982181, 42.508598],
							[118.960877, 42.505864],
							[118.945641, 42.49605],
							[118.944853, 42.468854],
							[118.954949, 42.466923],
							[118.979078, 42.475091],
							[119.035022, 42.474689],
							[119.047619, 42.477023],
							[119.067347, 42.484626],
							[119.086984, 42.485994],
							[119.109722, 42.492511],
							[119.124496, 42.500676],
							[119.148207, 42.516962],
							[119.169094, 42.524159],
							[119.185118, 42.541847],
							[119.189239, 42.556796],
							[119.232911, 42.561779],
							[119.247314, 42.55985],
							[119.251297, 42.554667],
							[119.249676, 42.540118],
							[119.261115, 42.530752],
							[119.274545, 42.532079],
							[119.286679, 42.536903],
							[119.292144, 42.543454],
							[119.30738, 42.54671],
							[119.312428, 42.571502],
							[119.313169, 42.589738],
							[119.329054, 42.599617],
							[119.34832, 42.597609],
							[119.359249, 42.60311],
							[119.364806, 42.620574],
							[119.342947, 42.622822],
							[119.328591, 42.643411],
							[119.316967, 42.655247],
							[119.310761, 42.67763],
							[119.313632, 42.692186],
							[119.325766, 42.700686],
							[119.341002, 42.704254],
							[119.362259, 42.705777],
							[119.373559, 42.71155],
							[119.385554, 42.722571],
							[119.402041, 42.713714],
							[119.440665, 42.706699],
							[119.448028, 42.697038],
							[119.448491, 42.668886],
							[119.453447, 42.659781],
							[119.465951, 42.65284],
							[119.48869, 42.64618],
							[119.53088, 42.643732],
							[119.567049, 42.651396],
							[119.57293, 42.655729],
							[119.582332, 42.67386],
							[119.593771, 42.67751],
							[119.614426, 42.670972],
							[119.620307, 42.66295],
							[119.615769, 42.647825],
							[119.60169, 42.629606],
							[119.60859, 42.62226],
							[119.621789, 42.619691],
							[119.632811, 42.613509],
							[119.648742, 42.592188],
							[119.670926, 42.59536],
							[119.683152, 42.600821],
							[119.693804, 42.610016],
							[119.704224, 42.624508],
							[119.734465, 42.63382],
							[119.751184, 42.645497],
							[119.75424, 42.653161],
							[119.750767, 42.664274],
							[119.739513, 42.675504],
							[119.72863, 42.680036],
							[119.708623, 42.699123],
							[119.698852, 42.705697],
							[119.685375, 42.724976],
							[119.682504, 42.736316],
							[119.685792, 42.746933],
							[119.701028, 42.755025],
							[119.716867, 42.769082],
							[119.757111, 42.780254],
							[119.767624, 42.787501],
							[119.773321, 42.801671],
							[119.771607, 42.833243],
							[119.781055, 42.839043],
							[119.808332, 42.834603],
							[119.82292, 42.842523],
							[119.819493, 42.857641],
							[119.809444, 42.86016],
							[119.805785, 42.869996],
							[119.816066, 42.872755],
							[119.824912, 42.865758],
							[119.836397, 42.871475],
							[119.836721, 42.879751],
							[119.831349, 42.896418],
							[119.835795, 42.90457],
							[119.858719, 42.916756],
							[119.854412, 42.934132],
							[119.866777, 42.960127],
							[119.869556, 42.977331],
							[119.883264, 42.987507],
							[119.909847, 42.987627],
							[119.935874, 42.997562],
							[119.965513, 42.996126],
							[119.977971, 42.999597],
							[119.98853, 43.00614],
							[119.996635, 43.003706],
							[119.994088, 42.993253],
							[120.006082, 42.980683],
							[120.037111, 42.974497],
							[120.056006, 42.972222],
							[120.062582, 42.964598],
							[120.07555, 42.961005],
							[120.09852, 42.950105],
							[120.117091, 42.960606],
							[120.120009, 42.950385],
							[120.146499, 42.950025],
							[120.155483, 42.944036],
							[120.153677, 42.956613],
							[120.177111, 42.955495],
							[120.193644, 42.962242],
							[120.205453, 42.962242],
							[120.22546, 42.954817],
							[120.243383, 42.950944],
							[120.264038, 42.957731],
							[120.272698, 42.967033],
							[120.281265, 42.969428],
							[120.282099, 42.978927],
							[120.297984, 42.981401],
							[120.306181, 42.985871],
							[120.301643, 42.995647],
							[120.319889, 43.002749],
							[120.323502, 43.010568],
							[120.332671, 43.000315],
							[120.347954, 43.007775],
							[120.358791, 43.029951],
							[120.362264, 43.042391],
							[120.354299, 43.045581],
							[120.348556, 43.058536],
							[120.358745, 43.069855],
							[120.359671, 43.076868],
							[120.373009, 43.086948],
							[120.373148, 43.092406],
							[120.381715, 43.100014],
							[120.391441, 43.099497],
							[120.407835, 43.088303],
							[120.410706, 43.094],
							[120.425155, 43.098262],
							[120.419922, 43.10348],
							[120.411123, 43.099855],
							[120.409965, 43.113595],
							[120.434881, 43.12152],
							[120.44104, 43.128408],
							[120.460213, 43.134897],
							[120.462158, 43.152967],
							[120.469522, 43.175926],
							[120.477534, 43.17704],
							[120.47846, 43.18945],
							[120.498235, 43.190484],
							[120.520372, 43.207345],
							[120.5263, 43.218279],
							[120.5445, 43.225951],
							[120.554457, 43.227541],
							[120.557051, 43.233702],
							[120.571546, 43.245226],
							[120.574695, 43.257544],
							[120.585718, 43.267157],
							[120.589006, 43.276451],
							[120.596832, 43.274823],
							[120.6098, 43.2863],
							[120.631057, 43.293249],
							[120.63064, 43.299324],
							[120.639856, 43.305239],
							[120.648701, 43.318338],
							[120.668337, 43.328101],
							[120.675516, 43.344646],
							[120.686677, 43.34897],
							[120.695522, 43.362297],
							[120.711407, 43.369515],
							[120.71488, 43.383076],
							[120.724096, 43.391084],
							[120.7454, 43.404006],
							[120.772214, 43.413596],
							[120.777679, 43.430752],
							[120.785089, 43.436575],
							[120.773372, 43.433882],
							[120.756376, 43.423344],
							[120.745122, 43.429643],
							[120.734887, 43.421085],
							[120.719697, 43.421046],
							[120.702839, 43.413081],
							[120.685334, 43.411258],
							[120.66843, 43.402381],
							[120.659677, 43.400597],
							[120.637216, 43.402183],
							[120.620405, 43.398338],
							[120.597156, 43.396515],
							[120.571129, 43.38593],
							[120.55455, 43.38375],
							[120.543296, 43.387437],
							[120.510461, 43.380697],
							[120.483832, 43.383433],
							[120.462992, 43.379627],
							[120.422191, 43.379785],
							[120.390607, 43.371419],
							[120.373564, 43.373084],
							[120.344527, 43.370507],
							[120.327299, 43.375424],
							[120.297104, 43.390767],
							[120.285711, 43.391798],
							[120.237362, 43.387278],
							[120.219486, 43.383353],
							[120.198553, 43.384186],
							[120.186697, 43.381212],
							[120.157428, 43.382164],
							[120.133207, 43.38145],
							[120.089397, 43.370863],
							[120.079023, 43.370189],
							[120.046929, 43.369713],
							[120.019281, 43.36079],
							[119.991772, 43.355753],
							[119.949675, 43.346193],
							[119.930965, 43.339211],
							[119.914663, 43.328934],
							[119.902206, 43.326831],
							[119.878077, 43.327505],
							[119.858812, 43.323854],
							[119.832646, 43.314131],
							[119.806711, 43.313377],
							[119.781657, 43.30647],
							[119.753684, 43.301388],
							[119.718627, 43.287293],
							[119.663284, 43.276729],
							[119.642768, 43.266283],
							[119.608359, 43.26243],
							[119.586824, 43.257861],
							[119.574459, 43.260523],
							[119.556536, 43.270732],
							[119.544866, 43.271368],
							[119.51078, 43.264615],
							[119.495405, 43.264575],
							[119.475259, 43.270335],
							[119.451455, 43.270811],
							[119.421538, 43.274664],
							[119.393473, 43.274545],
							[119.368697, 43.269262],
							[119.341604, 43.275101],
							[119.329378, 43.271368],
							[119.306963, 43.271248],
							[119.269127, 43.261635],
							[119.260189, 43.250471],
							[119.233698, 43.234695],
							[119.224019, 43.230999],
							[119.186044, 43.227501],
							[119.151727, 43.220545],
							[119.107685, 43.216371],
							[119.076424, 43.223208],
							[119.056927, 43.225315],
							[119.039792, 43.231357],
							[119.028075, 43.226189],
							[119.007559, 43.229369],
							[118.964304, 43.23855],
							[118.898681, 43.227978],
							[118.85876, 43.229449],
							[118.829028, 43.239226],
							[118.762942, 43.254723],
							[118.729042, 43.254921],
							[118.685694, 43.249279],
							[118.642346, 43.249597],
							[118.618542, 43.257186],
							[118.609975, 43.265608],
							[118.594738, 43.272321],
							[118.580428, 43.271924]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150428,
				"name": "喀喇沁旗",
				"center": [118.708572, 41.92778],
				"centroid": [118.643743, 41.898905],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 9,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[119.329332, 41.960555],
							[119.290291, 41.961204],
							[119.282881, 41.971344],
							[119.287929, 41.989389],
							[119.273897, 41.987241],
							[119.261439, 41.991052],
							[119.246203, 41.990728],
							[119.233837, 41.9872],
							[119.231059, 41.98128],
							[119.229994, 41.961609],
							[119.219249, 41.952725],
							[119.207301, 41.937266],
							[119.181135, 41.935602],
							[119.159461, 41.939782],
							[119.143159, 41.936049],
							[119.136352, 41.940715],
							[119.121069, 41.940675],
							[119.108611, 41.953212],
							[119.111992, 41.960798],
							[119.111251, 41.974588],
							[119.139501, 41.97325],
							[119.16349, 41.978319],
							[119.165852, 41.986105],
							[119.181181, 41.981645],
							[119.188082, 41.982983],
							[119.197205, 41.994214],
							[119.197529, 42.001917],
							[119.181644, 42.005809],
							[119.174096, 42.023034],
							[119.183821, 42.027613],
							[119.169835, 42.035636],
							[119.154969, 42.048276],
							[119.151495, 42.041592],
							[119.141909, 42.052975],
							[119.123153, 42.055365],
							[119.123848, 42.050058],
							[119.099117, 42.032719],
							[119.083881, 42.030328],
							[119.077212, 42.036041],
							[119.060586, 42.05824],
							[119.062114, 42.071929],
							[119.045257, 42.074804],
							[119.03794, 42.087881],
							[119.021406, 42.088732],
							[119.011635, 42.079015],
							[119.006726, 42.079258],
							[118.99663, 42.068811],
							[118.993527, 42.057349],
							[118.983894, 42.051112],
							[118.974493, 42.038715],
							[118.961803, 42.042078],
							[118.947956, 42.036001],
							[118.916881, 42.034502],
							[118.907063, 42.044266],
							[118.893448, 42.04779],
							[118.893772, 42.055729],
							[118.876498, 42.058889],
							[118.868995, 42.064194],
							[118.868486, 42.05824],
							[118.84709, 42.065085],
							[118.847645, 42.07861],
							[118.859594, 42.084967],
							[118.865383, 42.094925],
							[118.856167, 42.098001],
							[118.864179, 42.108039],
							[118.849266, 42.119248],
							[118.861863, 42.112855],
							[118.868717, 42.115607],
							[118.872746, 42.125318],
							[118.868995, 42.127583],
							[118.847831, 42.121433],
							[118.845978, 42.14785],
							[118.875571, 42.185089],
							[118.893633, 42.20566],
							[118.905952, 42.210064],
							[118.913547, 42.218549],
							[118.906322, 42.220407],
							[118.887381, 42.234182],
							[118.869597, 42.238544],
							[118.831483, 42.229658],
							[118.82537, 42.221619],
							[118.828102, 42.208529],
							[118.810504, 42.20962],
							[118.809022, 42.202791],
							[118.826944, 42.199881],
							[118.81268, 42.190505],
							[118.818052, 42.182583],
							[118.796934, 42.173851],
							[118.79485, 42.159254],
							[118.774612, 42.148537],
							[118.767388, 42.142389],
							[118.760117, 42.12815],
							[118.762247, 42.110953],
							[118.759005, 42.102332],
							[118.747613, 42.100349],
							[118.717001, 42.110467],
							[118.686806, 42.113907],
							[118.662075, 42.109941],
							[118.64406, 42.101401],
							[118.626601, 42.089177],
							[118.609512, 42.080391],
							[118.600481, 42.072901],
							[118.593812, 42.061319],
							[118.580845, 42.054555],
							[118.57626, 42.057795],
							[118.577927, 42.084562],
							[118.571212, 42.087112],
							[118.544907, 42.070917],
							[118.537497, 42.071159],
							[118.539952, 42.07861],
							[118.55278, 42.086222],
							[118.555698, 42.09278],
							[118.544351, 42.093792],
							[118.538655, 42.101401],
							[118.525827, 42.10472],
							[118.50193, 42.103628],
							[118.466131, 42.086141],
							[118.449829, 42.083549],
							[118.422922, 42.090189],
							[118.394024, 42.092213],
							[118.387633, 42.095451],
							[118.365079, 42.114069],
							[118.348778, 42.118237],
							[118.327104, 42.108525],
							[118.306078, 42.104963],
							[118.279634, 42.10642],
							[118.263194, 42.09784],
							[118.261388, 42.088246],
							[118.272225, 42.083307],
							[118.271437, 42.0778],
							[118.283803, 42.063789],
							[118.29288, 42.061562],
							[118.297279, 42.048803],
							[118.287137, 42.040538],
							[118.283062, 42.031544],
							[118.267501, 42.026762],
							[118.23763, 42.022872],
							[118.256293, 42.010713],
							[118.27931, 42.010146],
							[118.294732, 42.005201],
							[118.303948, 41.991052],
							[118.313396, 41.988579],
							[118.306264, 41.975156],
							[118.307792, 41.957309],
							[118.299873, 41.951427],
							[118.306541, 41.940269],
							[118.286535, 41.933614],
							[118.26889, 41.930164],
							[118.270187, 41.917338],
							[118.286674, 41.911087],
							[118.323955, 41.880551],
							[118.34021, 41.872468],
							[118.334838, 41.863328],
							[118.337385, 41.853617],
							[118.331781, 41.840654],
							[118.319833, 41.831469],
							[118.31071, 41.815127],
							[118.307885, 41.802359],
							[118.292278, 41.77287],
							[118.270835, 41.76221],
							[118.259859, 41.764407],
							[118.246985, 41.774009],
							[118.240918, 41.785074],
							[118.241474, 41.794225],
							[118.236009, 41.807564],
							[118.219105, 41.815371],
							[118.206231, 41.813744],
							[118.17849, 41.814923],
							[118.165893, 41.813297],
							[118.155983, 41.807035],
							[118.140376, 41.783976],
							[118.130697, 41.742309],
							[118.138199, 41.727328],
							[118.148758, 41.722361],
							[118.155195, 41.712629],
							[118.153713, 41.691165],
							[118.160382, 41.678251],
							[118.168996, 41.670794],
							[118.187428, 41.664763],
							[118.206879, 41.650743],
							[118.213918, 41.642141],
							[118.215215, 41.632968],
							[118.209889, 41.617758],
							[118.215354, 41.595691],
							[118.230498, 41.582186],
							[118.243465, 41.577167],
							[118.270835, 41.573535],
							[118.279171, 41.565413],
							[118.293806, 41.564311],
							[118.301586, 41.569658],
							[118.312192, 41.566556],
							[118.33456, 41.571862],
							[118.361467, 41.583002],
							[118.399257, 41.575086],
							[118.414772, 41.574147],
							[118.432879, 41.576351],
							[118.451219, 41.583084],
							[118.458953, 41.591285],
							[118.460435, 41.601525],
							[118.45849, 41.621714],
							[118.463213, 41.629788],
							[118.480534, 41.639981],
							[118.486045, 41.65168],
							[118.48447, 41.661788],
							[118.489287, 41.682447],
							[118.49628, 41.688151],
							[118.53458, 41.697153],
							[118.570517, 41.717679],
							[118.589366, 41.716376],
							[118.622849, 41.734738],
							[118.634751, 41.749472],
							[118.640633, 41.766442],
							[118.647117, 41.777223],
							[118.656425, 41.784505],
							[118.660871, 41.808133],
							[118.665734, 41.817607],
							[118.675042, 41.82464],
							[118.687685, 41.825168],
							[118.697874, 41.821184],
							[118.712555, 41.820046],
							[118.729458, 41.825006],
							[118.746084, 41.83659],
							[118.761877, 41.844109],
							[118.80189, 41.852358],
							[118.831344, 41.848579],
							[118.882981, 41.852683],
							[118.894698, 41.848619],
							[118.924708, 41.852398],
							[118.944992, 41.862678],
							[118.970742, 41.871006],
							[118.997324, 41.881851],
							[119.022564, 41.877383],
							[119.037754, 41.868853],
							[119.070172, 41.842321],
							[119.094671, 41.850895],
							[119.10875, 41.850651],
							[119.160711, 41.844434],
							[119.179977, 41.852317],
							[119.20003, 41.851911],
							[119.211237, 41.856949],
							[119.241201, 41.853374],
							[119.258938, 41.844027],
							[119.272044, 41.845287],
							[119.290106, 41.843458],
							[119.304416, 41.850001],
							[119.326692, 41.852886],
							[119.334056, 41.872468],
							[119.328081, 41.876977],
							[119.324052, 41.890461],
							[119.336279, 41.904672],
							[119.3335, 41.911412],
							[119.340724, 41.9216],
							[119.336556, 41.931017],
							[119.32646, 41.933736],
							[119.324238, 41.940025],
							[119.330119, 41.947086],
							[119.329332, 41.960555]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150429,
				"name": "宁城县",
				"center": [119.339242, 41.598692],
				"centroid": [118.896441, 41.564499],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 10,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[118.312192, 41.566556],
							[118.313257, 41.561495],
							[118.302929, 41.552719],
							[118.303485, 41.541491],
							[118.315943, 41.517764],
							[118.315804, 41.512536],
							[118.301262, 41.495582],
							[118.295427, 41.485121],
							[118.276439, 41.482424],
							[118.269631, 41.478868],
							[118.272132, 41.471307],
							[118.28547, 41.467873],
							[118.300197, 41.460351],
							[118.327058, 41.450825],
							[118.348685, 41.428333],
							[118.343961, 41.404156],
							[118.345953, 41.398181],
							[118.358874, 41.392248],
							[118.361745, 41.386723],
							[118.348268, 41.37387],
							[118.349148, 41.342751],
							[118.351093, 41.337017],
							[118.365265, 41.322147],
							[118.380177, 41.312109],
							[118.399628, 41.311085],
							[118.410279, 41.320754],
							[118.412456, 41.331938],
							[118.430286, 41.338696],
							[118.473263, 41.345659],
							[118.500865, 41.345782],
							[118.519297, 41.353849],
							[118.528142, 41.355037],
							[118.539813, 41.350942],
							[118.568757, 41.354218],
							[118.591682, 41.350123],
							[118.629935, 41.346437],
							[118.663001, 41.351146],
							[118.676987, 41.350409],
							[118.695142, 41.338],
							[118.716538, 41.330299],
							[118.74187, 41.324073],
							[118.763359, 41.328907],
							[118.770027, 41.35303],
							[118.795452, 41.358927],
							[118.839773, 41.374156],
							[118.847507, 41.369653],
							[118.840606, 41.35561],
							[118.844959, 41.342464],
							[118.868393, 41.312642],
							[118.890715, 41.300717],
							[118.934665, 41.30461],
							[118.949531, 41.31805],
							[118.974678, 41.306577],
							[119.006772, 41.30711],
							[119.035115, 41.29875],
							[119.062114, 41.295717],
							[119.079203, 41.29625],
							[119.093097, 41.293668],
							[119.126765, 41.298381],
							[119.162471, 41.296578],
							[119.200678, 41.282313],
							[119.210728, 41.289364],
							[119.212117, 41.308093],
							[119.224158, 41.312601],
							[119.239441, 41.314527],
							[119.244396, 41.323335],
							[119.252084, 41.325629],
							[119.270238, 41.323376],
							[119.296312, 41.325138],
							[119.306315, 41.331774],
							[119.314605, 41.348198],
							[119.314281, 41.334027],
							[119.326275, 41.329398],
							[119.331045, 41.340949],
							[119.317985, 41.369162],
							[119.325858, 41.374034],
							[119.330629, 41.385126],
							[119.309464, 41.396381],
							[119.309927, 41.405956],
							[119.322431, 41.405669],
							[119.348922, 41.411029],
							[119.365038, 41.421011],
							[119.377079, 41.423588],
							[119.372402, 41.441338],
							[119.378005, 41.459779],
							[119.40343, 41.475149],
							[119.404959, 41.487695],
							[119.398568, 41.503508],
							[119.405792, 41.503058],
							[119.404866, 41.510248],
							[119.3944, 41.521971],
							[119.382359, 41.522911],
							[119.38347, 41.531732],
							[119.372865, 41.53651],
							[119.362491, 41.554474],
							[119.368789, 41.556107],
							[119.36212, 41.566515],
							[119.392454, 41.563822],
							[119.403245, 41.566515],
							[119.414313, 41.562107],
							[119.418991, 41.571331],
							[119.411257, 41.580677],
							[119.419917, 41.581533],
							[119.416722, 41.589327],
							[119.397178, 41.597405],
							[119.386388, 41.598506],
							[119.379395, 41.604788],
							[119.358415, 41.609112],
							[119.343133, 41.617065],
							[119.34128, 41.623712],
							[119.31956, 41.644017],
							[119.312984, 41.640103],
							[119.312382, 41.65274],
							[119.308167, 41.660647],
							[119.313354, 41.664233],
							[119.313956, 41.681918],
							[119.304138, 41.694261],
							[119.2996, 41.710145],
							[119.306315, 41.720651],
							[119.319097, 41.727613],
							[119.311919, 41.741536],
							[119.300758, 41.743856],
							[119.311965, 41.750897],
							[119.317847, 41.76396],
							[119.298535, 41.772626],
							[119.290152, 41.783366],
							[119.293487, 41.792192],
							[119.312752, 41.805734],
							[119.319467, 41.831469],
							[119.31692, 41.834924],
							[119.326692, 41.852886],
							[119.304416, 41.850001],
							[119.290106, 41.843458],
							[119.272044, 41.845287],
							[119.258938, 41.844027],
							[119.241201, 41.853374],
							[119.211237, 41.856949],
							[119.20003, 41.851911],
							[119.179977, 41.852317],
							[119.160711, 41.844434],
							[119.10875, 41.850651],
							[119.094671, 41.850895],
							[119.070172, 41.842321],
							[119.037754, 41.868853],
							[119.022564, 41.877383],
							[118.997324, 41.881851],
							[118.970742, 41.871006],
							[118.944992, 41.862678],
							[118.924708, 41.852398],
							[118.894698, 41.848619],
							[118.882981, 41.852683],
							[118.831344, 41.848579],
							[118.80189, 41.852358],
							[118.761877, 41.844109],
							[118.746084, 41.83659],
							[118.729458, 41.825006],
							[118.712555, 41.820046],
							[118.697874, 41.821184],
							[118.687685, 41.825168],
							[118.675042, 41.82464],
							[118.665734, 41.817607],
							[118.660871, 41.808133],
							[118.656425, 41.784505],
							[118.647117, 41.777223],
							[118.640633, 41.766442],
							[118.634751, 41.749472],
							[118.622849, 41.734738],
							[118.589366, 41.716376],
							[118.570517, 41.717679],
							[118.53458, 41.697153],
							[118.49628, 41.688151],
							[118.489287, 41.682447],
							[118.48447, 41.661788],
							[118.486045, 41.65168],
							[118.480534, 41.639981],
							[118.463213, 41.629788],
							[118.45849, 41.621714],
							[118.460435, 41.601525],
							[118.458953, 41.591285],
							[118.451219, 41.583084],
							[118.432879, 41.576351],
							[118.414772, 41.574147],
							[118.399257, 41.575086],
							[118.361467, 41.583002],
							[118.33456, 41.571862],
							[118.312192, 41.566556]
						]
					]
				]
			}
		}, {
			"type": "Feature",
			"properties": {
				"adcode": 150430,
				"name": "敖汉旗",
				"center": [119.906486, 42.287012],
				"centroid": [120.150718, 42.426295],
				"childrenNum": 0,
				"level": "district",
				"parent": {
					"adcode": 150400
				},
				"subFeatureIndex": 11,
				"acroutes": [100000, 150000, 150400]
			},
			"geometry": {
				"type": "MultiPolygon",
				"coordinates": [
					[
						[
							[119.502352, 42.388042],
							[119.53991, 42.363419],
							[119.544356, 42.366401],
							[119.57219, 42.359347],
							[119.57131, 42.335681],
							[119.565706, 42.328221],
							[119.553202, 42.324752],
							[119.55737, 42.319267],
							[119.543847, 42.306963],
							[119.539447, 42.297038],
							[119.541994, 42.292276],
							[119.55348, 42.294052],
							[119.553989, 42.28925],
							[119.565752, 42.294375],
							[119.573255, 42.280613],
							[119.582795, 42.285577],
							[119.584555, 42.278555],
							[119.608915, 42.276698],
							[119.617019, 42.252597],
							[119.626467, 42.255464],
							[119.634664, 42.251022],
							[119.642259, 42.240644],
							[119.680003, 42.240725],
							[119.704085, 42.223478],
							[119.719923, 42.229456],
							[119.719368, 42.220973],
							[119.732752, 42.221862],
							[119.744607, 42.21164],
							[119.765679, 42.214428],
							[119.788372, 42.211357],
							[119.790965, 42.215236],
							[119.806016, 42.213095],
							[119.846724, 42.215276],
							[119.852884, 42.209822],
							[119.857005, 42.182906],
							[119.854736, 42.170172],
							[119.839778, 42.148699],
							[119.837832, 42.13527],
							[119.84251, 42.125722],
							[119.842278, 42.103547],
							[119.845613, 42.097273],
							[119.869741, 42.083469],
							[119.882616, 42.066178],
							[119.883449, 42.053785],
							[119.900214, 42.045967],
							[119.902761, 42.041308],
							[119.89725, 42.030936],
							[119.90767, 42.018738],
							[119.92124, 42.01424],
							[119.927769, 41.99916],
							[119.924481, 41.989227],
							[119.943932, 41.979739],
							[119.954399, 41.968221],
							[119.960049, 41.937875],
							[119.954491, 41.920423],
							[119.961808, 41.913563],
							[119.985011, 41.904713],
							[119.991818, 41.894684],
							[119.991911, 41.874296],
							[119.995245, 41.86804],
							[120.006406, 41.86215],
							[120.023218, 41.81655],
							[120.048735, 41.825981],
							[120.041742, 41.818745],
							[120.04591, 41.804067],
							[120.0429, 41.787799],
							[120.032248, 41.781657],
							[120.043178, 41.774457],
							[120.051699, 41.776084],
							[120.044104, 41.765587],
							[120.037296, 41.763797],
							[120.035953, 41.747519],
							[120.024792, 41.738564],
							[120.036509, 41.725333],
							[120.035305, 41.708883],
							[120.044058, 41.708638],
							[120.071706, 41.69756],
							[120.096205, 41.697072],
							[120.104911, 41.702326],
							[120.113525, 41.713159],
							[120.131355, 41.721954],
							[120.138255, 41.729283],
							[120.131262, 41.736855],
							[120.12376, 41.762088],
							[120.127187, 41.772626],
							[120.149278, 41.792273],
							[120.163125, 41.808946],
							[120.183085, 41.826469],
							[120.183502, 41.84228],
							[120.188411, 41.848213],
							[120.215966, 41.853374],
							[120.228007, 41.865928],
							[120.251626, 41.883963],
							[120.27455, 41.883191],
							[120.288166, 41.880389],
							[120.300392, 41.888471],
							[120.291547, 41.896552],
							[120.273856, 41.898541],
							[120.260286, 41.904551],
							[120.269595, 41.907961],
							[120.266909, 41.914335],
							[120.273115, 41.925903],
							[120.280571, 41.916851],
							[120.290296, 41.918556],
							[120.280617, 41.925619],
							[120.286915, 41.934831],
							[120.304977, 41.934547],
							[120.307802, 41.92708],
							[120.317481, 41.938483],
							[120.310442, 41.941608],
							[120.309747, 41.951589],
							[120.314239, 41.955889],
							[120.333783, 41.964246],
							[120.334616, 41.979901],
							[120.346333, 41.977954],
							[120.373564, 41.994701],
							[120.399545, 41.984808],
							[120.422006, 41.986186],
							[120.411957, 41.997255],
							[120.422979, 42.001552],
							[120.428305, 42.007754],
							[120.440114, 42.006376],
							[120.456416, 42.016307],
							[120.451738, 42.022872],
							[120.453776, 42.030652],
							[120.449469, 42.050869],
							[120.451692, 42.057673],
							[120.482952, 42.063587],
							[120.493326, 42.07363],
							[120.482813, 42.086019],
							[120.496336, 42.087962],
							[120.492585, 42.095694],
							[120.480174, 42.09112],
							[120.469476, 42.097799],
							[120.466558, 42.105327],
							[120.480914, 42.115768],
							[120.494901, 42.120017],
							[120.515278, 42.131103],
							[120.53621, 42.140286],
							[120.55103, 42.14336],
							[120.567702, 42.151935],
							[120.584189, 42.16718],
							[120.624712, 42.154523],
							[120.650878, 42.169969],
							[120.689131, 42.187878],
							[120.69668, 42.198144],
							[120.722105, 42.20372],
							[120.738129, 42.220811],
							[120.745261, 42.223478],
							[120.790507, 42.218509],
							[120.804169, 42.22574],
							[120.820841, 42.228083],
							[120.827742, 42.234909],
							[120.823805, 42.241169],
							[120.829455, 42.252516],
							[120.834781, 42.247711],
							[120.867014, 42.251789],
							[120.882482, 42.243027],
							[120.892068, 42.254535],
							[120.886511, 42.259057],
							[120.881185, 42.271814],
							[120.874563, 42.268181],
							[120.859882, 42.270078],
							[120.847702, 42.278716],
							[120.861364, 42.286304],
							[120.853167, 42.292882],
							[120.842006, 42.309626],
							[120.795555, 42.336326],
							[120.791896, 42.343302],
							[120.801483, 42.354591],
							[120.797639, 42.358662],
							[120.76712, 42.366522],
							[120.768694, 42.394125],
							[120.713121, 42.418575],
							[120.685195, 42.433554],
							[120.685009, 42.439151],
							[120.665049, 42.442613],
							[120.653286, 42.454809],
							[120.653101, 42.465474],
							[120.620683, 42.486678],
							[120.595489, 42.513665],
							[120.602112, 42.524923],
							[120.599194, 42.53236],
							[120.588589, 42.537787],
							[120.56974, 42.54044],
							[120.571407, 42.544218],
							[120.562515, 42.558163],
							[120.565665, 42.56206],
							[120.564229, 42.580861],
							[120.551817, 42.584075],
							[120.564461, 42.587328],
							[120.56497, 42.598533],
							[120.551447, 42.600862],
							[120.549826, 42.593151],
							[120.544871, 42.605319],
							[120.536998, 42.612666],
							[120.529912, 42.628161],
							[120.511526, 42.651757],
							[120.498559, 42.690422],
							[120.489019, 42.705497],
							[120.480451, 42.72698],
							[120.459565, 42.755786],
							[120.457157, 42.774568],
							[120.446459, 42.82048],
							[120.427934, 42.835083],
							[120.423812, 42.86008],
							[120.415754, 42.856321],
							[120.415893, 42.862999],
							[120.424322, 42.867197],
							[120.39672, 42.885507],
							[120.408715, 42.908006],
							[120.420571, 42.914838],
							[120.403945, 42.939044],
							[120.428536, 42.98647],
							[120.386532, 42.985352],
							[120.366988, 43.021217],
							[120.358791, 43.029951],
							[120.347954, 43.007775],
							[120.332671, 43.000315],
							[120.323502, 43.010568],
							[120.319889, 43.002749],
							[120.301643, 42.995647],
							[120.306181, 42.985871],
							[120.297984, 42.981401],
							[120.282099, 42.978927],
							[120.281265, 42.969428],
							[120.272698, 42.967033],
							[120.264038, 42.957731],
							[120.243383, 42.950944],
							[120.22546, 42.954817],
							[120.205453, 42.962242],
							[120.193644, 42.962242],
							[120.177111, 42.955495],
							[120.153677, 42.956613],
							[120.155483, 42.944036],
							[120.146499, 42.950025],
							[120.120009, 42.950385],
							[120.117091, 42.960606],
							[120.09852, 42.950105],
							[120.07555, 42.961005],
							[120.062582, 42.964598],
							[120.056006, 42.972222],
							[120.037111, 42.974497],
							[120.006082, 42.980683],
							[119.994088, 42.993253],
							[119.996635, 43.003706],
							[119.98853, 43.00614],
							[119.977971, 42.999597],
							[119.965513, 42.996126],
							[119.935874, 42.997562],
							[119.909847, 42.987627],
							[119.883264, 42.987507],
							[119.869556, 42.977331],
							[119.866777, 42.960127],
							[119.854412, 42.934132],
							[119.858719, 42.916756],
							[119.835795, 42.90457],
							[119.831349, 42.896418],
							[119.836721, 42.879751],
							[119.836397, 42.871475],
							[119.824912, 42.865758],
							[119.816066, 42.872755],
							[119.805785, 42.869996],
							[119.809444, 42.86016],
							[119.819493, 42.857641],
							[119.82292, 42.842523],
							[119.808332, 42.834603],
							[119.781055, 42.839043],
							[119.771607, 42.833243],
							[119.773321, 42.801671],
							[119.767624, 42.787501],
							[119.757111, 42.780254],
							[119.716867, 42.769082],
							[119.701028, 42.755025],
							[119.685792, 42.746933],
							[119.682504, 42.736316],
							[119.685375, 42.724976],
							[119.698852, 42.705697],
							[119.708623, 42.699123],
							[119.72863, 42.680036],
							[119.739513, 42.675504],
							[119.750767, 42.664274],
							[119.75424, 42.653161],
							[119.751184, 42.645497],
							[119.734465, 42.63382],
							[119.704224, 42.624508],
							[119.693804, 42.610016],
							[119.683152, 42.600821],
							[119.670926, 42.59536],
							[119.648742, 42.592188],
							[119.655411, 42.583834],
							[119.655828, 42.573109],
							[119.643092, 42.549322],
							[119.638508, 42.534089],
							[119.629431, 42.530431],
							[119.616695, 42.512057],
							[119.595808, 42.504215],
							[119.585759, 42.505301],
							[119.5708, 42.49239],
							[119.577701, 42.465112],
							[119.575524, 42.449094],
							[119.570291, 42.444424],
							[119.56867, 42.432024],
							[119.548015, 42.4313],
							[119.539771, 42.42836],
							[119.517171, 42.404881],
							[119.508048, 42.398718],
							[119.502352, 42.388042]
						]
					]
				]
			}
		}]
	})
}));
